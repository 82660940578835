import React from "react";
import Switch from '@mui/material/Switch';
import { useState, useEffect, useContext } from "react";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SearchConfig from "components/private/SearchConfig/SearchConfig";
import SearchModel from "Models/SearchModel";
import { useHistory } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AppDataContext from "Contexts/AppDataContext";
import AppAuthContext from "Contexts/AppAuthContext";
import { CenterFocusStrong, Nature } from "@mui/icons-material";
import { matchSorter } from 'match-sorter';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { version } from "chartist";
import { Stack, Typography } from "@mui/material";

export default function SearchForm(props) {
  const { IdAbonnement } = useContext(AppAuthContext);
  const { AllYears, AutoCompleteOptions, AllNatures, ImposOptions } = useContext(AppDataContext);

  const [LoadingImpos, setLoadingImpos] = useState(false);
  const [selectedValue, setselectedValue] = useState({ label: "", id: 0 });
  const [Loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [KeyWordsopen, setKeyWordsopen] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const selected = () => setFocused(false);
  const [showAdvFilters, setshowAdvFilters] = useState(props.showAdvFilters);
  const [searchData, setsearchData] = useState({
    query: "",
    anneeMin: "",
    anneeMax: "",
    impos: "%",
    filtreNature: ["%"]
  });
  const [SavedSearchData, setSavedSearchData] = useState(JSON.parse(localStorage.getItem('searchmodel')));
  const [value, setValue] = useState(0);
  const [KeyWords, setKeyWords] = useState(searchData.query);
  const [minYear, setminYear] = useState(searchData.anneeMin);
  const [maxYear, setmaxYear] = useState(searchData.anneeMax);
  const [imposition, setimposition] = useState(searchData.impos);
  const [Natures, setNatures] = useState(searchData.filtreNature);
  const [InputQuery, setInputQuery] = useState(KeyWords);
  const [versions, setVersions] = useState(1);
  const history = useHistory();
  const submitSearch = () => {
    let newSearch = new SearchModel();
    newSearch.query = KeyWords;
    newSearch.anneeMin = minYear == "" ? Math.min(...AllYears.map(x => Number(x.id))) : minYear;
    newSearch.anneeMax = maxYear == "" ? Math.max(...AllYears.map(x => Number(x.id))) : maxYear;
    newSearch.impos = imposition;
    newSearch.startFrom = 0;
    newSearch.filtreNature = Natures;
    newSearch.FromDashBoard = true;
    localStorage.setItem('searchmodel', JSON.stringify(newSearch));
    let selectedVersion = versions === 2 ? 'SearchV1' : 'Search';

    if (props.redirect !== undefined) {
      history.push(selectedVersion);
    } else {
      history.push('ReloadSearch');
    }
  }
  const onResetClicked = () => {
    localStorage.setItem('searchmodel', JSON.stringify({
      query: "",
      anneeMin: Math.min(...AllYears.map(x => Number(x.id))),
      anneeMax: Math.max(...AllYears.map(x => Number(x.id))),
      impos: "%",
      filtreNature: ["%"]
    }));
    setsearchData({
      query: "",
      anneeMin: Math.min(...AllYears.map(x => Number(x.id))),
      anneeMax: Math.max(...AllYears.map(x => Number(x.id))),
      impos: "%",
      filtreNature: ["%"]
    });
    setKeyWords("");
    setminYear(Math.min(...AllYears.map(x => Number(x.id))));
    setmaxYear(Math.max(...AllYears.map(x => Number(x.id))));
    setimposition("");
    setselectedValue({ label: "", id: 0 })
    setNatures(["%"]);
    setValue(value => value + 1);
  }
  const functionHandler = (data) => {
    setFocused(true);
    setInputQuery(data);
    setKeyWords(data)
  }


  const SelectAll = () => {
    setNatures(["%"]);
    setValue(value => value + 1);//this is used to froce component update
  }

  const NatureChange = (event) => {
    let currentNatures = Natures;
    if (event.target.checked) {
      const index = currentNatures.indexOf("%");
      if (index != -1) {
        currentNatures.splice(index, 1);
      }
      currentNatures.push(event.target.value)
    } else {
      const index = currentNatures.indexOf(event.target.value);
      if (index != -1) {
        currentNatures.splice(index, 1);
      }
    }
    if (currentNatures.length == 0) {
      currentNatures.push("%")
      SelectAll();
    }
    setNatures(currentNatures);
    setValue(value => value + 1);//this is used to froce component update
  };

  const ImposChange = (SelectedImpos, reason) => {
    if (reason == "clear") {
      setimposition("")
      setselectedValue({ label: "", id: 0 })
    }
    if (SelectedImpos != null) {
      setimposition(SelectedImpos.id)
      setselectedValue(SelectedImpos)
    } else {
      setimposition("")
      setselectedValue({ label: "", id: 0 })
    }
  }

  const SearchEnterPress = (event) => {
    if ((event.code === "Enter" || event.code === "NumpadEnter") && !KeyWordsopen) {
      submitSearch()
    }
  }

  useEffect(() => {
    localStorage.removeItem('searchmodel');
    setLoadingImpos(true)
    setLoading(true)

    if (AllYears.length > 0 && localStorage.getItem('searchmodel') == null) {
      setminYear(Math.min(...AllYears.map(x => Number(x.id))));
      setmaxYear(Math.max(...AllYears.map(x => Number(x.id))));
    }

    if (ImposOptions.length > 0) {
      setLoadingImpos(false)
      let selected = ImposOptions.find(v => v.id == imposition)
      if (selected) {
        setselectedValue({ label: selected.label, id: selected.id })
        ImposChange({ label: selected.label, id: selected.id });
      } else {
        ImposChange(null);
        setselectedValue({ label: "", id: 0 })
      }
    }
    if (AutoCompleteOptions.length > 0) {
      setLoading(false)
    }
  }, [ImposOptions, AutoCompleteOptions, AllYears]);

  const UseLastSearch = () => {
    console.log(SavedSearchData);

    setminYear(SavedSearchData.anneeMin);
    setmaxYear(SavedSearchData.anneeMax);
    setKeyWords(SavedSearchData.query);
    setInputQuery(SavedSearchData.query);

    setNatures(SavedSearchData.filtreNature);
    setimposition(SavedSearchData.impos);

    let selected = ImposOptions.find(v => v.id == SavedSearchData.impos)
    if (selected) {
      setselectedValue({ label: selected.label, id: selected.id })
      ImposChange({ label: selected.label, id: selected.id });
    }

    setSavedSearchData(null);
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
  });

  return (
    <>
      <Card elevation={5} sx={{ mb: 1 }}>
        {(showAdvFilters == true ?
          <CardHeader

            avatar={
              props.redirect != undefined ? <></> :
                <IconButton aria-label="SerachEngine">
                  <FindInPageIcon />
                </IconButton>
            }
            action={
              <SearchConfig />
            }
            title={
              props.redirect != undefined ?
                <Grid sx={{ textAlign: 'center' }} item lg={12}>
                  <img src='https://dbprofiscal.com/img/logobase3.PNG' width={500} />
                </Grid>
                : "Moteur de recherche"
            }

          /> :

          <CardHeader

            avatar={<IconButton aria-label="SerachEngine">
              <FindInPageIcon />
            </IconButton>
            }
            action={
              <SearchConfig />
            }
            title={"Moteur de recherche"}
          />

        )
        }


        <CardContent>
          <Grid container spacing={1} justifyContent="center">
            <FormGroup sx={{ textAlign: 'center' }} >
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography style={{ color: versions === 2 ? 'gray' : 'black' }}>Recherche Avancée</Typography>
                <Switch checked={versions === 2} onChange={() => setVersions(versions === 2 ? 1 : 2)} />
                <Typography style={{ color: versions === 2 ? 'black' : 'gray' }}>Recherche simplifiée</Typography>
              </Stack>
            </FormGroup>
            {
              SavedSearchData == null ? <></> :
                <Grid alignItems={"center"} item md={12} xs={12} lg={12}>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => UseLastSearch()}
                  >
                    Utiliser la dernière recherche
                  </Link>

                </Grid>
            }
            <Grid item md={12} xs={12} lg={(props.redirect != undefined ? 8 : 12)}>
              <Autocomplete
                inputValue={KeyWords}
                onInputChange={(event, newInputQuery) => {
                  functionHandler(newInputQuery);
                }}
                onChange={selected}
                options={AutoCompleteOptions}
                freeSolo={true}
                filterOptions={filterOptions}
                open={KeyWords?.length > 0 && focused}
                onOpen={() => setKeyWordsopen(true)}
                onClose={() => setKeyWordsopen(false)}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Mots clés"
                    onFocus={onFocus} onBlur={onBlur}
                    onKeyDown={(e => SearchEnterPress(e))}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {Loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                }
                renderOption={(props, option, { inputValue }) => {
                  const matches = match(option.label, inputValue);
                  const parts = parse(option.label, matches);

                  return (
                    <li {...props}>
                      <div>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </li>
                  );
                }}

              />
            </Grid>
            {(showAdvFilters == true ?
              <Grid item md={6} xs={6} lg={(props.redirect != undefined ? 2 : 6)}>
                <Autocomplete
                  id="MinYearTextBox"
                  freeSolo
                  getOptionLabel={(option) => String(option.label) || ""}
                  value={{ label: minYear, id: minYear }}
                  onChange={(e, value) => setminYear((value == null || value == "") ? Math.min(...AllYears.map(x => Number(x.id))) : Number(value.id))}
                  options={AllYears}
                  type="number"
                  renderInput={(params) => <TextField {...params} label="Année Min" />}
                />
              </Grid>

              : <></>
            )}
            {(showAdvFilters == true ?
              <Grid item md={6} xs={6} lg={(props.redirect != undefined ? 2 : 6)}>
                <Autocomplete
                  id="MinYearTextBox"
                  freeSolo
                  getOptionLabel={(option) => String(option.label) || ""}
                  value={{ label: maxYear, id: maxYear }}
                  onChange={(e, value) => setmaxYear((value == null || value == "") ? Math.max(...AllYears.map(x => Number(x.id))) : Number(value.id))}
                  options={AllYears}
                  type="number"
                  renderInput={(params) => <TextField {...params} label="Année Max" />}
                />
              </Grid>

              : <></>
            )}


            {(showAdvFilters == true && Natures != undefined ?
              <Grid item md={12} xs={12} lg={12}>
                <FormGroup row={true}>
                  <FormControlLabel control={<Checkbox key="%" value="%" checked={Natures.includes("%")} onChange={SelectAll} size="small" />} label="Tous les textes" />
                  {
                    AllNatures.map((item) => <FormControlLabel key={item.code} control={<Checkbox checked={Natures.includes(item.code)} onChange={NatureChange} value={item.code} size="small" />} label={item.libelle} />)
                  }
                </FormGroup>
              </Grid> : <></>
            )}
            {(showAdvFilters == true && selectedValue != undefined && ImposOptions != undefined ?
              <Grid md={12} xs={12} item lg={12}>

                <Autocomplete
                  disablePortal
                  value={selectedValue}
                  onChange={(event, newValue, reason) => {
                    ImposChange(newValue, reason);
                  }}
                  options={ImposOptions}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label="Impositions"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {LoadingImpos ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  }
                />

              </Grid> : <></>
            )}
            {(props.redirect == undefined ?
              <Grid item md={12} xs={12} lg={12} >

                <FormControlLabel key={"showAll"}
                  control={<Checkbox checked={showAdvFilters}
                    onChange={(e) => setshowAdvFilters(e.target.checked)} />}
                  label={"Afficher les options de recherche"} />
              </Grid>

              : <></>
            )}
          </Grid>
        </CardContent>
        <CardActions disableSpacing>
          <Button

            className="btn-fill m-2 pull-right"
            type="Reset"
            variant="outlined"
            color="error"
            endIcon={<ClearIcon />}
            onClick={onResetClicked}>Initialiser</Button>
          <Button

            disabled={IdAbonnement == ""}
            style={{ marginLeft: 'auto' }}
            type="submit"
            variant="contained"
            onClick={submitSearch} endIcon={<ManageSearchIcon />}>Lancer la recherche</Button>
        </CardActions>

      </Card>
    </>
  )
}