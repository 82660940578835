import * as React from 'react';
import {useEffect,useState,useContext} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinkToViewer from "components/private/Search/LinkToViewer";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import {AddOpenDocToSearch} from 'Services/HistoriqueService';
import { GetDocsFavorisWithDocsInfo,DeleteDocFromFavoris } from 'Services/DocumentsFavorisService';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AppDataContext from "Contexts/AppDataContext";

export default function DocumentFavoris() {
  const {setServerError,fetchFavorisDOcs,RemoveDocFromDocsFavoris} = useContext(AppDataContext);
  const [ loading, setloading]=useState(false);
  const [DocumentList,SetDocumentList] = useState([]);

  const fetchDocumentFavoris =()=>{
    setloading(true)
    GetDocsFavorisWithDocsInfo()
    .then(res => {
      if(res!= null){
        SetDocumentList(res);
        setloading(false);
      }else{
        setServerError(true);
        setloading(false);
      }

    })
    .catch(err => consol.log(err))
  }

  const DeleteConfirm =(id)=>{
    RemoveDocFromDocsFavoris(id)
    let docs = DocumentList.filter(d=>d.indexDoc != id);
    SetDocumentList(docs);
  }

  const DocOpened = (id_document,id_search)=> {
    AddOpenDocToSearch(id_search,{index_file:id_document,
      downloaded:false,
      downloadedWithOutOpen:false,
      component:'favorisWeb'})
    .then(res => {
      if(res){
      }else{
        setServerError(true);
      }

    });
  }
  useEffect(() => {
    fetchDocumentFavoris();
    fetchFavorisDOcs();
  }, [

  ]);

  return (
    <>
    
<Box sx={{ flexGrow: 1,mt:4,mb:4 }}>
<Box sx={{ display:( loading ? 'flex' : 'none' ), textAlign:'center',width:'100%' ,justifyContent:'center' }}>
     <CircularProgress disableShrink />
</Box>
<Box sx={{ display:( loading ? 'none': 'flex')}}>

<TableContainer component={Paper}> 
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 }
      }}
    >
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Documents Favoris
        </Typography>
    </Toolbar> 
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell> Réference </TableCell>
            <TableCell > Titre </TableCell>
            <TableCell > Date de création </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {DocumentList.map((docFavoris,i) => (
            <TableRow key={"docfav_"+i}>
              <TableCell>
              <LinkToViewer 
                              id_search={docFavoris.id_search}
                              id={docFavoris.indexDoc}
                              id_file_store={docFavoris.id_File_Store} 
                              text = {docFavoris.referenceDoc}
                              isReference={true}
                              DocOpened={DocOpened}
                            /> 
              </TableCell>
              <TableCell> 
                {docFavoris.titreDoc}
              </TableCell>
              <TableCell>
                {docFavoris.dateCreationStr}
              </TableCell>
              <TableCell>
                <IconButton onClick={(e)=>DeleteConfirm(docFavoris.indexDoc)} aria-label="delete" size="large">
                  <DeleteIcon type="submit" variant="outlined" color="error" /> 
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>    
    </TableContainer>
</Box>

</Box>

    </>
  );
}
