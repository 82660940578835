
import { GetIdentityConfig, METADATA_OIDC } from "../config/userManagerConfig";
import { UserManager, WebStorageStateStore, Log } from "oidc-client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import React from "react";

export default class AuthService {
    UserManager;
    IDENTITY_CONFIG;
    constructor() {
      /*  this.UserManager = new UserManager({
            authority: process.env.REACT_APP_AUTH_URL,
            client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
            redirect_uri: process.env.REACT_APP_REDIRECT_URL, 
            responseType: "code",
            grantType: "code",
            scope: "openid IndexingAPI",
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
            metadata: {
                ...METADATA_OIDC
            }
        });*/

          this.IDENTITY_CONFIG = GetIdentityConfig();
          this.UserManager = new UserManager(this.IDENTITY_CONFIG);

        // Logger
        Log.logger = console;
        Log.level = Log.DEBUG;

        this.UserManager.events.addUserLoaded((user) => {
            if (window.location.href.indexOf("signin-oidc") !== -1) {
                this.navigateToScreen();
            }
        });
        this.UserManager.events.addSilentRenewError((e) => {
            console.log("silent renew error", e.message);
        });

        this.UserManager.events.addAccessTokenExpired(() => {
            console.log("token expired");
            this.signinSilent();
        });

    }

    signinRedirectCallback = () => {
        this.UserManager.signinRedirectCallback().then(() => {
            window.location.replace("/EspaceClient/dashboard");
        });
    };


    getToken= () => {
        this.getUser().then((u)=>{
            return u.access_token;
        })
    };

    getUser = async () => {
        const user = await this.UserManager.getUser();
        if (!user) {
            return await this.UserManager.signinRedirectCallback();
        }
        return user;
    };

    parseJwt = (token) => {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
    };


    signinRedirect = () => {
        localStorage.setItem("redirectUri", window.location.pathname);
        this.UserManager.signinRedirect({});
    };


    navigateToScreen = () => {
        window.location.replace("/app/dashboard");
    };


    isAuthenticated = () => {
        const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${this.IDENTITY_CONFIG.authority}:${this.IDENTITY_CONFIG.client_id}`))
        return (!!oidcStorage && !!oidcStorage.access_token)
    };

    signinSilent = () => {
        this.UserManager.signinSilent()
            .then((user) => {
                console.log("signed in", user);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback();
    };

    createSigninRequest = () => {
        return this.UserManager.createSigninRequest();
    };

    logout = () => {
        this.UserManager.signoutRedirect({
            id_token_hint: localStorage.getItem("id_token")
        });
        this.UserManager.clearStaleState();
    };

    signoutRedirectCallback = () => {
        this.UserManager.signoutRedirectCallback().then(() => {
            localStorage.clear();
            window.location.replace("/public");
        });
        this.UserManager.clearStaleState();
    };  
}