import React from 'react';
import {useHistory} from 'react-router-dom';
import {useEffect}  from "react";
export const IndexComponent = () => {
    const history = useHistory(); 
    useEffect(() => {
          const queryParameters = new URLSearchParams(window.location.search)
          const state = queryParameters.get("MigrationState");
          localStorage.setItem("MigrationState",state);
        const timeout = setTimeout(() => {
            history.push('/EspaceClient/dashboard'); 
          }, 2000);
      },[])
    return (       
        <center>   
            vous serait redirigé vers l'application dans quelques instants ...
        </center>
    );
};
