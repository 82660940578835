
import React, { Component } from "react";
import {  useState,useEffect} from "react";
import { useLocation,useHistory } from "react-router-dom";
import Box from '@mui/material/Box';



import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import MenuIcon from '@mui/icons-material/Menu';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import HistoryIcon from '@mui/icons-material/History';
import GroupIcon from '@mui/icons-material/Group';
import BarChartIcon from '@mui/icons-material/BarChart';
import StoreIcon from '@mui/icons-material/Store';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';

export default function AppNavBar({routes,container }) {
  const UsedIcons = [
    { label: "DashboardIcon", icon: <DashboardIcon /> },
    { label: "ManageSearchIcon", icon: <ManageSearchIcon /> },
    { label: "FolderCopyIcon", icon: <FolderCopyIcon /> },
    { label: "FolderSpecialIcon", icon: <FolderSpecialIcon /> },
    { label: "HistoryIcon", icon: <HistoryIcon /> },
    { label: "GroupIcon", icon: <GroupIcon /> },
    { label: "BarChartIcon", icon: <BarChartIcon /> },
    { label: "StoreIcon", icon: <StoreIcon /> },
    { label: "DriveFolderUploadIcon", icon: <DriveFolderUploadIcon /> },
    { label: "MenuBookIcon", icon: <MenuBookIcon /> },
    { label: "StickyNote2Icon", icon: <StickyNote2Icon /> },
    { label: "RuleFolderIcon", icon: <RuleFolderIcon /> },
  ];


  const [value, setValue] = useState(0);
  const [TermsAccepted, setTermsAccepted] = useState(false);
  const location = useLocation();
  const history = useHistory(); 
  const redirectTo = (route) => {
    history.push(route);
  }

  const [state, setState] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
    }

    setState(open);
  };

  useEffect(() => {
    (routes).forEach((route,i) => {
      if(location.pathname.indexOf(route.path) > -1){
        setValue(i);
      }
    });
    return ;
  },[])

  return (
    <>
        <Box sx={{ 
      width: '100%', display: { xs: 'none', md: 'block'}
       }}>
      <BottomNavigation       
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
       {routes.map((prop, key) => {
            if (!prop.redirect)
              return (                                  
                <BottomNavigationAction key={key} onClick={(e)=>redirectTo(prop.layout + prop.path)} label={prop.name} icon={ UsedIcons.find(x=>x.label === prop.icon).icon } >
                </BottomNavigationAction>
              );
            return null;
          })}
      </BottomNavigation>
    </Box>
    <Box sx={{ 
      width: '100%', display: { xs: 'block', md: 'none'}
       }}>
      <BottomNavigation showLabels>
        <BottomNavigationAction key="menu" onClick={toggleDrawer(true)} label="Menu" icon={ <MenuIcon />} >
        </BottomNavigationAction>
      </BottomNavigation>
    </Box>
    <Drawer
    sx={{ 
       display: { xs: 'block', md: 'none'}
       }}
            anchor="left"
            open={state}
            onClose={toggleDrawer(false)}
          >
            <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer( false)}
            >
            <List>
            {routes.map((prop, key) => {
                if (!prop.redirect)
                         return (                                  
                                    <ListItem key={"route_"+key} disablePadding>
                                    <ListItemButton onClick={(e)=>redirectTo(prop.layout + prop.path)}>
                                    <ListItemIcon>
                                        {UsedIcons.find(x=>x.label === prop.icon).icon}
                                    </ListItemIcon>
                                    <ListItemText primary={prop.name} />
                                    </ListItemButton>
                                </ListItem>
                             );
                return null;
            })}

            </List>
            </Box>
          </Drawer>


    </>

  );
}


