import {SEARCH_API_URL} from "config/globals" ;
import { UserToken } from "config/Token";
// fetcch all natures
export const GetCurrentConfig = async () => {
    const token = UserToken();
    try{
        const response = await fetch(SEARCH_API_URL+'/UserSearchConfig',{
                                        method: 'GET',
                                        headers: {"Authorization": `Bearer ${token}`}
                                    });
        return response.json();
    }catch(err)  {
      return null;
    }
};

export const SaveNewConfig = async (object) => {
    const token = UserToken();
    try{
        const response = await fetch(SEARCH_API_URL+'/UserSearchConfig',{
          method: 'POST',
          headers: { 
            "Content-Type": `application/json`,
            "Authorization": `Bearer ${token}`
         },
         body: JSON.stringify(object)
        })
        if(response.status != 200){
          return null;
        }
        return response.json();
    }catch(err)  {
      return null;
    }  
   }
