import {SEARCH_API_URL} from "config/globals" ;
import { UserToken } from "config/Token";
export const GetCurrentSubscriptionState= async () => {
    const token = UserToken();
    try{
        const response =await fetch(SEARCH_API_URL+'/SubscriptionManager/CurrentSubscriptionState',{
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`},
        });
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    }
  }

  
export const GetSubscriptions= async () => {
  const token = UserToken();
  try{
      const response =await fetch(SEARCH_API_URL+'/SubscriptionManager/MySubscriptions',{
          method: 'GET',
          headers: {"Authorization": `Bearer ${token}`},
      });
      if(response.status != 200){
          return null;
        }
      return response.json();
  }catch(err)  {
    return null;
  }
}

  
export const GetFactures= async () => {
  const token = UserToken();
  try{
      const response =await fetch(SEARCH_API_URL+'/SubscriptionManager/Factures',{
          method: 'GET',
          headers: {"Authorization": `Bearer ${token}`},
      });
      if(response.status != 200){
          return null;
        }
      return response.json();
  }catch(err)  {
    return null;
  }
}
export const GetAvoirs= async () => {
  const token = UserToken();
  try{
      const response =await fetch(SEARCH_API_URL+'/SubscriptionManager/Avoirs',{
          method: 'GET',
          headers: {"Authorization": `Bearer ${token}`},
      });
      if(response.status != 200){
          return null;
        }
      return response.json();
  }catch(err)  {
    return null;
  }
}
export const GetCommandes= async () => {
  const token = UserToken();
  try{
      const response =await fetch(SEARCH_API_URL+'/SubscriptionManager/BonsCommandes',{
          method: 'GET',
          headers: {"Authorization": `Bearer ${token}`},
      });
      if(response.status != 200){
          return null;
        }
      return response.json();
  }catch(err)  {
    return null;
  }
}
export const ValiderCommande= async (id) => {
  const token = UserToken();
  try{
      const response =await fetch(SEARCH_API_URL+'/SubscriptionManager/BonsCommandes/ValiderCommande/'+id,{
          method: 'PUT',
          headers: {"Authorization": `Bearer ${token}`},
      });
      if(response.status != 200){
          return null;
        }
      return response.json();
  }catch(err)  {
    return null;
  }
}
export const AnnulerCommande= async (id) => {
  const token = UserToken();
  try{
      const response =await fetch(SEARCH_API_URL+'/SubscriptionManager/BonsCommandes/AnnulerCommande/'+id,{
          method: 'PUT',
          headers: {"Authorization": `Bearer ${token}`},
      });
      if(response.status != 200){
          return null;
        }
      return response.json();
  }catch(err)  {
    return null;
  }
}
export const GetTypesAbonnements= async () => {
  const token = UserToken();
  try{
      const response =await fetch(SEARCH_API_URL+'/SubscriptionManager/TypesAbonnements',{
          method: 'GET',
          headers: {"Authorization": `Bearer ${token}`},
      });
      if(response.status != 200){
          return null;
        }
      return response.json();
  }catch(err)  {
    return null;
  }
}
export const GetDevises= async () => {
  const token = UserToken();
  try{
      const response =await fetch(SEARCH_API_URL+'/SubscriptionManager/Devises',{
          method: 'GET',
          headers: {"Authorization": `Bearer ${token}`},
      });
      if(response.status != 200){
          return null;
        }
      return response.json();
  }catch(err)  {
    return null;
  }
}

export const GetTaxes= async () => {
  const token = UserToken();
  try{
      const response =await fetch(SEARCH_API_URL+'/SubscriptionManager/Taxes',{
          method: 'GET',
          headers: {"Authorization": `Bearer ${token}`},
      });
      if(response.status != 200){
          return null;
        }
      return response.json();
  }catch(err)  {
    return null;
  }
}

export const GetNewCommandeData= async () => {
  const token = UserToken();
  try{
      const response =await fetch(SEARCH_API_URL+'/SubscriptionManager/BonsCommandes/GetNewCommandeData',{
          method: 'GET',
          headers: {"Authorization": `Bearer ${token}`},
      });
      if(response.status != 200){
          return null;
        }
      return response.json();
  }catch(err)  {
    return null;
  }
}

export const AddNewCommande= async (type) => {
  const token = UserToken();
  try{
      const response =await fetch(SEARCH_API_URL+'/SubscriptionManager/BonsCommandes/AddBonCommande',{
          method: 'POST',
          headers: {
            "Authorization": `Bearer ${token}`,             
            "Content-Type":"application/json",
          },
          body: JSON.stringify(type)
      });
      if(response.status != 200){
          return null;
        }
      return response.json();
  }catch(err)  {
    return null;
  }
}

export const GetInformations= async () => {
  const token = UserToken();
  try{
      const response =await fetch(SEARCH_API_URL+'/SubscriptionManager/ClientInfo',{
          method: 'GET',
          headers: {"Authorization": `Bearer ${token}`},
      });
      if(response.status != 200){
          return null;
        }
      return response.json();
  }catch(err)  {
    return null;
  }
}


export const SetMigrationState= async () => {
  const token = UserToken();
  try{
      const response =await fetch(SEARCH_API_URL+'/SubscriptionManager/SetUserMigrationState?state='+localStorage.getItem("MigrationState"),{
          method: 'POST',
          headers: {
            "Authorization": `Bearer ${token}`,             
            "Content-Type":"application/json",
          },
          body: JSON.stringify({})
      });
      if(response.status != 200){
          return false;
        }
      return true
  }catch(err)  {
    return null;
  }
}

export const EditInfo= async (info) => {
  const token = UserToken();
  try{
      const response =await fetch(SEARCH_API_URL+'/SubscriptionManager/EditInfo',{
          method: 'PUT',
          headers: {
            "Authorization": `Bearer ${token}`,             
            "Content-Type":"application/json",
          },
          body: JSON.stringify(info)
      });
      if(response.status != 200){
          return null;
        }
      return response.json();
  }catch(err)  {
    return null;
  }
}
export const EditAutoRenew= async (AutoRenew) => {
  const token = UserToken();
  try{
      const response =await fetch(SEARCH_API_URL+'/SubscriptionManager/EditAutoRenew?AutoRenew='+AutoRenew,{
          method: 'PUT',
          headers: {
            "Authorization": `Bearer ${token}`,             
            "Content-Type":"application/json",
          },
          body: JSON.stringify({})
      });
      if(response.status != 200){
          return null;
        }
      return response.json();
  }catch(err)  {
    return null;
  }
}

export const GetFactureFile= async (id) => {
  const token = UserToken();
  try{
      const response =await fetch(SEARCH_API_URL+'/SubscriptionManager/FacturePDF/'+id,{
          method: 'GET',
          headers: {
            "Authorization": `Bearer ${token}`,
            "responseType": 'blob'
          },
      });
      if(response.status != 200){
          return null;
        }
      return response.blob();
  }catch(err)  {
    return null;
  }
}
export const GetCommandeFile= async (id) => {
  const token = UserToken();
  try{
      const response =await fetch(SEARCH_API_URL+'/SubscriptionManager/CommandePDF/'+id,{
          method: 'GET',
          headers: {
            "Authorization": `Bearer ${token}`,
            "responseType": 'blob'
          },
      });
      if(response.status != 200){
          return null;
        }
      return response.blob();
  }catch(err)  {
    return null;
  }
}
export const GetAvoirFile= async (id) => {
  const token = UserToken();
  try{
      const response =await fetch(SEARCH_API_URL+'/SubscriptionManager/AvoirPDF/'+id,{
          method: 'GET',
          headers: {
            "Authorization": `Bearer ${token}`,
            "responseType": 'blob'
          },
      });
      if(response.status != 200){
          return null;
        }
      return response.blob();
  }catch(err)  {
    return null;
  }
}