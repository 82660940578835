import {SEARCH_API_URL} from "config/globals" ;
import { UserToken } from "config/Token";

// get all Impositions
export const getAllImpositon =async () =>{
    const token = UserToken();
    try{
        const response = await fetch(SEARCH_API_URL+'/Options/GetImpositions', {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    } 
};