import  React from 'react';
import {useEffect,useState,useContext} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import HomeIcon from '@mui/icons-material/Home';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { GetSubscriptions,GetCommandes,GetFactures,GetInformations,GetAvoirs} from 'Services/SubscriptionManageService';
import AppDataContext from "Contexts/AppDataContext";
import Moment from 'moment';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import BonsCommandesComponent  from 'components/private/Subscriptions/BonsCommandesComponent';
import AbonnementsComponent  from 'components/private/Subscriptions/AbonnementsComponent';
import FacturesComponent from 'components/private/Subscriptions/FacturesComponent';
import AvoirsComponent from 'components/private/Subscriptions/AvoirsComponent';
import InformationsClientComponent from 'components/private/Subscriptions/InformationsClientComponent';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{width:'100%'}}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3}}>
          {children}
        </Box>
      )}
    </div>
  );
}
export default function ManageSubscription() {
  const [ Informations, setInformations] = useState(null);
  const [ Avoirs, setAvoirs] = useState([]);
  const [ Factures, setFactures] = useState([]);
  const [ Abonnements, setAbonnements] = useState([]);
  const [ Commandes, setCommandes] = useState([]);
  const [ loading, setloading]=useState(false);
  const {setServerError} = useContext(AppDataContext);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch(newValue){   
      case 0: 
        fetchClientInformations();
        break;  
      case 1:
        fetchCommandes();
        break;
      case 2:
        fetchAbonnements();
        break;
      case 3:
        fetchFactures();
        break;
      case 4:
        fetchAvoirs();
        break;
    }
  };
  const fetchAvoirs =()=>{
    setloading(true)
    GetAvoirs()
    .then(res => {
      if(res != null){
        setAvoirs(res);
        setloading(false);
      }else{
        setloading(false);
        setServerError(true);
      }
 
    })
    .catch(err => console.log(err))
  }
  const fetchFactures =()=>{
    setloading(true)
    GetFactures()
    .then(res => {
      if(res != null){
        setFactures(res);
        setloading(false);
      }else{
        setloading(false);
        setServerError(true);
      }
 
    })
    .catch(err => console.log(err))
  }
  
  const fetchCommandes =()=>{
    setloading(true)
    GetCommandes()
    .then(res => {
      if(res != null){
        setCommandes(res);
        setloading(false);
      }else{
        setloading(false);
        setServerError(true);
      }
 
    })
    .catch(err => console.log(err))
  }

 const fetchAbonnements =()=>{
   setloading(true)
   GetSubscriptions()
   .then(res => {
     if(res != null){
      setAbonnements(res);
       setloading(false);
     }else{
       setloading(false);
       setServerError(true);
     }

   })
   .catch(err => console.log(err))
 }
 
 const fetchClientInformations =()=>{
  setloading(true)
  GetInformations()
  .then(res => {
    if(res != null){
      setInformations(res);
      setloading(false);
    }else{
      setloading(false);
      setServerError(true);
    }

  })
  .catch(err => console.log(err))
}

 useEffect(() => {
  fetchClientInformations();
 }, []);
 return (
  <Box sx={{ flexGrow: 1, display: 'flex', mt:4,mb:4 }}>
          <Tabs
             orientation="vertical"
             variant="scrollable"
             value={value}
             onChange={handleChange}
             aria-label="Vertical tabs example"
             sx={{ borderRight: 1, borderColor: 'divider',minWidth:'300px',maxWidth:'350px' }}
            >
             <Tab label={<b>Mes informations</b>} id="vertical-tab-0" aria-controls="vertical-tabpanel-0" />
             <Tab label={<b>Mes bons de commande</b>} id="vertical-tab-1" aria-controls="vertical-tabpanel-1"  />
             <Tab label={<b>Mes abonnements</b>} id="vertical-tab-2" aria-controls="vertical-tabpanel-2" />
             <Tab label={<b>Mes factures</b>} id="vertical-tab-3" aria-controls="vertical-tabpanel-3" />
             <Tab label={<b>Mes avoirs</b>}  id="vertical-tab-4" aria-controls="vertical-tabpanel-4" />
           </Tabs>
           <TabPanel value={value} index={0}>
                <Box sx={{ display:( loading ? 'flex' : 'none' ), textAlign:'center',width:'100%' ,justifyContent:'center' }}>
                   <CircularProgress disableShrink />
               </Box>
               <Box sx={{ display:( loading ? 'none': 'flex'),width:'100%'}}>
                              {
                              !loading && value==0 ? 
                              <InformationsClientComponent informations={Informations} reloadCommandes={fetchClientInformations} /> 
                              : 
                              <></>
                            }
               </Box>
           </TabPanel>
           <TabPanel value={value} index={1}>
               <Box sx={{ display:( loading ? 'flex' : 'none' ), textAlign:'center',width:'100%' ,justifyContent:'center' }}>
                   <CircularProgress disableShrink />
               </Box>
               <Box sx={{ display:( loading ? 'none': 'flex'),width:'100%'}}>
                              {
                              !loading && value==1 ? 
                              <BonsCommandesComponent commandes={Commandes} reloadCommandes={fetchCommandes} /> 
                              : 
                              <></>
                            }
               </Box>
           </TabPanel>
           <TabPanel value={value} index={2}>
               <Box sx={{ display:( loading ? 'flex' : 'none' ), textAlign:'center',width:'100%' ,justifyContent:'center' }}>
                   <CircularProgress disableShrink />
               </Box>
               <Box sx={{ display:( loading ? 'none': 'flex'),width:'100%'}}>
                              {
                              !loading && value==2 ? 
                              <AbonnementsComponent abonnements={Abonnements} reloadCommandes={fetchAbonnements} /> 
                              : 
                              <></>
                            }
               </Box>

           </TabPanel>
           <TabPanel value={value} index={3}>
               <Box sx={{ display:( loading ? 'flex' : 'none' ), textAlign:'center',width:'100%' ,justifyContent:'center' }}>
                   <CircularProgress disableShrink />
               </Box>
               <Box sx={{ display:( loading ? 'none': 'flex'),width:'100%'}}>
                              {
                              !loading && value==3 ? 
                                <FacturesComponent factures={Factures} reloadCommandes={fetchFactures} /> 
                              : 
                              <></>
                            }
               </Box>           
            </TabPanel>
           <TabPanel value={value} index={4}>
               <Box sx={{ display:( loading ? 'flex' : 'none' ), textAlign:'center',width:'100%' ,justifyContent:'center' }}>
                   <CircularProgress disableShrink />
               </Box>
               <Box sx={{ display:( loading ? 'none': 'flex'),width:'100%'}}>
                              {
                              !loading && value==4 ? 
                                <AvoirsComponent avoirs={Avoirs} /> 
                              : 
                              <></>
                            }
               </Box>  
           </TabPanel>
  </Box>

 );
}
