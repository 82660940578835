import React from "react";
import {useEffect,useState,useContext} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { AddUsers,EditUsers,ChangeAccountState } from 'Services/SubscriptionUsersService';
import {GetUAllUsersNumbers} from "Services/statistiquesService";
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import EditIcon from '@mui/icons-material/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import HistoryIcon from '@mui/icons-material/History';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import validator from 'validator';
import FormControlLabel from '@mui/material/FormControlLabel';
import AppDataContext from "Contexts/AppDataContext";
import MesLogins from "./MesLogins";
import Historique from "./Historique";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 1,
  px: 1,
  pb: 1,
};
const styleInfoModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%', 
  height: '80%',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 1,
  px: 1,
  pb: 1,
};
export default function ManageUsers (props) {
  const {setServerError} = useContext(AppDataContext);
  const [ validation, setvalidation] = useState({ errorNom : false, textNom:'',errorMail:false,textmail:'',errorPassword:false,textpassword:''});
  const [ selectedUser, setselectedUser] = useState({});
  const [ Users, setUsers] = useState([]);
  const [ loading, setloading]=useState(false);
  const [action,setaction] = useState('add');
  const [formPopup, setformPopup] = useState(false);
  const [InfoPopup, setInfoPopup] = useState(false);

  const handleCloseInfoPopup = () => setInfoPopup(false); 

  const handleformPopup = (action,user) => {
    switch(action){
      case 'add':
        setselectedUser({
          fullName:'',
          email:'',
          password:'',
          disabled:false
        })
        setformPopup(true);
        break;
      case 'update':
        setselectedUser(user);
        setformPopup(true);
        break;
      case 'logins':
        setselectedUser(user);
        setInfoPopup(true)
        break;
      
        case 'history':
          setselectedUser(user);
          setInfoPopup(true)
          break;
        default:
          break;
    }
    setaction(action)
  }

  const handleFieldChanged = (field, value) => {
    let formData = {...selectedUser};
    formData[field] = value;
    setselectedUser(formData);
  }
  const validateForm = ()=>{
    let formValid= true;
    let Currentvalidation = { errorNom : false, textNom:'',errorMail:false,textmail:'',errorPassword:false,textpassword:''};
    if(selectedUser.fullName.length < 2){
      formValid=false;
      Currentvalidation.errorNom = true;
      Currentvalidation.textNom="Le nom du collaborateur invalide : doit être supérieur à 2 caractères. "
    }

    if(!validator.isEmail(selectedUser.email)){
      formValid=false;
      Currentvalidation.errorMail = true;
      Currentvalidation.textmail="Email est invalide"
    }

    if(selectedUser.password.length < 3){
      formValid=false;
      Currentvalidation.errorPassword = true;
      Currentvalidation.textpassword="Mot de passe est invalide : doit être supérieur à 3 caractères. "
    }
    setvalidation(Currentvalidation)
    return formValid;
  }
  //Validation de formulaire
  const onValidationClicked =() => {
    if( validateForm()){
      if(action == 'add'){
        AddUsers(selectedUser)
        .then(res => {
          if(res!= null){
            handleCloseformPopup(true);
            setselectedUser({});
            setUsers([]);
            loadUsers();
          }else{
            handleCloseformPopup(true);
            setServerError(true)
          }

        })
      }else{      
        EditUsers(selectedUser)
        .then(res => {
          if(res!=null){
            handleCloseformPopup(true);
            setselectedUser({});
            setUsers([]);
            loadUsers();
          }else{
            handleCloseformPopup(true);
            setServerError(true)
          }

        })
      }
    }
  }
  
  const handleCloseformPopup = () => setformPopup(false); 

  const AccountStateChanged= (id,checked)=>{   
    ChangeAccountState(id,checked).then((res)=>{
      loadUsers();
    });    
  };

  const loadUsers = () =>{
    setloading(true);
    GetUAllUsersNumbers()
    .then(res => {
      if(res!= null){
        setUsers(res);
        setloading(false);
      }else{
        setServerError(true);
      }

    })
  }
  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <Box sx={{ flexGrow: 1,mt:4,mb:4 }}>
    <Box sx={{ display:( loading ? 'flex' : 'none' ), textAlign:'center',width:'100%' ,justifyContent:'center' }}>
        <CircularProgress disableShrink />
    </Box>
     {/*


          <Box  sx={{ textAlign:'center',width:'100%' ,justifyContent:'center',m:2 }}>
            <Button  variant="contained" onClick={(e)=>handleformPopup('add')} startIcon={<AddIcon />}>
                  Ajouter un utilisateur 
              </Button>
          </Box>    
     
     */} 
        <Box sx={{ display:( loading ? 'none': 'flex'),width:'100%' }}>

    <TableContainer component={Paper}> 
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 }
          }}
        >
            <Typography
              sx={{ flex: '1 1 100%' }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Liste des utilisateurs de cet abonnement
            </Typography>
        </Toolbar>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell> Nom et prénom </TableCell>
                <TableCell> Email </TableCell>
                <TableCell> Compte actif </TableCell>
                <TableCell>Recherches</TableCell>
                <TableCell>Documents consultés</TableCell>
                <TableCell>Accès</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
              Users.map((user,i) => (
                <TableRow sx={{backgroundColor: (user.isClientMananger ? "#e5f6fd" : "")}} key={"user_"+i}>
                  <TableCell>
                    <b>{user.fullName}</b>
                  </TableCell>
                  <TableCell>
                    <b>{user.email}</b>
                  </TableCell>
                  <TableCell>
                    {
                      !user.isClientMananger ? <Switch key={"switch_"+user.id_user} checked={!user.disabled} onChange={(e)=>AccountStateChanged(user.id_user,!e.target.checked)} size="small" />  : <></>
                    }                     
                  </TableCell>
                  <TableCell>{user.nbrRech}</TableCell>
                  <TableCell>{user.nbrDocConsult}</TableCell>
                  <TableCell>{user.nbrAcces}</TableCell>
                  <TableCell>
                    {
                      !user.isClientMananger ?
                        <IconButton onClick={(e)=>handleformPopup('update',user)}  aria-label="Modifier" size="large">
                            <EditIcon variant="outlined" color="success" /> 
                        </IconButton>
                      : 
                       <></>
                    }
                        <IconButton onClick={(e)=>handleformPopup('logins',user)}  aria-label="Modifier" size="large">
                            <LockResetIcon variant="outlined" color="info" /> 
                        </IconButton>
                        <IconButton onClick={(e)=>handleformPopup('history',user)}  aria-label="Modifier" size="large">
                            <HistoryIcon variant="outlined" color="warning" /> 
                        </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                  <TableCell>
                    <b>Total</b>
                  </TableCell>
                  <TableCell>
                  <b>Tous les utilisateurs</b>
                  </TableCell>
                  <TableCell>                
                  </TableCell>
                  <TableCell><b> {Users.reduce((total,currentItem) =>  total = total + currentItem.nbrRech , 0 )} Recherches </b></TableCell>
                  <TableCell><b> {Users.reduce((total,currentItem) =>  total = total + currentItem.nbrDocConsult , 0 )} Documents consultés </b></TableCell>
                  <TableCell><b>{Users.reduce((total,currentItem) =>  total = total + currentItem.nbrAcces , 0 )} Accès </b></TableCell>
                  <TableCell>
                  </TableCell>
              </TableRow>
            </TableBody>
          </Table>  
        </TableContainer>
    </Box>

    <Modal
        keepMounted
        open={InfoPopup}
        onClose={handleCloseInfoPopup}
      >
            <Card sx={styleInfoModal} >
                <CardHeader
                    title={action == 'logins' ? "Historique des connexions : "+selectedUser.fullName : "Historique des recherche : "+selectedUser.fullName}  
                />
                <CardContent>
                    {action == 'logins' ? <MesLogins id_user={selectedUser.id_user} /> : <></>}
                    {action == 'history' ? <Historique id_user={selectedUser.id_user} /> : <></>}
                </CardContent>
                <CardActions>
                     <Button onClick={handleCloseInfoPopup} variant="outlined" >Fermer</Button>
                </CardActions>
              </Card>
      </Modal>


    <Modal
        keepMounted
        open={formPopup}
        onClose={handleCloseformPopup}
      >
            <Card sx={style} >
                <CardHeader
                    title={action == 'add' ? "Ajouter un utilisateur" : "Modifier un untilisateur "}  
                />
                <CardContent>
                  <FormControl sx={{mb:2}} fullWidth>
                    <TextField  id="fullName" 
                    error={validation.errorNom}  InputLabelProps={{ shrink: true }} onChange={(e)=>handleFieldChanged('fullName',e.target.value)}
                         helperText={validation.textNom} label="Nom et prénom" variant="outlined" value={selectedUser.fullName} />
                  </FormControl>
                  <FormControl sx={{mb:2}} fullWidth>
                    <TextField id="email" type="email" error={validation.errorMail}  onChange={(e)=>handleFieldChanged('email',e.target.value)}  InputLabelProps={{ shrink: true }}
                         helperText={validation.textmail} label="Email" variant="outlined" value={selectedUser.email}/>
                  </FormControl>
                  <FormControl sx={{mb:2}} fullWidth>
                    <TextField id="password" type="password"  error={validation.errorPassword} onChange={(e)=>handleFieldChanged('password',e.target.value)}   InputLabelProps={{ shrink: true }}
                         helperText={validation.textpassword} label="Mot de passe" variant="outlined" value={selectedUser.password} />
                  </FormControl>
                  <FormControl  sx={{mb:2}} fullWidth>
                        <FormControlLabel
                              control={
                                <Switch checked={!selectedUser.disabled} onChange={(e)=>handleFieldChanged('disabled',e.target.checked)} name="Etat du compte" />
                              }
                              label="Compte actif"
                            />
                  </FormControl>
                </CardContent>
                <CardActions>
                     <Button onClick={handleCloseformPopup} variant="outlined" >Annuler</Button>
                     <Button onClick={onValidationClicked} variant="contained" style={{marginLeft: 'auto'}}>  {action == 'add' ? "Ajouter" : "Modifier"}  </Button>
                </CardActions>
              </Card>
      </Modal>
</Box>
  );
}

 