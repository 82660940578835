import React from "react";
import {useEffect,useContext} from 'react';
import AppDataContext from "Contexts/AppDataContext";
import AppAuthContext from "Contexts/AppAuthContext";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const AlertFeed = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
export default function ConnectionsError(){
    const {ServerError, setServerError} = useContext(AppDataContext);
    const {userToken} = useContext(AppAuthContext);
    useEffect(() => {
    }, [ServerError,userToken]);

    return( 
        ( userToken ?        
             <Snackbar anchorOrigin={{ vertical : 'top' , horizontal: 'center' }}
                    open={ServerError} autoHideDuration={null}>
                <AlertFeed severity="error" sx={{ width: '100%' }}>
                    Une erreur se produite lors de la communication avec les seruvers, <b> <a onc style={{textDecoration:'none',color:'white'}} href="/logout"> Se déconnecter et réessayer de nouveau </a>  </b>             
                </AlertFeed>
            </Snackbar>

            :  <></>)

        )
}