import {SEARCH_API_URL} from "config/globals" ;
import { UserToken } from "config/Token";

export const GetDocsFavorisIds = async () => {
    try{
        const response = await fetch(SEARCH_API_URL+'/FavoriteDocument',{
            method: 'GET',
            headers: {"Authorization": `Bearer ${UserToken()}`},
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    }  
}


export const GetDocsFavorisWithDocsInfo = async () => {
    try{
        const response = await fetch(SEARCH_API_URL+'/FavoriteDocument/WithDocumentsInfo',{
            method: 'GET',
            headers: {"Authorization": `Bearer ${UserToken()}`},
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    }  
}

export const AddDocToFavoris = async (id_document,id_search) =>{
    try{
        const response = await fetch(SEARCH_API_URL+'/FavoriteDocument',{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${UserToken()}`
            },
            body: JSON.stringify({indexDoc : id_document,id_search:id_search})
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    }  
}

export const DeleteDocFromFavoris  = async(id_document) => {
    try{
        const response = await fetch( SEARCH_API_URL+'/FavoriteDocument/Document/'+id_document,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                "Authorization": `Bearer ${UserToken()}`
             }
        })
        if(response.status != 200){
            return null;
          }
        return true;
    }catch(err)  {
      return null;
    }  
}