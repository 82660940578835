import {useEffect,useState,useContext} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { GetCommandes,ValiderCommande,AnnulerCommande,GetTypesAbonnements,GetDevises,GetTaxes,GetNewCommandeData ,AddNewCommande } from 'Services/SubscriptionManageService';
import AppDataContext from "Contexts/AppDataContext";
import AppAuthContext from "Contexts/AppAuthContext";
import Moment from 'moment';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import {GetCommandeFile} from 'Services/SubscriptionManageService';
import { saveAs } from 'file-saver';
import CircularProgress from '@mui/material/CircularProgress';
import {PAIEMENT_URL} from "config/globals" ;
const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 1,
  px: 1,
  pb: 1,
};
export default function BonsCommandesComponent(props) {
  const [ Commandes, setCommandes] = useState([]);
  const [ TypesAbonnements, setTypesAbonnements] = useState([]);
  const [ Devises, setDevises] = useState([]);
  const [ Taxes, setTaxes] = useState(null);
  const [ selectedCommande, setselectedCommande]=useState(null); 
   const {IdAbonnement} = useContext(AppAuthContext);
  const {setServerError} = useContext(AppDataContext);
  const [showAnnulationPopup, setshowAnnulationPopup] = useState(false);
  const [showPaymentPopup, setshowPaymentPopup] = useState(false);
  const [showNewCommandePopup, setNewCommandePopup] = useState(false);
  const [ModePayment, setModePayment] = useState("");
  const [selectedAbonnementId,setselectedAbonnementId] = useState("");
  const [selectedDeviseId,setselectedDeviseId] = useState("");
  const [ selectedType, setselectedType]=useState(null);
  const [ selectedDevise, setselectedDevise]=useState(null);
  const [IdDownloading,setIdDownloading]= useState(""); 
  const [hasCommandeEnAttente, sethasCommandeEnAttente] = useState(false);

  const handleModePaymentChange = (event) => {
    setModePayment(event.target.value);
  }

  const NewCommandeForm=()=>{
    setNewCommandePopup(true);  
    GetNewCommandeData()
    .then(res => {
      if(res != null){
        setselectedCommande(res);
        let type = TypesAbonnements.find(x=>x.id == res.type.id);
        setselectedType(type);
        setselectedAbonnementId(type.id);
        let devise = Devises.find(x=>x.id == res.type.id);
        setselectedDevise(devise);
        setselectedDeviseId(devise.id);
      }else{
        setServerError(true);
      }
    })

  }
  const handleCloseNewCommandePopup = () => {
    setNewCommandePopup(false);
  }
  const handleClosePaymentPopup = () => {
    setshowPaymentPopup(false);
  }
  const handleCloseAnnulationPopup = () => {
    setshowAnnulationPopup(false);
  }
  
  const ValiderCommande = ()=>{
    if(selectedType != null){
      AddNewCommande(selectedType).then(res => {
        if(res != null){
          props.reloadCommandes();
          handleCloseNewCommandePopup();
        }else{
          setServerError(true);
        }
      })
    }
  }
 
 const ValiderCommandeConfirm = (cmd)=>{
  setshowPaymentPopup(true);
  setselectedCommande(cmd);
 }
 const AnnulerCommandeConfirm = (cmd)=>{
  setshowAnnulationPopup(true);
  setselectedCommande(cmd);
 }
 
 const AnnulerCommandeClick =()=>{
  if(selectedCommande != null){
    AnnulerCommande(selectedCommande.id)
    .then(res => {
      if(res != null){
        props.reloadCommandes();
        handleCloseAnnulationPopup();
      }else{
        setServerError(true);
      }
  
    })
  }
}
const selectedAbonnementChange = (event) =>{
  setselectedAbonnementId(event.target.value);
  let type = TypesAbonnements.find(x=>x.id == event.target.value);
  setselectedType(type);
}
const selectedDeviesChange = (event) =>{
  setselectedDeviseId(event.target.value)
}
const FetchTypesAbonnements =()=>{
  GetTypesAbonnements()
  .then(res => {
    if(res != null){
      setTypesAbonnements(res);
    }else{
      setServerError(true);
    }
  })
}
const FetchTaxes =()=>{
  GetTaxes()
  .then(res => {
    if(res != null){
      setTaxes(res);
    }else{
      setServerError(true);
    }
  })
}
const FetchDevises =()=>{
  GetDevises()
  .then(res => {
    if(res != null){
      setDevises(res);
    }else{
      setServerError(true);
    }
  })
}


const saveFile = (id,numG) => {
  setIdDownloading(id);
  GetCommandeFile(id)
  .then(res => {
    if(res != null){
      saveAs(res, 'Commande-dbprofiscal-'+numG+'.pdf');
      setIdDownloading("");
    }else{
      setServerError(true);
      setIdDownloading("");
    }

  })
  .catch(err => console.log(err))
}

 useEffect(() => {
  if(props.commandes != null){
    props.commandes.forEach(commande => {
      if(!commande.commandeAnnulee && (commande.id_Abonnement_Commande == "" || commande.id_Abonnement_Commande == null)){
        sethasCommandeEnAttente(true);
      }
    });
    setCommandes(props.commandes);

  }
  FetchTypesAbonnements();
  FetchTaxes();
  FetchDevises();
 }, []);
 return (
    <>
               <TableContainer component={Paper}> 
                   <Toolbar
                     sx={{
                       pl: { sm: 2 },
                       pr: { xs: 1, sm: 1 }
                     }}
                   >
                       <Typography
                         sx={{ flex: '1 1 100%' }}
                         variant="h6"
                         id="tableTitle"
                         component="div"
                       >
                         Mes Bons de commande
                       </Typography>
                      {
                        /*
                        <Tooltip key={"newCommande"} title={hasCommandeEnAttente ? "Vous avez déjà une commande en attente" :"Nouvelle commande"}>
                            <FormControl sx={{ m: 2, minWidth: 320 }}>
                                    <Button disabled={hasCommandeEnAttente} endIcon={<AddShoppingCartIcon />} onClick={(e)=>NewCommandeForm()} variant="contained" color="primary" style={{marginLeft: 'auto'}}> Nouvelle commande </Button>                                 
                            </FormControl>
                       </Tooltip>                       
                        */
                      } 
                   </Toolbar>
                     <Table sx={{ minWidth: 650 }} aria-label="simple table">
                       <TableHead>
                         <TableRow>
                         <TableCell> Numero </TableCell>
                           <TableCell> Abonnement </TableCell>
                           <TableCell> Période </TableCell>
                           <TableCell> Montant H.T </TableCell>
                           <TableCell> Etat </TableCell>
                           <TableCell></TableCell>
                         </TableRow>
                       </TableHead>
                       <TableBody>
                         {Commandes.map((commande,i) => (
                           <TableRow key={"BonCommande_"+i}>
                             <TableCell> 
                               {commande.numeroGlobal+"/"+Moment(commande.dateCreation).format('YYYY')}
                             </TableCell>
                             <TableCell> 
                               {commande.type.type}
                             </TableCell>
                             <TableCell>
                                 {"Du "+Moment(commande.dateDebut).format('DD/MM/YYYY') +" au "+Moment(commande.dateFin).format('DD/MM/YYYY')}
                             </TableCell>
                             <TableCell>
                               <b>Montant H.T : {commande.montant}</b>  {"("+commande.devise.symbole+")"} <br />
                               <small style={{display:(String(commande.remise) == "0" ? 'none':'')}}>(Remise : {commande.remise+"%"})</small>                                  
                             </TableCell>
                             <TableCell>
                              <b> {commande.commandeAnnulee  ? "Annulé": commande.id_Abonnement_Commande == "" || commande.id_Abonnement_Commande == null ? "En attente" : "Facturé" } </b> 
                             </TableCell>
                             <TableCell>
                            <>
                            {commande.commandeAnnulee  ? 
                                                              <></>
                                                             : commande.id_Abonnement_Commande == "" || commande.id_Abonnement_Commande == null ? 
                                                             <>
                                                             <Tooltip title="Valider et Passer au paiement">                              
                                                               <IconButton onClick={(e)=>ValiderCommandeConfirm(commande)} aria-label="Valider" size="large">
                                                                   <ShoppingCartCheckoutIcon variant="outlined" color="success" /> 
                                                               </IconButton>
                                                             </Tooltip> 

                                                                                               <Tooltip title="Annuler bon de commande">      
                                                                                               <IconButton onClick={(e)=>AnnulerCommandeConfirm(commande)} aria-label="Annuler" size="large">
                                                                                                   <RemoveShoppingCartIcon variant="outlined" color="error" /> 
                                                                                               </IconButton>
                                                                                             </Tooltip>   
                                                             </>
                                                             : <></>  }
                                            <Tooltip sx={{display: IdDownloading == commande.id ? 'none' : ''}}  title="Télécharger">      
                                                    <IconButton onClick={(e)=>saveFile(commande.id,commande.numeroGlobal)} aria-label="Télécharger" size="large">
                                                      <FileDownloadIcon variant="outlined" color="primary" /> 
                                                    </IconButton>
                                            </Tooltip> 
                                            <CircularProgress sx={{display: IdDownloading!=commande.id ? 'none' : ''}} disableShrink />
                            </>
                             </TableCell>
                           </TableRow>
                         ))}
                       </TableBody>
                     </Table>  
               </TableContainer>
          <Modal
              keepMounted
              open={showNewCommandePopup}
              onClose={handleCloseNewCommandePopup}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
            >
            <Card sx={style} >
                <CardHeader
                    title="Validation de la commande"  
                />
                <CardContent>
                  <Box mb={1} p={1}>
                  <FormControl fullWidth>
                    <InputLabel id="abonnement-select-label">Abonnement</InputLabel>
                    <Select
                      labelId="abonnement-select-label"
                      id="abonnement-select"
                      value={selectedAbonnementId}
                      label="Abonnement"
                      onChange={selectedAbonnementChange}
                      >
                      {TypesAbonnements.map((type,i)=>(
                        <MenuItem key={"type_"+i} value={type.id}>{type.type}</MenuItem>
                        )                       
                      )}
                    </Select>
                  </FormControl>
                  </Box>
                  <Box mb={1} p={1}>
                  <FormControl fullWidth>
                    <InputLabel id="Devise-select-label">Devise</InputLabel>
                    <Select
                      labelId="Devise-select-label"
                      id="Devise-select"
                      value={selectedDeviseId}
                      label="Devise"
                      onChange={selectedDeviesChange}
                      disabled={true}
                      >
                      {Devises.map((devise,i)=>(
                        <MenuItem key={"devise_"+i} value={devise.id}>{devise.libelle+"("+devise.symbole+")"}</MenuItem>
                      ))}
                    </Select>
                    <small>Vous pouvez contacter le service commerciale pour effectuer une commande en devise.</small>
                  </FormControl>
                  </Box>                  
                  {selectedCommande != null ?
                  <>
                                    <Box mb={1} p={1}> 
                    <TextField
                      label="Date début d'abonnement"
                      type="date"
                      defaultValue={Moment(selectedCommande.dateDebut).format('YYYY-MM-DD')}
                      sx={{ width: '100%' }}
                      disabled={true}
                    />
                  </Box>
                                      <Box sx={{backgroundColor:'#e3f3fa'}} m={1} p={3}>
                                      Client : <b>{selectedCommande.client.nom}</b><br/>
                                      Matricule Fiscale : <b>{selectedCommande.client.matriculeFiscal}</b><br/>
                                      RNE : <b>{selectedCommande.client.rne}</b><br/>
                                      Adresse de Facturation : <br/> 
                                      <b>{selectedCommande.client.adresse}</b><br/>
                                      <b>{selectedCommande.client.codePostal} {selectedCommande.client.ville}</b><br/>
                                      <b>{selectedCommande.client.pays}</b><br/>
                                      <hr/>
                                      {selectedType != null ?
                                        <table width={'100%'}>
                                              <thead>
                                                 <tr style={{width:'70%',textAlign:'center'}}>
                                                    <th>
                                                      Abonnement
                                                    </th>
                                                    <th style={{textAlign:'right'}}>
                                                      Prix ({selectedDevise != null ? selectedDevise.libelle : ""})
                                                    </th>
                                                 </tr>
                                              </thead>
                                              <tr>
                                                <td>
                                                  {selectedType.type}
                                                </td>
                                                <td style={{textAlign:'right'}}>
                                                  {selectedType.prixAbonnementDinar.toFixed(3)}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  TVA ({Taxes.tauxTVA}%)
                                                </td>
                                                <td style={{textAlign:'right'}}>
                                                  {(selectedType.prixAbonnementDinar*Taxes.tauxTVA/100).toFixed(3)}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  Timbre Fiscale ({Taxes.montantTimbreFiscal})
                                                </td>
                                                <td style={{textAlign:'right'}}>
                                                  {(Taxes.montantTimbreFiscal).toFixed(3)}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  Montant T.T.C
                                                </td>
                                                <td style={{textAlign:'right'}}>
                                                  {(selectedType.prixAbonnementDinar + (selectedType.prixAbonnementDinar*Taxes.tauxTVA/100) + Taxes.montantTimbreFiscal).toFixed(3)}
                                                </td>
                                              </tr>
                                        </table>
                                      :<></>}
                                      </Box>
                                      </> 
                                      :<></>
                }

                </CardContent>
                <CardActions>
                     <Button onClick={handleCloseNewCommandePopup} variant="outlined">Fermer</Button>
                     <Button onClick={ValiderCommande} variant="contained" color="info" style={{marginLeft: 'auto'}}> Valider </Button>
                </CardActions>
              </Card>
      </Modal>
      <Modal
        keepMounted
        open={showAnnulationPopup}
        onClose={handleCloseAnnulationPopup}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
            <Card sx={style} >
                <CardHeader
                    title="Annulation d'une commande"  
                />
                <CardContent>
                  <FormControl fullWidth>
                  <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                    Confirmez-vous l'annulation de cette commande ?
                  </Typography>
                  </FormControl>
                </CardContent>
                <CardActions>
                     <Button onClick={handleCloseAnnulationPopup} variant="outlined">Fermer</Button>
                     <Button onClick={AnnulerCommandeClick} variant="contained" color="error" style={{marginLeft: 'auto'}}> Annuler la commande  </Button>
                </CardActions>
              </Card>
      </Modal>
      <Modal
        keepMounted
        open={showPaymentPopup}
        onClose={handleClosePaymentPopup}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <form method="Get" action={PAIEMENT_URL+"/Commandes/Paiement/123456"} >
        <Card sx={style} >
                <CardHeader
                    title="Validation et paiement de la commande"  
                />
                <CardContent>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Mode de paiement</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={ModePayment}
                      label="Mode de payement"
                      onChange={handleModePaymentChange}
                    >
                      <MenuItem value="1">Carte bancaire (ClickToPay)</MenuItem>
                      <MenuItem value="2">Virement bancaire</MenuItem>
                      <MenuItem value="3">Autres méthodes</MenuItem>
                    </Select>
                  </FormControl>
                  <Box sx={{ display:( ModePayment !="2" ? 'none': ''), backgroundColor:'#e3f3fa'}} m={3} p={3}>
                          Banque : <b>BIAT AGENCE SFAX HABIB THAMEUR (34)</b> <br /> <br />
                          Titulaire du compte bancaire : <b> ST SERV INFO-INTER EXP ON LI </b> <br /><br />
                          RIB : <b>08 804 0003410007680 60</b> <br /><br />
                          Libelle du virement : <b>Commande DBPROFISCAL N° { selectedCommande !=null ? selectedCommande.numeroGlobal+"/"+Moment(selectedCommande.dateCreation).format('YYYY') : ""}</b> <br />
                          <p>
                            PS : À fin d'accélérer la procédure de traitement du virement, <br/>
                            vous pouvez nous envoyer une copie de l'ordre du virement avec cachet de la banque Vers l'adresse mail : <b>payements@dbprofiscal.com</b>. 
                          </p>
                          <b>Votre commande sera validée et facturée automatiquement dès la réception du virement.</b>
                  </Box>
                  <Box sx={{ display:( ModePayment !="3" ? 'none': ''), backgroundColor:'#e3f3fa'}} m={3} p={3}>
                          <b>Vous pouvez nous contacter pour plus d'informations</b>
                  </Box>
                </CardContent>
                <CardActions>
                     <Button onClick={handleClosePaymentPopup} variant="outlined">Fermer</Button>
                     <Button type="submit" disabled={ModePayment!="1"} variant="contained" color="info" style={{marginLeft: 'auto'}}> Passer au paiement </Button>
                </CardActions>
              </Card>
        </form>

      </Modal>
    </>
 );
}
