import {SEARCH_API_URL} from "config/globals" ;
import { UserToken } from "config/Token";

export const GetUsers= async () => {
    try{
        const response =await fetch(SEARCH_API_URL+'/SubscriptionUsers/GetUsers', {
            method: 'GET',
            headers: {"Authorization": `Bearer ${UserToken()}`},
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    }
}
export const AddUsers= async (newUser) => {
    try{
        const response = await fetch(SEARCH_API_URL+'/SubscriptionUsers/AddUser',{
            method: 'POST',        
            headers: {
                "Content-Type":"application/json",
                "Authorization": `Bearer ${UserToken()}`
            },
            body: JSON.stringify(newUser)
        })
        if(response.status != 200){
            return null;
          }
        return true;
    }catch(err)  {
      return null;
    }
}
export const EditUsers= async (EditUser) => {
    try{
        const response = await fetch(SEARCH_API_URL+'/SubscriptionUsers/EditUser/'+EditUser.id_user,{
            method: 'PUT',
            headers: {
                "Content-Type":"application/json",
                "Authorization": `Bearer ${UserToken()}`
            },
            body: JSON.stringify(EditUser)
        })
        if(response.status != 200){
            return null;
          }
        return true;
    }catch(err)  {
      return null;
    }
}
export const ChangeAccountState= async (id,Disabled) => {

    try{
        const response = await fetch(SEARCH_API_URL+'/SubscriptionUsers/ChangeAccountState/'+id+"?disabled="+Disabled,{
            method: 'PUT',
            headers: {
                "Content-Type":"application/json",
                "Authorization": `Bearer ${UserToken()}`
            },
            body: JSON.stringify({})
        })
        if(response.status != 200){
            return null;
          }
        return true;
    }catch(err)  {
      return null;
    }
}