import * as React from 'react';
import {useEffect,useState,useContext} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { GetHistoriqueTableData,AddOpenDocToSearch } from 'Services/HistoriqueService';
import LinkToViewer from 'components/private/Search/LinkToViewer';
import LinkBookToViewer from "components/private/Search/LinkBookToViewer";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AppDataContext from "Contexts/AppDataContext";
import AppAuthContext from "Contexts/AppAuthContext";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
export default function Historique(props) {
  const {setServerError} = useContext(AppDataContext);
  const {userId} = useContext(AppAuthContext);
  const [ Historique, setHistorique] = useState([]);
  const [ BackDays, setBackDays]=useState(5);
  const [ loading, setloading]=useState(false);
  const [ onlyDocHistory , setonlyDocHistory]=useState(true);
  const handleChange = (event) => {
    setBackDays(event.target.value);
    fetchHistorique(event.target.value)
  };
  const fetchHistorique =(days)=>{
    setloading(true)
    GetHistoriqueTableData(props.id_user==null ? userId : props.id_user,days)
    .then(res => {
      if(res!=null){
        setHistorique(res);
        setloading(false);
      }else{
        setServerError(true)
      }
    })
  }
 const ShowDocChange = ()=>{
  setonlyDocHistory(!onlyDocHistory);
 }
 const DocOpened = (id_document,id_search)=> {
  AddOpenDocToSearch(id_search,{index_file:id_document,
    downloaded:false,
    downloadedWithOutOpen:false,
    component:'historyWeb'})
  .then(res => {
    if(res){
    }else{
      setServerError(true);
    }

  });
}
useEffect(() => {
  if(userId != null && userId!='' && userId !=undefined){
    setBackDays(5);
    fetchHistorique(5);
  }
}, [userId,props.id_user]);
  return (
    <>
    
<Box sx={{ flexGrow: 1,mt:4,mb:4 }}>
<Box sx={{ display:( loading ? 'flex' : 'none' ), textAlign:'center',width:'100%' ,justifyContent:'center' }}>
     <CircularProgress disableShrink />
</Box>
<Box sx={{ display:( loading ? 'none': 'flex')}}>

<TableContainer component={Paper}> 
<Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 }
      }}
    >
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          key={"tableTitle"}
          component="div"
        >
          Historique des recherches
        </Typography>

        <Tooltip key={"onlyDocHistory"} title={"Présence des documents consultés"}>
          <FormControl sx={{ m: 2, minWidth: 320 }}>
            <FormControlLabel control={<Checkbox  checked={onlyDocHistory} onChange={(e)=>ShowDocChange()} />} label="Afficher uniquement les recherches avec des documents consultés" />
          </FormControl>
        </Tooltip>
        <Tooltip key={"BackDays"} title={"Période d'historique"}>
          <FormControl sx={{ m: 2, minWidth: 220 }}>
            <InputLabel id="demo-simple-select-label">Période</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={BackDays}
              label="Période"
              onChange={handleChange}
            >
              <MenuItem key={"option5"} value={5}>5 derniers jours</MenuItem>
              <MenuItem key={"option10"}value={10}>10 derniers jours</MenuItem>
              <MenuItem key={"option30"} value={30}>30 derniers jours</MenuItem>
            </Select>
          </FormControl>
        </Tooltip>

    </Toolbar> 
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow key={"headerTable"}>
            <TableCell> Date </TableCell>
            <TableCell > Recherche </TableCell>
            <TableCell sx={{width:'60%'}} > Documents</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Historique.map((historique,i) => (
            <TableRow key={"historique_"+i} sx={{display:(onlyDocHistory ? (historique.opendFiles.length>0 ? '' : 'none') : '')}}>
              <TableCell component="th" scope="row">
                {historique.date_Rech}
              </TableCell>
              <TableCell>
                
                  Mots clés : <b> {historique.keyWords} </b> <br />
                  <small>
                  Période : {historique.intervalAnnees}<br />
                  Types : {historique.documentsTypes}<br />
                  Imposition : {historique.Imposition} <br/>
                  Moteur de recherche : {historique.index} <br/>
                  Nombre Documents trouvés : {historique.nbrDocResult}
                  </small>

              </TableCell>
              <TableCell>
              <Table size="small">
                  <TableBody>
                    {historique.opendFiles.map((doc,j) => (
                        <TableRow key={historique.id_rech+"-"+j}>
                        <TableCell sx={{width:'30%'}}>
                          {
                          historique.id_user == userId ?
                          <>
                               {
                                  historique.index == "ery_books" ? 
                                  <LinkBookToViewer 
                                  id_search={historique.id_rech}
                                  id={doc.id}
                                  source="history"
                                  id_file_store={doc.id_File_Store} 
                                  text = {doc.reference} isReference={true}
                                  DocOpened={DocOpened}
                                />

                                  :
                                  <LinkToViewer 
                                  id_search= {historique.id_rech}
                                  source="history"
                                  id={doc.id}
                                  id_file_store={doc.id_File_Store} 
                                  text = {doc.reference}
                                  isReference={true}
                                  DocOpened={DocOpened}
                                /> 

                                }
                          </>

                          : 
                          <b>{doc.reference}</b>
                            }


                        </TableCell>
                        <TableCell> {doc.titre}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
</Box>

</Box>

    </>
  );
}
