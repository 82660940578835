import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {useState,useEffect,useContext} from 'react';
import { DeleteDossier,UpdateDossier,PostDossierDocument,getDocumentsByIdDossier,DeleteDocumentFromFolder } from 'Services/DossierDocumentService';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import AddIcon from '@mui/icons-material/Add';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import LinkToViewer from 'components/private/Search/LinkToViewer';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import {AddOpenDocToSearch} from 'Services/HistoriqueService';
import Tooltip from '@mui/material/Tooltip';
import AppDataContext from "Contexts/AppDataContext";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 1,
  px: 1,
  pb: 1,
};
export default function MesDossiers() {
  const {setServerError,fetchDossiers,AllDossiers} = useContext(AppDataContext);
  const [Documents,setDocuments] = useState([]);
  const [LoadingDoc,setLoadingDoc] = useState(false);
  const [selectedDocument,setselectedDocument] = useState({
    nomDossier:''
  });

  const [action,setaction] = useState('add');
  const [openedFolder,setopenedFolder] = useState(null);
  // ShowDeletePopup
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const handleOpenShowDeletePopup = (dossier) => {
    setselectedDocument(dossier)
    setShowDeletePopup(true)
  };
  const handleCloseShowDeletePopup = () => {
    setShowDeletePopup(false);
  }

  //Show Open Popup
  const [showOpenPopup , setShowOpenPopup] = useState(false)
  const handleCloseShowOpenPopup = () => {
    setShowOpenPopup(false);
  }


  //Show Form Popup  
  const [formPopup, setformPopup] = useState(false);
  const handleformPopup = (action,dossier) => {
    if(action=='add'){
      setselectedDocument({
        nomDossier:''
      })
    }else{
      setselectedDocument(dossier)
    }
    setformPopup(true)
    setaction(action)
  }

  const handleCloseformPopup = () => setformPopup(false);
  //handle changed
  const handleFieldChanged = (field, value) => {
    let formData = {...selectedDocument};
    formData[field] = value;
    setselectedDocument(formData);
  }
 
  //Get Document By Id 
  const onOpenClicked =(dossier) => {
    setLoadingDoc(true)
    setopenedFolder(dossier);
    getDocumentsByIdDossier(dossier.id)
    .then(res => {
      if(res!= null){
        setDocuments(res);
        setLoadingDoc(false)
      }else{
        setServerError(true);
      }

    })
    .catch(err => console.log(err))
  }
 

  // delete document 
  const onDeleteClicked=()=>{
    DeleteDossier(selectedDocument.id)
    .then(res => {
        setselectedDocument({
          nomDossier:''
        });
        fetchDossiers();
        setopenedFolder(null);
      handleCloseShowDeletePopup(true);
    })
    .catch(err => console.log(err) )
  }

  //Validation de formulaire
  const onValidationClicked =() => {
    if(action == 'add'){
      if(selectedDocument.nomDossier.trim() === ''){
        return;
      }
      PostDossierDocument(selectedDocument)
      .then(res => {
        if(res.nomDossier)
        {
            fetchDossiers();
            handleCloseformPopup(true);

        }
      })
      .catch (error => console.log("error Add document",error))
    }else{
      UpdateDossier(selectedDocument)
        .then(res =>{
          if(res.nomDossier)
          {
            fetchDossiers();
            handleCloseformPopup(true);

          }
        })
        .catch (error => console.log("error update document",error))
    }


  }
  const DocOpened = (id_document,id_search)=> {
    AddOpenDocToSearch(id_search,{index_file:id_document,
      downloaded:false,
      downloadedWithOutOpen:false,
      component:'DossiersWeb'})
    .then(res => {
      if(res){
      }else{
        setServerError(true);
      }

    });
  }

  const DeleteDocumentFromDossier = (dossier,document,id_search)=>{
    DeleteDocumentFromFolder(dossier,document,id_search)
    .then(res => {
      fetchDossiers();
      let newDocs = Documents.filter(d=>d.indexDoc != document);
      setDocuments(newDocs);
    })
    .catch(err => console.log(err) )
  }
  
  useEffect(() => {
    fetchDossiers();
  },[])
  return (  
    
    <Box sx={{ flexGrow: 1,mt:1,mb:1,mr:1,ml:1 }}>
      <Grid container spacing={2}>
    
        <Grid item lg={4} xs={12} md={4}>
          <Box  sx={{textAlign:'center',width:'100%' ,justifyContent:'center',m:2 }}>
            <Button  variant="contained" startIcon={<AddIcon />} onClick={(e)=>handleformPopup('add')}>
                  Ajouter nouveau dossier 
              </Button>
          </Box>
          {AllDossiers.map((dossierDocument,i) => (
                  <Card key={"dossier_"+i} sx={{m:2,p:1}}>
                    <CardHeader   
                        avatar={                   
                          <IconButton aria-label="open" onClick={(e)=>{onOpenClicked(dossierDocument)}} size="large">
                            <FolderOpenIcon fontSize="large" color="primary" />
                         </IconButton>
                        }         
                        action={
                          <Stack direction="row" spacing={2} style={{display:"fles", justifyContent:"flex-end"}} >
                                <Tooltip title="Ouvrir le dossier">
                                <IconButton aria-label="open" onClick={(e)=>{onOpenClicked(dossierDocument)}} size="large">
                                   <ImportContactsIcon  type="submit"
                                  variant="contained" color="primary"   />
                          </IconButton>
                                </Tooltip>
                                <Tooltip title="Renommer">
                                <IconButton onClick={(e)=>handleformPopup('update',dossierDocument)} aria-label="Update" size="large">
                                   <EditIcon   type="submit" 
                                  variant="contained" color="success"  />
                                </IconButton>  
                              </Tooltip>
<Tooltip title="Supprimer">
<IconButton onClick={(e)=>handleOpenShowDeletePopup(dossierDocument)}  aria-label="delete" size="large">
                            <DeleteIcon type="submit"
                                  variant="outlined" 
                                  color="error" />
                          </IconButton>
</Tooltip>

          

          

                                 
                           </Stack>  
                        }
                        title={dossierDocument.nomDossier}
                        subheader={dossierDocument.documents == null ? 0 +" Documents" : dossierDocument.documents.length + " Documents"} 
                      />
              </Card>
        ))}
        </Grid>
        {(openedFolder != null) ? 


                <Grid item lg={8} xs={12} md={8}>
                  <Box sx={{ display:(LoadingDoc? '':'none') , width: '100%' }}>
                  <hr />
                  <Skeleton animation="wave" />
                  <br />
                  <Skeleton animation="wave" />
                  <br/>
                  <Skeleton animation="wave" />
                  <br />
                  <Skeleton animation="wave" />
                  <br />
                  <Skeleton animation="wave" />
                  <br/>
                  <Skeleton animation="wave" />
                  </Box>
                 <Card  sx={{display:(LoadingDoc? 'none':'') ,m:2,p:1}}>
                    <CardHeader   
                        avatar={
                          <FolderOpenIcon fontSize="large" color="primary" />
                        }         
                        title={                        
                           <Typography variant="h6" >
                            <b> {openedFolder.nomDossier} </b> 
                          </Typography>}
                        subheader={openedFolder.documents == null ? 0 +" Documents" : Documents.length + " Documents"} 
                      />
                <CardContent>
                {
                        (Documents.length == 0 ?                
                         <Typography id="keep-mounted-modal-title" variant="body1" >
                           auccun document trouvé
                        </Typography> :
                                      <Table size="small">
                                      <TableBody>
                                      {Documents.map((document,i) =>(
                                                <TableRow key={document.indexDoc+"_"+i}>
                                                  <TableCell>
                                                  <LinkToViewer 
                                                                                         id_search={document.id_search}
                                                                                         id={document.indexDoc}
                                                                                         id_file_store={document.id_File_Store} 
                                                                                         text = {document.referenceDoc}
                                                                                         isReference={true}
                                                                                         DocOpened={DocOpened}
                                                      />  
                            
                                                  </TableCell>
                                                  <TableCell>
                                                      {document.titreDoc}
                                                  </TableCell> 
                                                  <TableCell>
                                                  <IconButton onClick={(e)=>DeleteDocumentFromDossier(openedFolder.id,document.indexDoc,document.id_search)} aria-label="delete" size="large">
                                                      <DeleteIcon type="submit"
                                                            variant="outlined" 
                                                            color="error"  />
                                                    </IconButton>
                                                  </TableCell>                                                  
                                                </TableRow>
                                              ))} 
                                      </TableBody>
                                    </Table>
                        )

                      }

                </CardContent>
              </Card>

              </Grid>
        
        
        : <></>}

      </Grid>
  


      <Modal
        keepMounted
        open={showDeletePopup}
        onClose={handleCloseShowDeletePopup}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
            <Card sx={style} >
                <CardHeader
                    title="suppression du dossier"  
                />
                <CardContent>
                  <FormControl fullWidth>
                  <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                    Voulez-vous valider la suppression de ce dossier ?
                  </Typography>
                  </FormControl>
                </CardContent>
                <CardActions>
                     <Button onClick={handleCloseShowDeletePopup} variant="outlined">Annuler</Button>
                     <Button onClick={onDeleteClicked} variant="contained" color="error" style={{marginLeft: 'auto'}}> validation de suppression  </Button>
                </CardActions>
              </Card>
      </Modal>

      <Modal
        keepMounted
        open={formPopup}
        onClose={handleCloseformPopup}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
            <Card sx={style} >
                <CardHeader
                    title={action == 'add' ? "Ajouter un dossier" : "Modifier un dossier "}  
                />
                <CardContent>
                  <FormControl fullWidth>
                    <TextField id="outlined-basic" label="Nom dossier" variant="outlined" value={selectedDocument.nomDossier} onChange={(e)=>handleFieldChanged('nomDossier',e.target.value)} />
                  </FormControl>
                </CardContent>
                <CardActions>
                     <Button onClick={handleCloseformPopup} variant="outlined" >Annuler</Button>
                     <Button onClick={onValidationClicked} variant="contained" style={{marginLeft: 'auto'}}>  {action == 'add' ? "Ajouter" : "Modifier"}  </Button>
                </CardActions>
              </Card>
      </Modal>
    </Box>

  );
}
  