import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import UsageNumbers from 'components/private/NumberCards/UsageNumbers';
import ButttomCards from 'components/private/NumberCards/ButttomCards';
import SearchForm from 'components/private/Search/SearchForm';
export default function Dashboard(props) {
  return (
    <>
      <Box sx={{ flexGrow: 1,mt:1,mb:1 }}>
        <Grid container spacing={2}>
          <Grid item lg={12}>
              <Grid container spacing={2}>
                  <Grid item md={2} xs={12} lg={2}>
                      
                  </Grid>    
                  <Grid item  md={8} xs={12} lg={8}>
                     <UsageNumbers page={'dashboard'} />
                     <SearchForm redirect={'Search'} showAdvFilters={true} />
                     <ButttomCards />
                  </Grid>
                  <Grid item md={2} xs={12} lg={2}>
                      
                  </Grid>                
              </Grid>
          </Grid>

        </Grid>
      </Box>
    </>
  );
}


