import {SEARCH_API_URL} from "config/globals" ;
import { UserToken } from "config/Token";
// fetcch all natures
export const getAllYears =async () =>{
    try{
        const response = await fetch(SEARCH_API_URL+'/Options/GetYears',{
            method: 'GET',
            headers: {"Authorization": `Bearer ${UserToken()}`}
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    } 
};

export const getAllBooksYears =async () =>{
  try{
      const response = await fetch(SEARCH_API_URL+'/Options/GetBooksYears',{
          method: 'GET',
          headers: {"Authorization": `Bearer ${UserToken()}`}
      })
      if(response.status != 200){
          return null;
        }
      return response.json();
  }catch(err)  {
    return null;
  } 
};