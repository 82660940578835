
export const SEARCH_API_URL ='https://api-search.dbprofiscal.com/api'
export const DOC_VIEWER_URL ='https://viewer.dbprofiscal.com'   
export const SIGNALR_HUB_URL ='https://compte.dbprofiscal.com/hubs/UsersHub'
export const PAIEMENT_URL = 'https://paiement.expertiseonline.com'


//export const SEARCH_API_URL ='https://localhost:5201/api'
//export const DOC_VIEWER_URL ='https://localhost:44359'
//export const SIGNALR_HUB_URL ='https://localhost:5001/hubs/UsersHub'
//export const PAIEMENT_URL = 'https://localhost:44302'