import  {createContext,useState,useEffect} from 'react';
import { getAllNature } from "Services/natureService";
import { getAllYears,getAllBooksYears } from "Services/YearsService";
import { GetAllBooks } from "Services/BooksService";
import { LoadAutoCompleteOptions } from 'Services/AutoCompleteService';
import { getAllImpositon } from 'Services/ImpositionService';
import {UpdateHistoryRecherche} from 'Services/HistoriqueService'
import { GetDossiers} from 'Services/DossierDocumentService';
import { GetDocsWithNotes} from 'Services/NotesService';
import { LoadNotifications} from 'Services/NotificationsService';
import { GetUserFeeds,PostFeedBack,DeleteFeedBackDocument} from 'Services/FeedBackService';
import { GetDocsFavorisIds,AddDocToFavoris,DeleteDocFromFavoris} from 'Services/DocumentsFavorisService';
import {SetMigrationState} from "Services/SubscriptionManageService";
const AppDataContext = createContext();

export function AppDataProvider({children}){
    const [ServerError, setServerError] = useState(false)
    const [OriginalSearchResult, setOriginalSearchResult] = useState({})
    const [OriginalBooksSearchResult, setOriginalBooksSearchResult] = useState({})
    const [DocsWithNotes, setDocsWithNotes] = useState([])
    const [AllDossiers, setAllDossiers] = useState([])
    const [DocsFavoris, setDocsFavoris] = useState([])
    const [UserFeeds, setUserFeeds] = useState({})
    const [AllNatures, setAllNatures] = useState([])
    const [AllYears, setAllYears] = useState([])
    const [AllBooksYears, setAllBooksYears] = useState([])
    const [ImposOptions, setImposOptions] = useState([])
    const [BooksOptions, setBooksOptions] = useState([])
    const [Notifications, setNotifications] = useState([])
    const [AutoCompleteOptions, setAutoCompleteOptions] = useState([])
    const [CurrentSearchObject, setCurrentSearchObject] = useState("");
    const [CurrentSearchbooksObject, setCurrentSearchbooksObject] = useState("");
    const [NotSeenNotifications, setNotSeenNotifications] = useState(0);
    const setContainerReference = (container)=>{
      setAppContainerRef(container)
    }
    const SaveCurrentSearchBooksObject = (recherche)=>{
      setCurrentSearchbooksObject(recherche)
      UpdateHistoryRecherche(recherche);
    }
    const SaveCurrentSearchObject = (recherche)=>{
      setCurrentSearchObject(recherche)
      UpdateHistoryRecherche(recherche);
    }
    const SaveBooksSearchResult = (res)=>{
      setOriginalBooksSearchResult(res);
    }
    const SaveSearchResult = (res)=>{
        setOriginalSearchResult(res);
    }
    const fetchNature =() =>{
        getAllNature()
        .then((res) => { 
          if(res!=null){
            setAllNatures(res); 
          }else{
            setServerError(true);
          }
                      
        })
    };
    const fetchNotifications =(nbrNotif) =>{
      LoadNotifications(nbrNotif)
      .then((res) => { 
        if(res!=null){
          setNotSeenNotifications(res.filter(x=>x.seen == false).length)
          setNotifications(res); 
        }else{
          setServerError(true);
        }
                    
      })
  };
    const fetchYears =() =>{
      getAllYears()
      .then((res) => { 
        if(res!=null){
          var items = []; 
          (res).forEach(element => {
            var obj = {label : element.libelle,id: element.code}
            items.push(obj)
          });  
          setAllYears(items); 
        }else{
          setServerError(true);
        }
                    
      })
  };
  
  const fetchBooksYears =() =>{
    getAllBooksYears()
    .then((res) => { 
      if(res!=null){
        var items = []; 
        (res).forEach(element => {
          var obj = {label : element.libelle,id: element.code}
          items.push(obj)
        });  
        setAllBooksYears(items); 
      }else{
        setServerError(true);
      }
                  
    })
};
    const fetchAutoComplete = ()=>{
        LoadAutoCompleteOptions()
        .then((res) => {  
          if(res != null){
            var items = [];
            (res).forEach(element => {
              var obj = {label : element.libelle,id: element.code}
              items.push(obj)
            });  
            items = items.sort(function(a, b){
              return a.label.length - b.label.length;
            });
             setAutoCompleteOptions(items);
          }else{
            setServerError(true);
          }  
        })
      }
      const fetchImposition=()=>{
        getAllImpositon()
        .then((res) => { 
          if(res!=null){
            var items = []; 
            (res).forEach(element => {
              var obj = {label : element.libelle,id: element.code}
              items.push(obj)
            });  
             setImposOptions(items); 
          }else{
            setServerError(true);
          }
   
        })
      }
      const fetchBooks=()=>{
        GetAllBooks()
        .then((res) => { 
          if(res != null){
            var items = []; 
            (res).forEach(element => {
              var obj = {label : element.libelle,id: element.code,url:element.url}
              items.push(obj)
            });  
            setBooksOptions(items);    
          }else{
            setServerError(true);
          }

        })
      }
      const fetchDossiers=() =>{
        GetDossiers()
        .then(res => {
          if(res!=null){
            setAllDossiers(res);
          }else{
            setServerError(true);
          }       
        })
      }
  const fetchFavorisDOcs=() =>{
    GetDocsFavorisIds()
    .then(res => {
      if(res != null){
        setDocsFavoris(res);
      }else{
        setServerError(true)
      }
    })
  }
  const addDocToDocsFavoris = (id_document,id_search)=> {
    AddDocToFavoris(id_document,id_search)
    .then(res => {
      if(res != null){
        let docs = DocsFavoris;
        docs.push(id_document);
        setDocsFavoris(docs);
      }else{
        setServerError(true);
      }

    })
  }
  const RemoveDocFromDocsFavoris = (id_document)=> {
    DeleteDocFromFavoris(id_document)
    .then(res => {
      if(res != null){
        let docs = DocsFavoris.filter(f=> f != id_document);
        setDocsFavoris(docs);
      }else{
        setServerError(true);
      }

    })
  }
  const FetchUserFeed =()=>{
    GetUserFeeds()
    .then(res => {
      if(res!=null){
        setUserFeeds(res);
      }else{
        setServerError(true)
      }
     
    })
  }
  const AddUserFeed = (id_document,Feedback,id_search) =>{
    PostFeedBack({idDoc : id_document,feedBackType:Feedback,feedbackComment:" ",id_search:id_search})
    .then(res => {
      if(res!= null){
        let Feeds = UserFeeds;
        switch(Feedback){
          case "Utile":
            Feeds.utile.push(id_document);
            break;
          case "NonUtile":
            Feeds.nonUtile.push(id_document);
            break;
        }
        setUserFeeds(Feeds);
      }else{
        setServerError(true)
      }
    })
  }
  const RemoveUserFeed = (id_document,Feedback)=>{
    DeleteFeedBackDocument(id_document,Feedback)
    .then(res => {
      if(res!=null){
        let Feeds = UserFeeds;
        switch(Feedback){
          case "Utile":
            Feeds.utile = Feeds.utile.filter(f=> f != id_document); ;
            break;
          case "NonUtile":
            Feeds.nonUtile = Feeds.nonUtile.filter(f=> f != id_document); ;
            break;
        }
        setUserFeeds(Feeds);
      }else{
        setServerError(true)
      }

    })
  }
  
  const FetchDocumentsWithNotes =()=>{
    GetDocsWithNotes()
    .then(res => {
      if(res!=null){
        setDocsWithNotes(res);
      }else{
        setServerError(true);
      }
      
    })
    .catch((err) => {
      console.log(err);
      setDocsWithNotes([])}
      ) ;
  }

  
    useEffect(() => {
        SetMigrationState().then(res=>{
          console.log("current migration state : "+localStorage.getItem("MigrationState"))
        });
        fetchAutoComplete();
        fetchNature();
        fetchYears();
        fetchImposition();
        fetchBooks();
        fetchDossiers();
        fetchFavorisDOcs();
        FetchUserFeed();
        FetchDocumentsWithNotes();
        fetchBooksYears();
        fetchNotifications(10);
      },[])
    return (
        <AppDataContext.Provider value={{NotSeenNotifications,setNotSeenNotifications, Notifications,setNotifications,AllBooksYears,AllYears,ServerError, setServerError,FetchDocumentsWithNotes,DocsWithNotes,AddUserFeed,RemoveUserFeed,FetchUserFeed,UserFeeds,addDocToDocsFavoris,RemoveDocFromDocsFavoris,fetchFavorisDOcs,DocsFavoris,fetchDossiers,AllDossiers,OriginalBooksSearchResult,SaveBooksSearchResult,SaveCurrentSearchBooksObject,CurrentSearchbooksObject, setCurrentSearchbooksObject,BooksOptions,AllNatures,ImposOptions,AutoCompleteOptions,OriginalSearchResult,SaveSearchResult,CurrentSearchObject, SaveCurrentSearchObject,setCurrentSearchObject}}>
            {children}
        </AppDataContext.Provider>
    )
}

export default AppDataContext;