import * as React from 'react';
import {useEffect,useState,useContext} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ErrorIcon from '@mui/icons-material/Error';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
 import { LoginsHistory } from 'Services/statistiquesService';
import AppDataContext from "Contexts/AppDataContext";
import AppAuthContext from "Contexts/AppAuthContext";
import Moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
 export default function MesLogins(props) {
  const [ BackDays, setBackDays]=useState(5);
   const [ Logins, setLogins] = useState([]);
   const [ loading, setloading]=useState(false);
   const {setServerError} = useContext(AppDataContext);
   const {userId} = useContext(AppAuthContext);
   const handleChange = (event) => {
    setBackDays(event.target.value);
    fetchLogins(event.target.value)
  };
  const fetchLogins =(days)=>{
    setloading(true)
    LoginsHistory(props.id_user==null ? userId : props.id_user,days)
    .then(res => {
      if(res != null){
        setLogins(res);
        setloading(false);
      }else{
        setloading(false);
        setServerError(true);
      }

    })
    .catch(err => console.log(err))
  }

  useEffect(() => {
    if(userId != null && userId!='' && userId !=undefined){
      setBackDays(5);
      fetchLogins(5);
    }
  }, [userId,props.id_user]);

  return (
    <>
    
        <Box sx={{ flexGrow: 1,mt:4,mb:4 }}>
              <Box sx={{ display:( loading ? 'flex' : 'none' ), textAlign:'center',width:'100%' ,justifyContent:'center' }}>
                  <CircularProgress disableShrink />
              </Box>
              <Box sx={{ display:( loading ? 'none': 'flex')}}>

              <TableContainer component={Paper}> 
                  <Toolbar
                    sx={{
                      pl: { sm: 2 },
                      pr: { xs: 1, sm: 1 }
                    }}
                  >
                      <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                      >
                        Historique des connexions
                      </Typography>
                      <Tooltip key={"BackDays"} title={"Période d'historique"}>
                        <FormControl sx={{ m: 2, minWidth: 220 }}>
                          <InputLabel id="demo-simple-select-label">Période</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={BackDays}
                            label="Période"
                            onChange={handleChange}
                          >
                            <MenuItem key={"option5"} value={5}>5 derniers jours</MenuItem>
                            <MenuItem key={"option10"}value={10}>10 derniers jours</MenuItem>
                            <MenuItem key={"option30"} value={30}>30 derniers jours</MenuItem>
                          </Select>
                        </FormControl>
                      </Tooltip>
                  </Toolbar>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell> Date et heure </TableCell>
                          <TableCell> Réseau </TableCell>
                          <TableCell> Localisation </TableCell>
                          <TableCell sx={{width:'10%'}}> Navigateur </TableCell>
                          <TableCell> Type connexion </TableCell>
                          <TableCell> Etat </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Logins.map((login,i) => (
                          <TableRow key={"login"+i}>
                            <TableCell>
                                <b>{Moment(login.dateLogin).format('YYYY-MM-DD')+" ("+Moment(login.dateLogin).format('HH:mm')+")"}</b>
                            </TableCell>
                            <TableCell> 
                              <b>{login.ip}</b><br />
                              {login.org}
                            </TableCell>
                            <TableCell>
                                <b>{login.city} ({login.region})</b>                              
                            </TableCell>
                            <TableCell> 
                              <small>{login.browser}</small>
                            </TableCell>
                            <TableCell> 
                            { (login.id_saved_login == "" ||login.id_saved_login == null) ? "Nouvelle connexion "+(login.saveLogin==true ?"(sauvgardé)":"") : "Connexion enregistré"}                              
                            </TableCell>
                            <TableCell>
                              {login.loginSuccess ? 
                              <Chip label="Connexion accéptée" size="small" variant="filled" color="primary" icon={<ThumbUpIcon />} /> 
                              :
                              <Chip label="Connexion bloquée" size="small" variant="filled" color="error" icon={<ErrorIcon />}/>
                              }
                              {login.failingReason != "" ? <small><br/>Cause de blocage : {login.failingReason}</small> : <></>}                              

                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>  
                  </TableContainer>
              </Box>

        </Box>

    </>
  );
}

