
import React from "react";
import {useRef} from 'react';
import {  Switch } from "react-router-dom";

import MainNavBar from "components/private/Navbars/MainNavbar";
import AppNavbar from "components/private/Navbars/AppNavBar";
import HubConnection from "components/private/SignalR/HubConnection";
import { PrivateRoute } from "routes/privateRoute"
import AppPrivateRoutes from "routes/AppPrivateRoutes";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppDataProvider } from "Contexts/AppDataContext";
import { AppAuthProvider } from "Contexts/AppAuthContext";
import ReloadSearch from "components/private/Search/ReloadSearch";
import ReloadBookSearch from "components/private/Search/ReloadBookSearch";

import ManageUsers from "views/ManageUsers";
import MesLogins from "views/MesLogins";
import ManageSubscription from "views/ManageSubscription";
import ConnectionsError from "components/private/Errors/ConnectionsError"
import SearchVersion1 from "views/SearchVersion1";
export default function PrivatetLayout() {


  const AppContainer = useRef(null);

  const theme = createTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#3f51b5',
      },
      secondary: {
        main: '#e05289',
      },
    }
  });

  const getRoutes = (AppPrivateRoutes) => {
    return AppPrivateRoutes.map((privateRoute, key) => {
      if (privateRoute.layout === "/EspaceClient") {
        return (
          <PrivateRoute
            AppContainer={AppContainer}
            component={privateRoute.component}
            path={privateRoute.layout + privateRoute.path}
            render={(props) => <prop.component {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
        /*

      
      */
  return (
      
    <AppDataProvider> 
      <AppAuthProvider>
      <ThemeProvider theme={theme}>
            <Box
            id="AppContainer"
            ref={AppContainer}
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
        <MainNavBar />
          <AppNavbar  routes={AppPrivateRoutes} />

            <Container maxWidth="xl">

                <Switch>
                <PrivateRoute
                    AppContainer={AppContainer}
                    component={ManageSubscription}
                    path={"/EspaceClient/ManageSubscription"}
                    render={(props) => <prop.component {...props} />}
                    key={"ManageSubscription"}
                  />
                  <PrivateRoute
                    AppContainer={AppContainer}
                    component={ManageUsers}
                    path={"/EspaceClient/ManageUsers"}
                    render={(props) => <prop.component {...props} />}
                    key={"ManageUsers"}
                  />
                  <PrivateRoute
                    AppContainer={AppContainer}
                    component={MesLogins}
                    path={"/EspaceClient/HistoriqueConnexions"}
                    render={(props) => <prop.component {...props} />}
                    key={"HistoriqueConnexions"}
                  />
                   <PrivateRoute
                    AppContainer={AppContainer}
                    component={SearchVersion1}
                    path={"/EspaceClient/SearchV1"}
                    render={(props) => <prop.component {...props} />}
                    key={"SearchVersion1"}
                  />
                  <PrivateRoute
                    AppContainer={AppContainer}
                    component={ReloadSearch}
                    path={"/EspaceClient/ReloadSearch"}
                    render={(props) => <prop.component {...props} />}
                    key={"reloader"}
                  />
                  <PrivateRoute
                    AppContainer={AppContainer}
                    component={ReloadBookSearch}
                    path={"/EspaceClient/ReloadBookSearch"}
                    render={(props) => <prop.component {...props} />}
                    key={"reloaderBooks"}
                  />
                  {getRoutes(AppPrivateRoutes)}
                </Switch>
                <ConnectionsError />
                <HubConnection />
            </Container>

          </Box>
          </ThemeProvider>
      </AppAuthProvider>
  </AppDataProvider>
  );
}


