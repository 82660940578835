import React from "react";
import {useState} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LinkToViewer from "./LinkToViewer";
import IconButton from '@mui/material/IconButton';

import Typography from '@mui/material/Typography';

import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import StarIcon from '@mui/icons-material/Star';

import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Badge from '@mui/material/Badge';

import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import {DOC_VIEWER_URL} from "config/globals" ;



export default function SearchItem(props){
  const [isFavoris, setisFavoris] = useState(props.isFavoris);
  const [isUtile, setisUtile] = useState(props.IsUtile);
  const [isNonUtile, setisNonUtile] = useState(props.IsNonUtile);
    const DownLoad = () => {
        props.DirectDownload(props.SearchIemModel.id)
    }
    const AddToFolder = () => {
      props.MoveToFolder(props.SearchIemModel.id)
    }
    const ShowNotes = () => {
      props.ShowNotes(props.SearchIemModel.id)
    }
    
    const Updatefavoris = () => {
      props.SetFavorisState(props.SearchIemModel.id,!isFavoris);
      setisFavoris(!isFavoris);
    } 
    
    const DocumentUtile = () => {   
      props.SetFeedBack(props.SearchIemModel.id, "Utile")
      setisUtile(true);
      setisNonUtile(false);
    }   
    
    const DocumentNonUtile = () => {
      props.SetFeedBack(props.SearchIemModel.id, "NonUtile")
      setisUtile(false);
      setisNonUtile(true);
    }

    return( 
      <Card sx={{marginBottom:'10px' }} style={props.style}> 
        <CardContent  sx={{ p:'5px', '&:last-child': { pb: '5px' }}}>
          <Grid container sx={{paddingBottom:'0px'}}> 
            <Grid item  xs={12} sm={12} md={3} lg={3} >
                <Box sx={{textAlign:"center" , verticalAlign:"middle", mt:1 }} >
                    <LinkToViewer 
                              id_search={props.id_search}
                              id={props.SearchIemModel.id}
                              id_file_store={props.SearchIemModel.id_data_store} 
                              text = {props.SearchIemModel.reference}
                              isReference={true}
                              DocOpened={props.DocOpened}
                            /> 
                <Typography variant="caption" display="block" gutterBottom>
                    {props.SearchIemModel.nature.libelle}
                </Typography>
                </Box>

                <Box sx={{textAlign:"center" , verticalAlign:"middle"}} >
                <Tooltip title={props.AllowDownLoad ? "Télécharger":"Téléchargement non autorisé"}>
                    <Link href={DOC_VIEWER_URL+"/Documents/Download/"+props.SearchIemModel.id_data_store} onClick={DownLoad} target="_blank" underline="none">
                       <IconButton disabled={!props.AllowDownLoad} size="smaller" color="success">
                          <FileDownloadOutlinedIcon size="smaller"  />
                        </IconButton>
                    </Link> 
                </Tooltip>
                    <Tooltip title="Deplacer vers un dossier">
                      <IconButton onClick={AddToFolder} color="info" sx={{fontSize:20}}>
                        <CreateNewFolderIcon />                  
                      </IconButton>
                    </Tooltip>
                    {isFavoris ? 
                    
                    <Tooltip title="Retirer des favoris ">
                    <IconButton onClick={Updatefavoris} color="error" >
                      <StarIcon  sx={{fontSize:20}} />                                                           
                    </IconButton>
                  </Tooltip>
                    
                    :  
                    
                    <Tooltip title="Ajouter aux favoris">
                    <IconButton onClick={Updatefavoris} color="warning" >
                      <StarOutlineOutlinedIcon  sx={{fontSize:20}} />                                                         
                    </IconButton>
                  </Tooltip>
                    
                     }  

                    <Tooltip title="Document utile">
                      <IconButton onClick={DocumentUtile} color="primary">
                        {
                          isUtile ? <ThumbUpIcon sx={{fontSize:20}} /> : <ThumbUpOffAltIcon sx={{fontSize:20}} />
                        }
                      </IconButton>
                    </Tooltip>
                    
                    <Tooltip title="Document non utile">
                      <IconButton onClick={DocumentNonUtile} color="error">
                        {
                          isNonUtile ?<ThumbDownIcon sx={{fontSize:20}}  /> : <ThumbDownOffAltIcon sx={{fontSize:20}}  />
                        }                        
                      </IconButton>
                    </Tooltip>    
                    {
                      props.HasNotes ?                  
                        <Tooltip title="Voir les notes">
                          <IconButton onClick={ShowNotes} sx={{fontSize:20,color:'#ede437'}}>
                            <StickyNote2Icon />         
                          </IconButton>
                        </Tooltip>  : <></>
                    }   
                     
                </Box>
            </Grid>
            <Grid item  xs={12} sm={12} md={9} lg={9}>
            <LinkToViewer 
                              id_search={props.id_search}
                              id={props.SearchIemModel.id}
                              id_file_store={props.SearchIemModel.id_data_store} 
                              text = {props.SearchIemModel.titre}
                              isReference={false}
                              DocOpened={props.DocOpened}
                />

            <Typography sx={{mt:1}} variant="caption" dangerouslySetInnerHTML={{ __html: (props.SearchIemModel.bestFragment.length == 0 ? props.SearchIemModel.titre : props.SearchIemModel.bestFragment ) }} display="block" gutterBottom>
            </Typography> 
            <Typography variant="caption" display="block" gutterBottom>
                Source : {props.SearchIemModel.origine.libelle} | Consultations (<b>{props.SearchIemModel.usageInfo.opens}</b>) | Téléchargements (<b>{props.SearchIemModel.usageInfo.downloads}</b>) | Utile (<b>{props.SearchIemModel.usageInfo.likes}</b>) | Non utile (<b>{props.SearchIemModel.usageInfo.dislikes}</b>)

            </Typography>                
            </Grid>
          </Grid>
        </CardContent>      
      </Card>
        )
}