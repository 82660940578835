import {useState,useEffect,useContext}  from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import TriOptions from "./TriComponents";
import {GetCurrentConfig,SaveNewConfig} from 'Services/SearchConfigService';
import { green } from '@mui/material/colors';
import AppDataContext from "Contexts/AppDataContext";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 1,
    px: 1,
    pb: 1,
  };

export default function SearchConfig() {
    const {setServerError} = useContext(AppDataContext);
    const [open, setOpen] = useState(false);
    const [id, setid] = useState("");
    const [TriMode, setTriMode] = useState("AnneeTypeScore");
    const [DocPerPage, setDocPerPage] = useState(1000);
    const [ShowExtrait, setShowExtrait] = useState(true);
    const [PdfHighlight, setPdfHighlight] = useState(true);
    const [EnableDictionnaire, setEnableDictionnaire] = useState(true);
    const [ShowTags, setShowTags] = useState(true);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    
    const SaveConfig = ()=>{
        if (!loading) {
            setSuccess(false);
            setLoading(true);
            SaveNewConfig({
                id:id,
                sortBy : TriMode,
                resultPerPage : DocPerPage,
                bestFragment : ShowExtrait,
                pdfHighlight : PdfHighlight,
                enableDictionnaire : EnableDictionnaire,
                showTags : ShowTags
            })
            .then((res) => {   
                if(res!=null){
                    setid(res.id);
                    setTriMode(res.sortBy);
                    setDocPerPage(res.resultPerPage);
                    setShowExtrait(res.bestFragment);
                    setPdfHighlight(res.pdfHighlight);
                    setEnableDictionnaire(res.enableDictionnaire);
                    setShowTags(res.showTags);
                    localStorage.setItem('SearchConfig',JSON.stringify(res));
                    setSuccess(true);
                    setLoading(false);
                    handleClose();
                }else{
                    setServerError(true);
                    setSuccess(false);
                    setLoading(false);
                    handleClose();
                }

            })
            .catch((err) => {
                console.log(err);
                setSuccess(false);
                setLoading(false);
            }) ;
          }

    }
    const handlepdfHighlightClick = ()=>{
        setPdfHighlight(!PdfHighlight);
    }
    
    const handleShowExtraitClick = ()=>{
        setShowExtrait(!ShowExtrait);
    }
    const handleEnableDictionnaireClick = ()=>{
        setEnableDictionnaire(!EnableDictionnaire)
    }
    
    const handleShowTagsClick = ()=>{
        setShowTags(!ShowTags)
    }
    useEffect(() => {
        GetCurrentConfig()
        .then((res) => {   
            if(res != null){ 
                setid(res.id)
                setTriMode(res.sortBy);
                setDocPerPage(res.resultPerPage);
                setShowExtrait(res.bestFragment);
                setPdfHighlight(res.pdfHighlight);
                setEnableDictionnaire(res.enableDictionnaire);
                setShowTags(res.showTags);
                localStorage.setItem('SearchConfig',JSON.stringify(res));
            }else{
                setServerError(true);
            }
        });
        return ;
      },[])
    return (
      <div>
        <IconButton onClick={handleOpen} aria-label="Configuration Moteur de recherche">
        <SettingsIcon />
        </IconButton>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
            <Card sx={{ ...style, width: 600 }}>
            <CardHeader
                title="Configuration moteur de recherche"
                subheader="Adapter le moteur de recherche a vos besoin"
            />
            <CardContent>      
                <TriOptions selectedValue={TriMode} onChange={setTriMode} />
                <hr />
                <FormControl >
                    <FormLabel>Nombre des documents chargés par page</FormLabel>
                    <TextField
                        helperText="Ce nombre peut affecter la durée de recherche"
                        id="demo-helper-text-misaligned"
                        value={DocPerPage}
                        onChange={(e)=> setDocPerPage(e.target.value)}
                    />
                </FormControl>
                <hr />
                <div>
                <FormControl>
                    <FormControlLabel
                        control={
                            <Switch id="check-1" checked={ShowExtrait} onChange={handleShowExtraitClick} name="ShowExtrait" />
                        }
                        label="Affichage d'un extrait qui contient les mots clés"
                        />
                </FormControl>
                </div>
               {/*
               
                <hr />
                <div>
                <FormControl>
                    <FormControlLabel
                        control={
                            <Switch id="check-2" checked={PdfHighlight} onChange={handlepdfHighlightClick} name="PdfHighlight" />
                        }
                        label="Désactiver l'auto-coloration des mots dans les PDFs"
                        />
                </FormControl>
                </div>
               
               */} 

                <hr />
                <div>
                <FormControl>
                    <FormControlLabel
                        control={
                            <Switch checked={EnableDictionnaire} onClick={handleEnableDictionnaireClick} name="EnableDictionnaire" />
                        }
                        label="Activer le dictionnaire des synonymes, exemple : TVA => Taxe sur la valeur ajouter"
                        />
                </FormControl>
                </div>
            </CardContent>
            <CardActions disableSpacing>
                <Button  onClick={handleClose} variant="outlined">Fermer</Button>
                <Box style={{marginLeft: 'auto'}} sx={{ m: 1, position: 'relative' }}>
                    <Button
                    variant="contained"
                    disabled={loading}
                    onClick={SaveConfig}
                    >
                    Sauvgarder
                    </Button>
                    {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                        color: green[500],
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                        }}
                    />
                    )}
                </Box>
            </CardActions>
            </Card>
        </Modal>
      </div>
    );
  }

