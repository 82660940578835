import  React from 'react';
import {useEffect,useState,useContext} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Moment from 'moment';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import {GetFactureFile} from 'Services/SubscriptionManageService';
import AppDataContext from "Contexts/AppDataContext";
import { saveAs } from 'file-saver';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {PAIEMENT_URL} from "config/globals" ;
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 1,
  px: 1,
  pb: 1,
};
export default function FacturesComponent(props) {
  const {setServerError} = useContext(AppDataContext);
  const [ AbonnementsFacturables, setAbonnementsFacturables] = useState([]); 
  const [IdDownloading,setIdDownloading]= useState(""); 
  const [ModePayment, setModePayment] = useState("");
  const [showPaymentPopup, setshowPaymentPopup] = useState(false);
  const [selectedFacture ,setselectedFacture] = useState(null);
  const handleModePaymentChange = (event) => {
    setModePayment(event.target.value);
  }
  const handleClosePaymentPopup = () => {
    setshowPaymentPopup(false);
  }
  const OpenFacturePayementForm = (facture)=>{
    setselectedFacture(facture);
    setshowPaymentPopup(true);
  }
  const saveFile = (id,numG) => {
    setIdDownloading(id);
    GetFactureFile(id)
    .then(res => {
      if(res != null){
        saveAs(res, 'Facture-dbprofiscal-'+numG+'.pdf');
        setIdDownloading("");
      }else{
        setServerError(true);
        setIdDownloading("");
      }
  
    })
    .catch(err => console.log(err))
  }
  const FactureTTC= (abonnement)=>{
     let prixRem = abonnement.facture.montant - (abonnement.facture.montant *abonnement.facture.remise/100 )
     return (prixRem + (prixRem*abonnement.facture.tauxTVA/100) + abonnement.facture.montantTimbreFiscal).toFixed(3)
  }
 useEffect(() => {
    setAbonnementsFacturables(props.factures);
 }, []);
 return (
    <>
                       <TableContainer component={Paper}> 
                   <Toolbar
                     sx={{
                       pl: { sm: 2 },
                       pr: { xs: 1, sm: 1 }
                     }}
                   >
                       <Typography
                         sx={{ flex: '1 1 100%' }}
                         variant="h6"
                         id="tableTitle"
                         component="div"
                       >
                         Mes Factures
                       </Typography>
                   </Toolbar>
                     <Table sx={{ minWidth: 650 }} aria-label="simple table">
                       <TableHead>
                         <TableRow>
                           <TableCell> Numéro </TableCell>
                           <TableCell> Date </TableCell>
                           <TableCell> Abonnement </TableCell>
                           <TableCell> Montant </TableCell>
                           <TableCell> Payements & transactions </TableCell>
                           <TableCell></TableCell>
                         </TableRow>
                       </TableHead>
                       <TableBody>
                         {AbonnementsFacturables.map((abonnement,i) => (
                           <TableRow key={"facture_"+i}>
                             <TableCell> 
                              <b><i>SSII-{abonnement.facture.numeroGlobal}</i></b> <br/>
                               <small>Dbprofiscal-{abonnement.facture.numeroProduit}</small>
                             </TableCell>
                             <TableCell>
                             {Moment(abonnement.facture.dateFacture).format('DD/MM/YYYY')}
                             </TableCell>
                             <TableCell>
                                 {abonnement.type.type}<br/>
                                 <small>{"Du "+Moment(abonnement.dateDebut).format('DD/MM/YYYY') +" au "+Moment(abonnement.dateFin).format('DD/MM/YYYY')}</small>                                
                             </TableCell>
                             <TableCell>
                                H.T : <b>{abonnement.facture.montant.toFixed(3)}</b>  {"("+abonnement.facture.devise.symbole+")"} <br />
                               <small style={{display:(String(abonnement.facture.remise) == "0" ? 'none':'')}}>(Remise : {abonnement.facture.remise+"%"})<br/></small>    
                               T.T.C : <b>{FactureTTC(abonnement)}</b>  {"("+abonnement.facture.devise.symbole+")"}
                             </TableCell>
                             <TableCell>
                                <b>{abonnement.facture.factureIsPayed ? "Paiement accépté" : "Paiement en attente"}</b>
                                {abonnement.facture.transactionPayement.map((transaction,t)=>(
                                 <><br/><small>- {transaction.modePayement.libelle+ " ("+transaction.libelleBanque+")"} | {Moment(transaction.dateTransaction).format('DD/MM/YYYY')} | {transaction.montant.toFixed(3)} </small></> 
                                ))}
                             </TableCell>
                             <TableCell>
                                                            <Tooltip sx={{display: abonnement.facture.factureIsPayed  ? 'none' : ''}}  title="Paiement">                              
                                                               <IconButton onClick={(e)=>OpenFacturePayementForm(abonnement.facture)} aria-label="Valider" size="large">
                                                                   <ShoppingCartCheckoutIcon variant="outlined" color="success" /> 
                                                               </IconButton>
                                                             </Tooltip> 
                                            <Tooltip sx={{display: IdDownloading == abonnement.facture.id ? 'none' : ''}}  title="Télécharger">      
                                                    <IconButton onClick={(e)=>saveFile(abonnement.facture.id,abonnement.facture.numeroGlobal)} aria-label="Télécharger" size="large">
                                                      <FileDownloadIcon variant="outlined" color="primary" /> 
                                                    </IconButton>
                                            </Tooltip> 
                                            <CircularProgress sx={{display: IdDownloading!=abonnement.facture.id ? 'none' : ''}} disableShrink />

                             </TableCell>
                           </TableRow>
                         ))}
                       </TableBody>
                     </Table>  
                   </TableContainer>
                   <Modal
        keepMounted
        open={showPaymentPopup}
        onClose={handleClosePaymentPopup}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
      <form method="Get"  action={selectedFacture != null ? PAIEMENT_URL+"/Commandes/Paiement/"+selectedFacture.id : ""}>
        <Card sx={style} >
                <CardHeader
                    title="Validation et payement de la commande"  
                />
                <CardContent>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Mode de payement</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={ModePayment}
                      label="Mode de payement"
                      onChange={handleModePaymentChange}
                    >
                      <MenuItem value="1">Carte bancaire (ClickToPay)</MenuItem>
                      <MenuItem value="2">Virement bancaire</MenuItem>
                      <MenuItem value="3">Autres méthodes</MenuItem>
                    </Select>
                  </FormControl>
                  <Box sx={{ display:( ModePayment !="2" ? 'none': ''), backgroundColor:'#e3f3fa'}} m={3} p={3}>
                          Banque : <b>BIAT AGENCE SFAX HABIB THAMEUR (34)</b> <br /> <br />
                          Titulaire du compte bancaire : <b> ST SERV INFO-INTER EXP ON LI </b> <br /><br />
                          RIB : <b>08 804 0003410007680 60</b> <br /><br />
                          Libelle du virement : <b>Facture DBPROFISCAL N° { selectedFacture !=null ? selectedFacture.numeroGlobal+"/"+Moment(selectedFacture.dateCreation).format('YYYY') : ""}</b> <br />
                          <p>
                            PS : À fin d'accélérer la procédure de traitement du virement, <br/>
                            vous pouvez nous envoyer une copie de l'ordre du virement avec cachet de la banque Vers l'adresse mail : <b>payements@dbprofiscal.com</b>. 
                          </p>
                          <b>Votre commande sera validée et facturée automatiquement dès la réception du virement.</b>
                  </Box>
                  <Box sx={{ display:( ModePayment !="3" ? 'none': ''), backgroundColor:'#e3f3fa'}} m={3} p={3}>
                          <b>Vous pouvez nous contacter pour plus d'informations</b>
                  </Box>
                </CardContent>
                <CardActions>
                     <Button onClick={handleClosePaymentPopup} variant="outlined">Fermer</Button>
                     
                        <Button type="submit" disabled={ModePayment!="1" && selectedFacture != null} variant="contained" color="info" style={{marginLeft: 'auto'}}> Passer au payement </Button>
                </CardActions>
              </Card>
             </form>


      </Modal>
    </>
 );
}
