import React from "react";
import { AuthConsumer } from "../../providers/authProvider";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
export const Callback = () => (
    <AuthConsumer>
        {({ signinRedirectCallback }) => {
            signinRedirectCallback();
            return <Backdrop
                        open={true}
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>;
        }}
    </AuthConsumer>
);