import {SEARCH_API_URL} from "config/globals" ;
import { UserToken } from "config/Token";

// Get  feed back with docInfo
export const GetFeedBackWithDocsInfo = async () => {
    const token = UserToken();
    try{
        const response = await fetch(SEARCH_API_URL+'/FeedBack/WithDocumentInfo',{
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`},
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
        alert(err)
      return null;
    }  
}
 



// Get les feed back  
export const GetUserFeeds = async () => {
    const token = UserToken();
    try{
        const response = await fetch(SEARCH_API_URL+'/FeedBack/UserFeeds',{
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`},
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    }  
}
// Get les feed back  
export const GetFeedBack = async () => {
    const token = UserToken();
    try{
        const response = await fetch(SEARCH_API_URL+'/FeedBack',{
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`},
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    }  
}

// Post les feed back 

export const PostFeedBack = async (object) =>{
    const token = UserToken();
    try{
        const response = await fetch(SEARCH_API_URL+'/FeedBack',{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(object)
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    }  
}

//Get by Id 
export const getById =async (id) => {
    const token = UserToken();
    try{
        const response = await fetch(SEARCH_API_URL+'/FeedBack/'+id,{
            method: 'GET', 
            headers: { 
                'Content-Type':'application/json',
                "Authorization": `Bearer ${token}`
            }
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    }  
   }

   //Edit feed back  
   export const UpdateFeedBack = async(selectedFeedBack ) => {
    const token = UserToken();
    try{
        const response = await fetch(SEARCH_API_URL+'/FeedBack/'+selectedFeedBack.id,{
            method:'PUT',
            headers: { 
                'Content-Type':'application/json',
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify( selectedFeedBack )
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    }  
   }

   //Delete feed back  
   export const DeleteFeedBack  = async(id) => {
    const token = UserToken();
    try{
        const response = await fetch( SEARCH_API_URL+'/FeedBack/'+id,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                "Authorization": `Bearer ${token}`
             }
        })
        if(response.status != 200){
            return null;
          }
        return true;
    }catch(err)  {
      return null;
    }  
   }
   
   //Delete feed back  
   export const DeleteFeedBackDocument  = async(id_document,feedback) => {
    const token = UserToken();
    try{
        const response = await fetch( SEARCH_API_URL+'/FeedBack/ByDocument/'+id_document+'?typeFeed='+feedback,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                "Authorization": `Bearer ${token}`
             }
        })
        if(response.status != 200){
            return null;
          }
        return true;
    }catch(err)  {
      return null;
    }  
   }