import {useState,useEffect,useContext}  from "react";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import Avatar from '@mui/material/Avatar';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import DescriptionIcon from '@mui/icons-material/Description';
import StoreIcon from '@mui/icons-material/Store';
import { blue, red,purple,indigo,orange } from '@mui/material/colors';
import {GetCurrentSubscriptionState} from "Services/SubscriptionManageService";
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppAuthContext from "Contexts/AppAuthContext";
import { height } from "@mui/system";
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import AddIcon from '@mui/icons-material/Add';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: {xs :  '60%',md:'auto' },
  overflow: 'scroll',
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 1,
  px: 1,
  pb: 1,
};

export default function UsageNumbers(props) {
  const {setIdAbonnement,setClientName,setAllowDownLoading,userIsAccountOwner} = useContext(AppAuthContext);
  const [UsageNumbers,setUsageNumbers] = useState(null);
  const [UsagePercentage,setUsagePercentage]= useState(0);

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{alignItems: 'center' }}>
        <Box sx={{textAlign:'center',width:'100%' ,justifyContent:'center'}}>
          <Typography variant="subtitle2">{
            props.message
          }</Typography>
        </Box>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress color={UsagePercentage<70 ? "primary":"error"} sx={{height:'7px'}} variant="determinate" {...props} />
        </Box>
      </Box>
    );
  }
  
  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };

  
  useEffect(() => {
    GetCurrentSubscriptionState().then(res => {
      if(res!=null){
        setUsageNumbers(res);
        if(res.nbrDocConsultLimitPerAbonnement == 0){
          setIdAbonnement(res.id_abonnement);
          setClientName(res.nomClient);
          setAllowDownLoading(res.allowDownLoading);
        }else{
          if(res.nbrDocConsultClient>=res.nbrDocConsultLimitPerAbonnement){
            setIdAbonnement("");
          }else{
            setIdAbonnement(res.id_abonnement);
          }
          setClientName(res.nomClient);
          setAllowDownLoading(res.allowDownLoading);
  
          setUsagePercentage(Math.round((Number(res.nbrDocConsultClient)/Number(res.nbrDocConsultLimitPerAbonnement))*100))
        }
      }else{
        setServerError(true)
      }  
    })
    .catch(err => console.log(err))
    return()=>{
    }
  },[])
return (
    <>
     {
      UsageNumbers == null ? <></> :
      <>
      <Grid sx={{display:(UsageNumbers.id_abonnement != "" ? "none" : "" )}} container>
      <Grid item lg={12}>
                <Card sx={{ bgcolor: red[100] , margin: 1 }}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: red[600] }} aria-label="Exipiration">
                        <EventBusyIcon />
                      </Avatar>
                    }
                    title="Abonnement expiré"
                    subheader="Votre abonnement est expiré. Vous pouvez procéder au renouvellement via la rebrique > Commandes & Abonnements"
                  />
                </Card>
              </Grid>
     </Grid>

            <Grid sx={{display:(UsageNumbers.id_abonnement == "" ? "none":"" )}} container>
            <Grid item sx={{display:(UsageNumbers.nbrDocConsultLimitPerAbonnement == 0 ? "none":"" ),mb:2}} md={12} xs={12} lg={12}>
                <Box sx={{ width: '100%' }}>
                  <LinearProgressWithLabel 
                  message={UsageNumbers == null ? "..." :"Quota d'utilisation : "+UsageNumbers.nbrDocConsultClient+" documents consultés sur "+UsageNumbers.nbrDocConsultLimitPerAbonnement + ", soit "+UsagePercentage+"%."}                  
                  value={UsagePercentage} />
                </Box>
            </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Card sx={{ bgcolor: ( UsageNumbers == null ? red[50] : (UsagePercentage>=70 || UsageNumbers.daysToExpire) <= 60 ? red[200] : red[50] ) , margin: 1 }}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="Exipiration">
                        <EventBusyIcon />
                      </Avatar>
                    }
                    title={UsageNumbers == null ? "..." :"Expiration dans "+ UsageNumbers.daysToExpire +" jours."}
                    subheader={UsageNumbers == null ? "..." :"Date expiration : "+ UsageNumbers.dateFinStr}
                  />
                </Card>
              </Grid>

              <Grid item xs={12} md={6}  lg={6}>
                <Card  sx={{ margin: 1 }}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: purple[200] }} aria-label="Recherches">
                        <FindInPageIcon />
                      </Avatar>
                    }
                    title={UsageNumbers == null ? "..." : UsageNumbers.nbrRechClient +" Recherches ("+UsageNumbers.nbrRechClientPerDay+" aujourd'hui)"}
                    subheader={UsageNumbers == null ? "..." : UsageNumbers.nbrDocConsultClient +" Documents consultés ("+UsageNumbers.nbrDocConsultClientPerDay+"/" +UsageNumbers.nbrDocConsultLimitPerDay+" aujourd'hui)"}
                  />
                </Card>
              </Grid>
            </Grid>
            </>
     }
    </>
)
}