import React from 'react';
import {useEffect,useState,useContext} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LinkToViewer from "components/private/Search/LinkToViewer";
 import { GetPrettyDocumentsWithNotes,DeleteNoteById } from 'Services/NotesService';
import { IconButton } from '@mui/material';
import AppDataContext from "Contexts/AppDataContext";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {AddOpenDocToSearch} from 'Services/HistoriqueService';
 export default function Notes() {
   const [ DocsNotes, setDocsNotes] = useState([]);
   const [ loading, setloading]=useState(false);
   const {setServerError,FetchDocumentsWithNotes} = useContext(AppDataContext);

   const DeleteNote =(id_document,id_note)=>{
    DeleteNoteById(id_note)
    .then(res => {
      if(res!=null){
        fetchNotes()
      }else{
        setServerError(true)
      }    
    })
  }

  const fetchNotes =()=>{
    setloading(true)
    FetchDocumentsWithNotes();
    GetPrettyDocumentsWithNotes()
    .then(res => {
      if(res!=null){
        setDocsNotes(res);
        setloading(false);
      }else{
        setServerError(true);
        setloading(false);
      }
    })
  }

  const DocOpened = (id_document,id_search)=> {
    AddOpenDocToSearch(id_search,{index_file:id_document,
      downloaded:false,
      downloadedWithOutOpen:false,
      component:'notesWeb'})
    .then(res => {
      if(res){
      }else{
        setServerError(true);
      }

    });
  }

  useEffect(() => {
    fetchNotes()
  }, []);

  return (
    <>
    
        <Box sx={{ flexGrow: 1,mt:4,mb:4 }}>
              <Box sx={{ display:( loading ? 'flex' : 'none' ), textAlign:'center',width:'100%' ,justifyContent:'center' }}>
                  <CircularProgress disableShrink />
              </Box>
              <Box sx={{ display:( loading ? 'none': 'flex')}}>

              <TableContainer component={Paper}> 
                  <Toolbar
                    sx={{
                      pl: { sm: 2 },
                      pr: { xs: 1, sm: 1 }
                    }}
                  >
                      <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                      >
                        Liste des notes
                      </Typography>
                  </Toolbar>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{width:'50%'}} > Documents </TableCell>
                          <TableCell > Notes </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {DocsNotes.map((doc,i) => (
                          <TableRow key={"note_"+i}>
                            <TableCell>
                              <b>{doc.referenceDoc}</b>
                                <br />
                              <p>{doc.titreDoc}</p>
                            </TableCell>
                            <TableCell>
                                {doc.notes.map((elem,key) => {
                                      return (
                                        <Box sx={{ m:2 }}>
                                          <Alert key={key} severity="warning"
                                          action={
                                            <IconButton color="error" onClick={(e)=>DeleteNote(doc.id_document,elem.id)}>
                                              <DeleteForeverIcon />
                                            </IconButton>
                                          }
                                          
                                          icon={false}>  
                                          <AlertTitle>
                                             <b>{elem.titre} </b>
                                            </AlertTitle>
                                          {elem.description} <br /> <small>{elem.dateNoteStr}</small> <br />
                                          <LinkToViewer 
                                          id_search={elem.id_search}
                                          id={doc.id_document}
                                          id_file_store={doc.id_File_Store} 
                                          text = {"Afficher le document"}
                                          isReference={true}
                                          DocOpened={DocOpened}
                                        /> 
                                          
                                        </Alert>
                                        </Box>
                                      );
                                    })}
                              </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>  
                  </TableContainer>
              </Box>

        </Box>

    </>
  );
}

