/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Search from "views/Search.js";
import SearchERY from "views/SearchERY.js";
import MesDossiers from "views/MesDossiers";

import Historique from "views/Historique";
import DocumentFavoris from "views/DocumentFavoris";
import Feedback from "views/MesFeedBack";
import Notes from "views/MesNotes";
import Statistiques from "views/Statistiques";
import SearchVersion1 from "views/SearchVersion1";

 const AppPrivateRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "DashboardIcon",
    component: Dashboard,
    layout: "/EspaceClient",
  },
  {
    path: "/Search",
    name: "Documentation DBPROFISCAL",
    icon: "ManageSearchIcon",
    component: Search,
    layout: "/EspaceClient",
  }, 
  
  {
    path: "/SearchERY",
    name: "Livres Editions Raouf YAICH",
    icon: "MenuBookIcon",
    component: SearchERY,
    layout: "/EspaceClient",
  },
  {
    path: "/DocumentsFavoris",
    name: "Documents favoris",
    icon: "FolderSpecialIcon",
    component: DocumentFavoris,
    layout: "/EspaceClient",
  },
  {
    path: "/MesDossiers",
    name: "Mes Dossiers",
    icon: "FolderCopyIcon",
    component: MesDossiers,
    layout: "/EspaceClient",
  },
  {
    path: "/MesFeedBack",
    name: "Mes FeedBacks",
    icon: "RuleFolderIcon",
    component: Feedback,
    layout: "/EspaceClient",
  }, 
  {
    path: "/MesNotes",
    name: "Mes Notes",
    icon: "StickyNote2Icon",
    component: Notes,
    layout: "/EspaceClient",
  },
  {
    path: "/Historique",
    name: "Historique",
    icon: "HistoryIcon",
    component: Historique,
    layout: "/EspaceClient",
  },
  {
    path: "/Statistiques",
    name: "Statistiques",
    icon: "BarChartIcon",
    component: Statistiques,
    layout: "/EspaceClient",
  }
];

export default AppPrivateRoutes;
