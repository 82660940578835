import  React from 'react';
import {useState,useEffect,useContext}  from "react";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import AppDataContext from "Contexts/AppDataContext";
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {useHistory} from 'react-router-dom';
export default function Notifications(props){
    const {Notifications,setNotifications,setNotSeenNotifications} = useContext(AppDataContext);
    const history = useHistory();
    useEffect(() => {
    },[Notifications])

    const openNotifDocs =(idnotif)=>{
      props.handleCloseNavMenu();
      localStorage.setItem("showDocsFromNotif",idnotif);
      const tempNotifs = Notifications.map(obj => {
        if(obj.id==idnotif){
          return {...obj, seen: true};
        }
        return obj;
      });
      setNotSeenNotifications(tempNotifs.filter(x=>x.seen == false).length)
      setNotifications(tempNotifs);
      history.push('ReloadSearch'); 
    }

    return( 
      <Card elevation={5} sx={{m:0,p:0}}>
<CardHeader
 avatar={
   <IconButton aria-label="Liste des notifications">
     <NotificationsActiveIcon />
   </IconButton>
 }
 title={"Nouveautés"}
 subheader="Notifications et actualités fiscales"
/>    
      <CardContent sx={{mt:0,pt:0}}>
      <Stack sx={{height:'500px',overflowX:'none',overflowY:'scroll'}} spacing={2}>
        {
        Notifications.length == 0 ? <span>Chargement en cours ...</span> :
          Notifications.map((elem,key) =>{
            switch(elem.type){
              case "InsertionDocuments":            
               return (
                  <Alert 
                  action={
                    <>
                    <IconButton onClick={(e)=>openNotifDocs(elem.id)} aria-label="afficher les document">
                      <FindInPageIcon />
                    </IconButton> 
                    </>

                  }
                  elevation={elem.seen ? 0 : 3} key={"notif_"+key} icon={<NotificationAddIcon  fontSize={elem.seen ? "" : "large"} />} color={elem.seen ? "success" : "primary"}>
                    <b>{elem.titre}</b> <small>{elem.dateCreationStr}</small><br />
                    {elem.description}                   
                  </Alert>
                )
              case "MiseAjourDocuments":
                return (
                    <Alert 
                    action={
                      <>
                      <IconButton onClick={(e)=>openNotifDocs(elem.id)} aria-label="afficher les document">
                        <FindInPageIcon />
                      </IconButton> 
                      </>
  
                    }
                    elevation={elem.seen ? 0 : 3} icon={<NotificationAddIcon  fontSize={elem.seen ? "" : "large"} />}
                    key={"notif_"+key} color={elem.seen ? "info" : "primary"}>
                      <AlertTitle>{elem.titre}</AlertTitle>
                      {elem.description} — <strong>check it out!</strong>
                    </Alert>
          )
              case "RappelFiscal":
                return (
                            <Alert elevation={elem.seen ? 0 : 3} icon={<NotificationAddIcon  fontSize={elem.seen ? "" : "large"} />} key={"notif_"+key} color="error">
                  <AlertTitle>Info</AlertTitle>
                  This is an info alert — <strong>check it out!</strong>
                </Alert>  
          )
              case "ActualitesFiscal":
                return (         
                  
                  <Alert  elevation={elem.seen ? 0 : 3} icon={<NotificationAddIcon  fontSize={elem.seen ? "" : "large"} />} key={"notif_"+key} color="warning">
                    <AlertTitle>Warning</AlertTitle>
                    This is a warning alert — <strong>check it out!</strong>
                  </Alert>
          )
              default:
                return(<span>Unkown type</span>)
            }
          })
        }
    </Stack>
    </CardContent>
  </Card>
        )
}
