import {SEARCH_API_URL} from "config/globals" ;
import { UserToken } from "config/Token";
// fetcch all natures
export const GetAllBooks =async () =>{
    try{
        const response = await fetch(SEARCH_API_URL+'/Options/GetBooks',{
            method: 'GET',
            headers: {"Authorization": `Bearer ${UserToken()}`}
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    }  
};