import React from "react";
import {useState,useEffect,useContext} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import UsageNumbers from 'components/private/NumberCards/UsageNumbers';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import {GetGenStats,GetUAllUsersNumbers} from "Services/statistiquesService";
import AppDataContext from "Contexts/AppDataContext";
import AppAuthContext from "Contexts/AppAuthContext";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import { IconButton } from '@mui/material';
import Modal from '@mui/material/Modal';
import CardActions from '@mui/material/CardActions';
import { ChangeAccountState } from 'Services/SubscriptionUsersService';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import UserStatsComponent from "components/private/Statistiques/UserStatsComponent";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 1,
  px: 1,
  pb: 1,
};

export default function Statistiques() {

  const {userIsAccountOwner,userId} = useContext(AppAuthContext);
  const {setServerError,AllNatures} = useContext(AppDataContext);

  const [labels, setlabels] = useState([]);
  const [nbrAcces, setnbrAcces] = useState([]);
  const [nbrSearch, setnbrSearch] = useState([]);
  const [nbrOpen, setnbrOpen] = useState([]);
  const [nbrDownload, setnbrDownload] = useState([]);
  const [Natureslabels, setNatureslabels] = useState([]);
  const [NbrOpenPerNature, setNbrOpenPerNature] = useState([]);
  const [UsersData, setUsersData] = useState([]);
  const [formPopup, setformPopup] = useState(false);
  const [selectedUser, setselectedUser] = useState(null);
  const handleCloseformPopup = () => setformPopup(false); 


  const [CurrentView, setCurrentView] = useState('');

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Statistiques d\'utilisation',
      },
    },
  };

 const data = {
  labels,
  datasets: [
    {
      label: 'Nombre d\' accèes',
      data: nbrAcces,
      borderColor: 'rgb(94, 166, 136)',
      backgroundColor: 'rgba(94, 166, 136, 0.5)',
    },
    {
      label: 'Nombre de recherches',
      data: nbrSearch,
      borderColor: 'rgb(54, 162, 235)',
      backgroundColor: 'rgba(54, 162, 235, 0.5)',
    },
    {
      label: 'Nombre des documents consultés',
      data: nbrOpen,
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Nombre des documents téléchargés',
      data: nbrDownload,
      borderColor: 'rgb(255, 206, 86)',
      backgroundColor: 'rgba(255, 206, 86, 0.5)',
    }
  ],
};
const dataPie = {
  labels: Natureslabels,
  datasets: [
    {
      label: '# of Votes',
      data: NbrOpenPerNature,
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};




const loadStats = (Personalstats,iduser)=>{
  GetGenStats(Personalstats ? iduser : null).then(res => {
    if(res!=null){
      let LablesList = [];
      let nbrAccessPerDay = [];
      let nbrSearchPerDay = [];
      let nbrOpenPerDay = [];
      let nbrDownPerDay = [];
      res.daysLabels.forEach(dayLabel => {
        LablesList.push(dayLabel);

        let nbAcc = res.nbrAccesPerDay.filter(f=>f.label==dayLabel);
        if(nbAcc.length>0){
          nbrAccessPerDay.push(nbAcc[0].value)
        }else{
          nbrAccessPerDay.push(0)
        }

        let nbRech = res.nbrRechPerDay.filter(f=>f.label==dayLabel);
        if(nbRech.length>0){
          nbrSearchPerDay.push(nbRech[0].value)
        }else{
          nbrSearchPerDay.push(0)
        }

        let nbOpen = res.nbrDocsOpenedPerDay.filter(f=>f.label==dayLabel);
        if(nbOpen.length>0){
          nbrOpenPerDay.push(nbOpen[0].value)
        }else{
          nbrOpenPerDay.push(0)
        }

        let nbDown = res.nbrDocsDownloadedPerDay.filter(f=>f.label==dayLabel);
        if(nbDown.length>0){
          nbrDownPerDay.push(nbDown[0].value)
        }else{
          nbrDownPerDay.push(0)
        }

      });
      console.log(LablesList)
      console.log(nbrAccessPerDay)
      console.log(nbrSearchPerDay)
      console.log(nbrOpenPerDay)
      console.log(nbrDownPerDay)

      setlabels(LablesList);
      setnbrAcces(nbrAccessPerDay);
      setnbrSearch(nbrSearchPerDay);
      setnbrOpen(nbrOpenPerDay);
      setnbrDownload(nbrDownPerDay);
  
      let natures = []
      let naturesValues = []
      res.nbrDocsOpenedPerType.forEach(element=>{
        let nat = AllNatures.filter(f=>f.code==element.label);
        if(nat.length>0){
          natures.push(nat[0].libelle)
        }else{
          natures.push(element.label);
        }
  
        naturesValues.push(element.value);
      })
      setNatureslabels(natures);
      setNbrOpenPerNature(naturesValues);
    }else{
      setServerError(true)
    }

  })
  .catch(err => console.log(err))

}
const handleformPopup = (user)=>{
  setselectedUser(user);
  setformPopup(true);
}

const AccountStateChanged= (id,checked)=>{   
  ChangeAccountState(id,checked).then((res)=>{
    const tempUSers = UsersData.map(obj => {
      if(obj.id_user==id){
        return {...obj, disabled: checked};
      }
      return obj;
    });
    setUsersData(tempUSers);
  });    
};

const ChangeView = (view,user)=>{
  switch(view){
    case "UsageTab":
        setCurrentView("UsageTab");
        if(userIsAccountOwner){
          GetUAllUsersNumbers().then(res => {
            if(res!=null){
              setUsersData(res);
            }else{
              setServerError(true)
            } 
          })
          .catch(err => console.log(err))
        }
      break;
    case "AbonStats":
        setCurrentView("AbonStats");
        loadStats(false);
      break;
    case "CurrentUserStats":
      setCurrentView("CurrentUserStats");
      setselectedUser(user);
      loadStats(true,user == null ? userId : user.id_user);
    break;
  }
}

useEffect(() => {
  if(userIsAccountOwner){
    ChangeView("UsageTab");
  }else{
    ChangeView("CurrentUserStats");
  }

  return()=>{
  }
},[userIsAccountOwner])

  return (
    <>
      <Box sx={{ flexGrow: 1,mt:1,mb:1 }}>
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <UsageNumbers page="statistiques" />
          </Grid>
          <Grid item lg={12}>
            <Box sx={{textAlign:'center',width:'100%' ,justifyContent:'center'}}>
              {userIsAccountOwner ?
              <Button  onClick={(e)=>ChangeView("UsageTab")}  sx={{mr:1}} variant={ CurrentView=="UsageTab" ? "contained" : "outlined" } >Tableau d'utilisation</Button>:<></>}
              <Button  onClick={(e)=>ChangeView("AbonStats")}  sx={{mr:1}} variant={CurrentView=="AbonStats" ? "contained" : "outlined" } >Statistiques Abonnement</Button>
              <Button onClick={(e)=>ChangeView("CurrentUserStats",null)} sx={{ml:1}} variant={CurrentView=="CurrentUserStats" ? "contained" : "outlined" }>Statistiques Utilisateur</Button>
            </Box>
          </Grid>
              {userIsAccountOwner && CurrentView=="UsageTab" ?         
                        <Grid item lg={12}>
                        <Grid container spacing={2}>         
                   <Grid item xs={12}>
                    <Card>
                      <CardHeader  title="Utilisation par compte"/>
                      <CardContent> 
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Utilisateur</TableCell>
                                <TableCell>Compte actif</TableCell>
                                <TableCell>Recherches</TableCell>
                                <TableCell>Documents consultés</TableCell>
                                <TableCell>Accès</TableCell>
                                <TableCell>                                                       
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {UsersData.map((row) => (
                                <TableRow
                                  key={"user_data_"+row.id_user}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.fullName}<br />
                                    <small>{row.email}</small>
                                  </TableCell>
                                  <TableCell>
                                      {
                                        !row.isClientMananger ? <Switch key={"switch_"+row.id_user} checked={!row.disabled} onChange={(e)=>AccountStateChanged(row.id_user,!e.target.checked)} size="small" />  : <></>
                                      }    
                                  </TableCell>
                                  <TableCell>{row.nbrRech}</TableCell>
                                  <TableCell>{row.nbrDocConsult}</TableCell>
                                  <TableCell>{row.nbrAcces}</TableCell>
                                  <TableCell>                        
                                    <IconButton onClick={(e)=>ChangeView("CurrentUserStats",row)}  aria-label="Modifier" size="large">
                                        <QueryStatsIcon variant="outlined" color="success" /> 
                                    </IconButton>
                                </TableCell>
                                </TableRow>
                              ))}
                                            <TableRow>
                  <TableCell>
                    <b>Total Abonnement</b>
                  </TableCell>
                  <TableCell>
                  <b>Tous les utilisateurs</b>
                  </TableCell>
                  <TableCell><b> {UsersData.reduce((total,currentItem) =>  total = total + currentItem.nbrRech , 0 )} Recherches </b></TableCell>
                  <TableCell><b> {UsersData.reduce((total,currentItem) =>  total = total + currentItem.nbrDocConsult , 0 )} Documents consultés </b></TableCell>
                  <TableCell><b>{UsersData.reduce((total,currentItem) =>  total = total + currentItem.nbrAcces , 0 )} accèes </b></TableCell>
                  <TableCell>
                  </TableCell>
              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </Card>

                  </Grid> 
                  </Grid>
          </Grid>
                  : <></>}

            <Grid sx={{display:(CurrentView=="UsageTab" ? 'none' : '')}} item lg={12}>
              <h2>Statistique {CurrentView=="AbonStats" ? <b>Abonnement</b> : selectedUser==null ? <b>Compte actuel</b> : <b>{selectedUser.fullName }</b>  } </h2>
              <Grid container spacing={2}>
                  <Grid item lg={8}>
                    <Card>
                      <CardHeader  title="Statistiques d'utilisation relatives aux 30 derniers jours."/>
                      <CardContent> 
                        <Line id="mainChart" options={options} data={data} />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                  <Card>
                      <CardHeader  title="Documents consultés par type"/>
                      <CardContent> 
                        <Pie data={dataPie} />
                      </CardContent>
                    </Card>

                  </Grid>
              </Grid>
          </Grid>
        </Grid>
      </Box>
            </>
  );
}