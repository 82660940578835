import  React from 'react';
import {useEffect,useState} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { EditInfo,EditAutoRenew } from 'Services/SubscriptionManageService';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
const AlertFeed = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
export default function InformationsClientComponent(props) {
  const [ Informations, setInformations] = useState([]); 
  const [openFeedAlert, setopenFeedAlert] = useState(false);
  const [ loading, setloading]=useState(false);
  const UpdateInformation =()=>{
    setloading(true)
    EditInfo(Informations)
    .then(res => {
      if(res != null){
        setInformations(res);
        setopenFeedAlert(true);
        setloading(false);
      }else{
        setloading(false);
        setServerError(true);
      }
  
    })
    .catch(err => console.log(err))
  }
  const ChangeAutoRenew =(autoRenew)=>{
    setloading(true)
    EditAutoRenew(autoRenew)
    .then(res => {
      if(res != null){
        setInformations(res);
        setopenFeedAlert(true);
        setloading(false);
      }else{
        setloading(false);
        setServerError(true);
      }
  
    })
    .catch(err => console.log(err))
  }
 useEffect(() => {
    setInformations(props.informations);
 }, []);
 return (
    <>
    {
        Informations == null ? <></>:
        <Grid container spacing={2}>
                <Grid item lg={7}>
                        <Card variant="outlined">
                            <CardHeader
                                avatar={
                                  <IconButton aria-label="Informations du compte">
                                     <AccountCircleIcon />
                                  </IconButton>
                                }
                                action={
                                    loading ?
                                    <IconButton aria-label="Chargement">
                                        <CircularProgress disableShrink />
                                    </IconButton>
                                    : <></>
                                }
                                title="Informations générales"
                                subheader="informations de contact et de facturation"
                            />
                            <CardContent>
                                <Box mb={1} p={1}> 
                                    <TextField
                                        label="Nom du client"
                                        disabled={true}
                                        sx={{ width: '100%' }}
                                        value={Informations.nom}
                                        onChange={(e)=>setInformations({...Informations,nom:e.target.value})}
                                        />
                                </Box>
                                <Box mb={1} p={1}> 
                                    <TextField
                                        label="Email"
                                        disabled={true}
                                        sx={{ width: '50%',marginRight:'10px' }}
                                        value={Informations.email}
                                        onChange={(e)=>setInformations({...Informations,email:e.target.value})}
                                        />
                                    <TextField
                                        label="Nom du Responsable"
                                        disabled={true}
                                        sx={{ width: '45%' }}
                                        value={Informations.responsable}
                                        onChange={(e)=>setInformations({...Informations,responsable:e.target.value})}
                                        />
                                </Box>
                                <Box mb={1} p={1}> 
                                    <TextField
                                        label="Matricule fiscale"
                                        disabled={true}
                                        sx={{ width: '50%',marginRight:'10px' }}
                                        value={Informations.matriculeFiscal}
                                        onChange={(e)=>setInformations({...Informations,matriculeFiscal:e.target.value})}
                                        />
                                    <TextField
                                        label="Identifiant unique RNE"
                                        disabled={true}
                                        sx={{ width: '45%' }}
                                        value={Informations.rne}
                                        onChange={(e)=>setInformations({...Informations,rne:e.target.value})}
                                        />
                                </Box>
                                <Box mb={1} p={1}> 
                                    <TextField
                                        label="Adresse"
                                        disabled={true}
                                        sx={{ width: '100%' }}
                                        value={Informations.adresse}
                                        onChange={(e)=>setInformations({...Informations,adresse:e.target.value})}
                                        />
                                </Box>
                                <Box mb={1} p={1}> 
                                    <TextField
                                        label="Pays"
                                        disabled={true}
                                        sx={{ width: '30%',marginRight:'10px' }}
                                        value={Informations.pays}
                                        onChange={(e)=>setInformations({...Informations,pays:e.target.value})}
                                        />
                                    <TextField
                                        label="Ville"
                                        disabled={true}
                                        sx={{ width: '30%',marginRight:'10px' }}
                                        value={Informations.ville}
                                        onChange={(e)=>setInformations({...Informations,ville:e.target.value})}
                                        />
                                    <TextField
                                        label="Code postal"
                                        disabled={true}
                                        sx={{ width: '30%' }}
                                        value={Informations.codePostal}
                                        onChange={(e)=>setInformations({...Informations,codePostal:e.target.value})}
                                        />
                                </Box>
                                <Box mb={1} p={1}> 
                                    <TextField
                                        label="Téléphone FIXE"
                                        disabled={true}
                                        sx={{ width: '30%',marginRight:'10px' }}
                                        value={Informations.telFix}
                                        onChange={(e)=>setInformations({...Informations,telFix:e.target.value})}
                                        />
                                    <TextField
                                        label="Téléphone Mobile"
                                        disabled={true}
                                        sx={{ width: '30%',marginRight:'10px' }}
                                        value={Informations.telMobile}
                                        onChange={(e)=>setInformations({...Informations,telMobile:e.target.value})}
                                        />
                                    <TextField
                                        disabled={true}
                                        label="FAX"
                                        sx={{ width: '30%' }}
                                        value={Informations.fax}
                                        onChange={(e)=>setInformations({...Informations,fax:e.target.value})}
                                        />
                                </Box>
                            </CardContent>
                            {/*
                                                        <CardActions>
                                <Button disabled={loading} onClick={UpdateInformation} style={{marginLeft: 'auto'}} variant="contained">Modifier</Button>
                            </CardActions>
                            
                            */}

                        </Card>
                </Grid>
                <Grid item xs={5}>
                <Card variant="outlined">
                            <CardHeader
                                avatar={
                                  <IconButton aria-label="Informations du compte">
                                     <AutorenewIcon />
                                  </IconButton>
                                }
                                action={
                                    loading ?
                                    <IconButton aria-label="Chargement">
                                        <CircularProgress disableShrink />
                                    </IconButton>
                                    : <></>
                                }
                                title="Configuration de renouvellement"
                                subheader="Configuration renouvellement automatique"
                            />
                            <CardContent>
                                <Box mb={1} p={1}> 
                                    <FormControlLabel
                                    control={
                                        <Switch checked={Informations.renouvellementAutomatique} onChange={(e)=>ChangeAutoRenew(!Informations.renouvellementAutomatique)} name="Renouvellement automatique" />
                                    }
                                    label="Activer le renouvellement automatique avant 30 jours de l'expiration."
                                    />
                                    <FormHelperText>Cette méthode génére un bon de commande automatique avant 30 jours de l'expiration.</FormHelperText>
                                </Box>
                            </CardContent>
                        </Card>
                </Grid>
        </Grid>
    }
      <Snackbar open={openFeedAlert} autoHideDuration={6000}>
        <AlertFeed severity="success" sx={{ width: '100%' }}>
          Modification effectué,
        </AlertFeed>
      </Snackbar>
    </>
 );
}
