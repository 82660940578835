import React from "react";
import {useEffect,useState,useContext} from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import {GetGenStats} from "Services/statistiquesService";
import AppDataContext from "Contexts/AppDataContext";
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  
  import { Line } from 'react-chartjs-2';
  import { Pie } from 'react-chartjs-2';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );
export default function UserStatsComponent(props){
    const {setServerError,AllNatures} = useContext(AppDataContext);
    const [idUser, setidUser] = useState(props.id_user);
    const [NatureslabelsUser, setNatureslabelsUser] = useState([]);
    const [NbrOpenPerNatureUser, setNbrOpenPerNatureUser] = useState([]);
    const [dataUser, setdataUser] = useState(null);


    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Statistiques d\'utilisation',
          },
        },
      };
    
      const dataPieUser = {
        labels: NatureslabelsUser,
        datasets: [
          {
            label: '# of Votes',
            data: NbrOpenPerNatureUser,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
      };
      const loadStatsUser = (id_user)=>{
        GetGenStats(id_user).then(res => {
          if(res!=null){
            let LablesList = [];
            let nbrSearchPerDay = [];
            let nbrOpenPerDay = [];
            let nbrDownPerDay = [];
            res.nbrRechPerDay.forEach(element => {
              LablesList.push(element.label);
              nbrSearchPerDay.push(element.value);
              let nbOpen = res.nbrDocsOpenedPerDay.filter(f=>f.label==element.label);
              if(nbOpen.length>0){
                nbrOpenPerDay.push(nbOpen[0].value)
              }else{
                nbrOpenPerDay.push(0)
              }
              let nbDown = res.nbrDocsDownloadedPerDay.filter(f=>f.label==element.label);
              if(nbDown.length>0){
                nbrDownPerDay.push(nbDown[0].value)
              }else{
                nbrDownPerDay.push(0)
              }
            });
            setdataUser({
                LablesList,
                datasets: [
                {
                    label: 'Nombre de recherches',
                    data: nbrSearchPerDay,
                    borderColor: 'rgb(54, 162, 235)',
                    backgroundColor: 'rgba(54, 162, 235, 0.5)',
                },
                {
                    label: 'Nombre des documents consultés',
                    data: nbrOpenPerDay,
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                    label: 'Nombre des documents téléchargés',
                    data: nbrDownPerDay,
                    borderColor: 'rgb(255, 206, 86)',
                    backgroundColor: 'rgba(255, 206, 86, 0.5)',
                }
                ]
              });

            let natures = []
            let naturesValues = []
            res.nbrDocsOpenedPerType.forEach(element=>{
              let nat = AllNatures.filter(f=>f.code==element.label);
              if(nat.length>0){
                natures.push(nat[0].libelle)
              }else{
                natures.push(element.label);
              }
        
              naturesValues.push(element.value);
            })
            setNatureslabelsUser(natures);
            setNbrOpenPerNatureUser(naturesValues);

          }else{
            setServerError(true)
          }
      
        })
        .catch(err => console.log(err))
      
      }
      useEffect(() => {
        if(idUser != null){
            alert(idUser)
            loadStatsUser(idUser);
        }
        return()=>{
            setdataUser(null);
            setNatureslabelsUser([]);
            setNbrOpenPerNatureUser([]);
        }
      },[idUser])
    return( 
        <>
               <Grid container>
                  <Grid item lg={8}>
                    <Card>
                      <CardHeader  title="Statistiques d'utilisation relatives aux 30 derniers jours."/>
                      <CardContent> 
                        {dataUser != null ?
                            <Line id="SecondaryChart" data={dataUser} options={options} redraw />
                         : <></>
                        }
                        
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                  <Card>
                      <CardHeader  title="Documents consultés par type"/>
                      <CardContent> 
                        <Pie data={dataPieUser} options={options} />
                      </CardContent>
                    </Card>

                  </Grid>
                </Grid>
        </>
    )

}