
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {DOC_VIEWER_URL} from "config/globals" ;
export default function LinkToViewer({id_file_store, text,isReference,id,source,DocOpened,id_search}){
        const url = DOC_VIEWER_URL+"/Documents/showBookPage/"+id_file_store+"/"+id_search;
    return( 
        <Link href={url} target="_blank" underline="none">
            <Typography sx={{padding:(isReference ? "10px" : "0")}} variant={(isReference ? "subtitle2" : "body1")} component="div">
                <b dangerouslySetInnerHTML={{__html : String(text).replace("(","<br><small>(").replace(")",")</small>")}}></b> 
            </Typography>
        </Link> 
    )
}