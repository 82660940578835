import {SEARCH_API_URL} from "config/globals" ;
import { UserToken } from "config/Token";

// Post les resultat
export const GetSearchResult = async (object) => {
    const token = UserToken();
       try{
        const response = await fetch(SEARCH_API_URL+'/DbprofiscalEngine/Search',{
          method: 'POST',
          headers: { 
            "Content-Type": `application/json`,
            "Authorization": `Bearer ${token}`
         },
         body: JSON.stringify(object)
        })
        if(response.status != 200){
          return null;
        }
        return response.json();
    }catch(err)  {
      return null;
    } 
}
export const GetSearchResultV1 = async (object) => {
  const token = UserToken();
     try{
      const response = await fetch(SEARCH_API_URL+'/DbprofiscalEngine/SearchV1',{
        method: 'POST',
        headers: { 
          "Content-Type": `application/json`,
          "Authorization": `Bearer ${token}`
       },
       body: JSON.stringify(object)
      })
      if(response.status != 200){
        return null;
      }
      return response.json();
  }catch(err)  {
    return null;
  } 
}


export const GetBooksSearchResult = async (object) => {
  const token = UserToken();
  try{
      const response = await fetch(SEARCH_API_URL+'/BooksEngine/Search',{
        method: 'POST',
        headers: { 
          "Content-Type": `application/json`,
          "Authorization": `Bearer ${token}`
       },
       body: JSON.stringify(object)
    })
    if(response.status != 200){
      return null;
    }
      return response.json();
  }catch(err)  {
    return null;
  } 
}
