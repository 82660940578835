import React from "react";
import {useState,useEffect,useContext} from 'react';
import {SIGNALR_HUB_URL} from "config/globals" ;
import { HubConnectionBuilder } from '@microsoft/signalr';
import AppAuthContext from "Contexts/AppAuthContext";
import {useHistory} from 'react-router-dom';
import platform from 'platform';

export default function HubConnection(){
    const {userToken} = useContext(AppAuthContext);
    const [ connection, setConnection ] = useState(null);
    const [History, setHistory] = useState(useHistory())
    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(SIGNALR_HUB_URL)
            .withAutomaticReconnect()
            .build();
        setConnection(newConnection);
    }, []);
  
      useEffect(() => {
        if (connection) {
            if(userToken){
                connection.start()
                .then(result => {  
                    connection.send('joinapp', userToken,platform.name,(platform.product ?? "" )+ " "+platform.os,"WebClient");
                    console.log('Connected!');      
                    connection.on('forcelogout', message => {
                        History.push("/logout");
                    });                        
                    connection.on('confirmeConnection', message => {
                       console.log("SignalR Connection Id : "+message);
                    });   
                                          
                    connection.on('AppNotification', message => {
                       console.log("New Notification recived");
                    });    
                })
                .catch(e => console.log('Connection failed: ', e));
            }
        }
      }, [connection,userToken]);
    return( 
            <>
            </>
        )
}