import  React from 'react';
import {useEffect,useState,useContext} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Moment from 'moment';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {GetAvoirFile} from 'Services/SubscriptionManageService';
import AppDataContext from "Contexts/AppDataContext";
import { saveAs } from 'file-saver';
import CircularProgress from '@mui/material/CircularProgress';
export default function AvoirsComponent(props) {
  const {setServerError} = useContext(AppDataContext);
  const [ Avoirs, setAvoirs] = useState([]); 
  const [IdDownloading,setIdDownloading]= useState(""); 
  const saveFile = (id,numG) => {
    setIdDownloading(id);
    GetAvoirFile(id)
    .then(res => {
      if(res != null){
        saveAs(res, 'Avoir-dbprofiscal-'+numG+'.pdf');
        setIdDownloading("");
      }else{
        setServerError(true);
        setIdDownloading("");
      }
  
    })
    .catch(err => console.log(err))
  }
 useEffect(() => {
  setAvoirs(props.avoirs);
 }, []);
 return (
    <>
                       <TableContainer component={Paper}> 
                   <Toolbar
                     sx={{
                       pl: { sm: 2 },
                       pr: { xs: 1, sm: 1 }
                     }}
                   >
                       <Typography
                         sx={{ flex: '1 1 100%' }}
                         variant="h6"
                         id="tableTitle"
                         component="div"
                       >
                         Mes Avoirs 
                       </Typography>
                   </Toolbar>
                     <Table sx={{ minWidth: 650 }} aria-label="simple table">
                       <TableHead>
                         <TableRow>
                           <TableCell> Avoir </TableCell>
                           <TableCell> Abonnement </TableCell>
                           <TableCell> Facture </TableCell>
                           <TableCell> Montant </TableCell>
                           <TableCell></TableCell>
                         </TableRow>
                       </TableHead>
                       <TableBody>
                       {Avoirs.map((abonnement,i) => (
                           <TableRow key={"feedback_"+i}>
                             <TableCell> 
                               Avoir N° {abonnement.avoir.numeroAvoir} <br/>
                               Du {Moment(abonnement.avoir.dateAvoir).format('DD/MM/YYYY')} <br/>
                               <small>Période : {"Du "+Moment(abonnement.avoir.dateDebut).format('DD/MM/YYYY') +" au "+Moment(abonnement.avoir.dateFin).format('DD/MM/YYYY')}</small>    
                             </TableCell>
                             <TableCell>
                              {abonnement.type.type}<br />
                              {"Du "+Moment(abonnement.dateDebut).format('DD/MM/YYYY') +" au "+Moment(abonnement.dateFin).format('DD/MM/YYYY')}<br/>
                              <small> 
                                {abonnement.nbrRech} recherches | {abonnement.nbrDoc} Documents Consultés
                              </small> 
                             </TableCell>
                             <TableCell>
                               <b><i>SSII-{abonnement.facture.numeroGlobal}</i></b> <br/>
                               <small>Dbprofiscal-{abonnement.facture.numeroProduit}</small> <br />
                               <b>Montant H.T : {abonnement.facture.montant}</b>  {"("+abonnement.facture.devise.symbole+")"} <br />
                               <small style={{display:(String(abonnement.facture.remise) == "0" ? 'none':'')}}>(Remise : {abonnement.facture.remise+"%"})</small>  
                             </TableCell>
                             <TableCell>
                              {(abonnement.facture.montant+ (abonnement.facture.montant*abonnement.facture.tauxTVA/100)-abonnement.facture.montantTimbreFiscal).toFixed(3)} {"("+abonnement.facture.devise.symbole+")"}
                             </TableCell>
                             <TableCell>
                             <Tooltip sx={{display: IdDownloading == abonnement.id ? 'none' : ''}}  title="Télécharger">      
                                                    <IconButton onClick={(e)=>saveFile(abonnement.id,abonnement.avoir.numeroAvoir)} aria-label="Télécharger" size="large">
                                                      <FileDownloadIcon variant="outlined" color="primary" /> 
                                                    </IconButton>
                                            </Tooltip> 
                                            <CircularProgress sx={{display: IdDownloading!=abonnement.id ? 'none' : ''}} disableShrink />

                             </TableCell>
                           </TableRow>
                         ))}
                       </TableBody>
                     </Table>  
                   </TableContainer>
    </>
 );
}
