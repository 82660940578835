    export const GetIdentityConfig = () =>{
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return {
                authority: "https://compte.dbprofiscal.com/", //(string): The URL of the OIDC provider.
                client_id: "FrontEnd.ReactSearchApplication", //(string): Your client application's identifier as registered with the OIDC provider.
                redirect_uri: "http://localhost:3000/signin-callback", //The URI of your client application to receive a response from the OIDC provider.
                //login: process.env.REACT_APP_AUTH_URL + "/Account/login",
                //automaticSilentRenew: false, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
                //loadUserInfo: false, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
                silent_redirect_uri: "http://localhost:3000/silentrenew", //(string): The URL for the page containing the code handling the silent renew.
                post_logout_redirect_uri: "http://localhost:3000/logout-callback", // (string): The OIDC post-logout redirect URI.
                //audience: "https://example.com", //is there a way to specific the audience when making the jwt
                response_type: "code", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
                grantType: "code",
                clockSkew: 3600,
                scope: "openid profile Searching_API_Ressource offline_access",
                 //(string, default: 'openid'): The scope being requested from the OIDC provider.
                //webAuthResponseType: "id_token token"
            };
        } else {
            return {
                authority: "https://compte.dbprofiscal.com/", //(string): The URL of the OIDC provider.
                client_id: "FrontEnd.ReactSearchApplication", //(string): Your client application's identifier as registered with the OIDC provider.
                redirect_uri: "https://app.dbprofiscal.com/signin-callback", //The URI of your client application to receive a response from the OIDC provider.
                //login: process.env.REACT_APP_AUTH_URL + "/Account/login",
                //automaticSilentRenew: false, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
                //loadUserInfo: false, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
                silent_redirect_uri: "https://app.dbprofiscal.com/silentrenew", //(string): The URL for the page containing the code handling the silent renew.
                post_logout_redirect_uri: "https://app.dbprofiscal.com/logout-callback", // (string): The OIDC post-logout redirect URI.
                //audience: "https://example.com", //is there a way to specific the audience when making the jwt
                response_type: "code", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
                grantType: "code",
                clockSkew: 3600,
                scope: "openid profile Searching_API_Ressource offline_access" //(string, default: 'openid'): The scope being requested from the OIDC provider.
                //webAuthResponseType: "id_token token"
            };
        }
    };

export const METADATA_OIDC = {
    issuer: "https://compte.dbprofiscal.com",
    jwks_uri: process.env.REACT_APP_AUTH_URL + "/.well-known/openid-configuration/jwks",
    authorization_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/authorize",
    token_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/token",
    userinfo_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/userinfo",
    end_session_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/endsession",
    check_session_iframe: process.env.REACT_APP_AUTH_URL + "/connect/checksession",
    revocation_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/revocation",
    introspection_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/introspect"
};