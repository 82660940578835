import React from "react";
import {useState,useEffect,useRef,useContext}  from "react";
import {GetBooksSearchResult} from "Services/ResultatService";
import LinkBookToViewer from "components/private/Search/LinkBookToViewer";
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Autocomplete from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import AppDataContext from "Contexts/AppDataContext";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import {useHistory} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import CardHeader from '@mui/material/CardHeader';
import ListMui from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
export default  function SearchERY(props) {
  const history = useHistory(); 
  const [Resultat, setResultat] = useState({total:0,results : [],aggregationsByNature : [],aggregationsByYears : []});
  const [loading, setLoading] = useState(false);
  const [BackGrounLoading, setBackGrounLoading] = useState(false);
  const {AllBooksYears,setServerError,CurrentSearchbooksObject,SaveCurrentSearchBooksObject, setCurrentSearchbooksObject ,AutoCompleteOptions,BooksOptions} = useContext(AppDataContext);
  const [ KeyWords, setKeyWords] = useState("");
  const [ minYear, setminYear] = useState(0);
  const [ maxYear, setmaxYear] = useState(3000);
  const [ books , setbooks ] = useState([]);
  const [ Selectedbooks , SetSelectedbooks ] = useState([]);
  const [LoadingAutoComp, setLoadingAutoComp] = useState(false);
  const [focused, setFocused] = useState(false);
  const [LoadingBooks, setLoadingBooks] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const selected = () => setFocused(false);
  const [InputQuery, setInputQuery] = useState(KeyWords);
  const [TriMode, setTriMode] = useState("AnneeScore");
  const [KeyWordsopen, setKeyWordsopen] = useState(false);
  const [ YearsFilter, setYearsFilter] = useState([]);
  const [ OuvrageFilter, setOuvrageFilter] = useState([]);
  const [TotalDocs, setTotalDocs] = useState(0);
  const [searchTime, setsearchTime] = useState(0);

  const [searchInfos,setsearchInfos] = useState({});
  const [firstFilter,setfirstFilter] = useState("none");


  const trichange = (event)=>{
    setTriMode(event.target.value);
  }
  const functionHandler = (data) => {
    setFocused(true);
    setInputQuery(data);
    setKeyWords(data)
  }
  const submitSearch = ()=> {  
    setLoading(true); 
    let newSearch = {
      query :KeyWords,
      anneeMin :  minYear == "" ?  Math.min(...AllBooksYears.map(x=>Number(x.id))) :minYear,
      anneeMax : maxYear == "" ? Math.max(...AllBooksYears.map(x=>Number(x.id))) : maxYear,
      filtreNature : books,
      FromDashBoard : true,
      searchConfig :{
        SortBy : TriMode
      }
    }
    localStorage.setItem('searchBooksmodel', JSON.stringify(newSearch));
    history.push('ReloadBookSearch');   
  }

  
  const LoadSearch = (searchInfos,startFrom)=>{
    searchInfos.startFrom = startFrom;
    if(startFrom == 0 || TotalDocs>Resultat.results.length)
    {
      setLoading(true);
      GetBooksSearchResult(     
        searchInfos
            )
            .then((res) => {    
              if(res!=null){          
                if(res.total == 0){
                  setTotalDocs(0);
                  setCurrentSearchbooksObject(res.rechercheInfo);
                  res.results = [];
                  res.aggregationsByNature = [];
                  res.aggregationsByYears = [];
                }
  
                if(startFrom == 0){
                  if((searchInfos.id_recherche == undefined || searchInfos.id_recherche=="")){
                    //new search
                    setTotalDocs(res.total);
                    setsearchTime(res.searchTime)
                    setCurrentSearchbooksObject(res.rechercheInfo);
                    setResultat(res); 
                    searchInfos.id_recherche=res.rechercheInfo.id;
                    setsearchInfos(searchInfos);
                  }else{
                    //filtring from aggregation
                    setsearchTime(res.searchTime)
                    setTotalDocs(res.total);                     
                    let filterName= firstFilter;
                    if((OuvrageFilter.length==0 && firstFilter=="natures") || searchInfos.agregfiltreNatures==undefined ) {
                      filterName="none";
                      searchInfos.agregfiltreNatures=undefined;
                    }
                    if((YearsFilter.length==0 && firstFilter=="years") || searchInfos.agregfiltreAnnees==undefined) {
                      filterName="none";
                      searchInfos.agregfiltreAnnees=undefined;
                    }

                    if((searchInfos.agregfiltreNatures != undefined) && firstFilter==="none"){
                      filterName ="natures";
                    }
                    if((searchInfos.agregfiltreAnnees != undefined) && firstFilter==="none"){
                      filterName = "years";
                    }

                    setfirstFilter(filterName);
                    switch(filterName){
                      case "natures":
                        setResultat({...Resultat,
                            results:res.results,
                            aggregationsByYears:res.aggregationsByYears,
                            aggregationsByTags:res.aggregationsByTags
                          });
                        break;
                        case "years":
                          setResultat({...Resultat,
                              results:res.results,
                              aggregationsByNature:res.aggregationsByNature,
                              aggregationsByTags:res.aggregationsByTags
                            });
                          break;                      
                      case "none":
                        setResultat({...Resultat,
                          results:res.results,
                          aggregationsByYears:res.aggregationsByYears,
                          aggregationsByNature:res.aggregationsByNature,
                          aggregationsByTags:res.aggregationsByTags
                        });
                        break;
                    }
                  }
                  if(res.total <= res.rechercheInfo.searchConfig.resultPerPage){                  
                    setBackGrounLoading(false);
                  }else{
                    setBackGrounLoading(true);
                  }
                }else{
                  if(res.results.length>0){
                    SaveCurrentSearchBooksObject({...CurrentSearchbooksObject,  seenPages: startFrom })
                    setResultat({...Resultat,  results:[...Resultat.results, ...res.results]})
                  }
                }


                setLoading(false); 
              }else{
                setServerError(true);
                setLoading(false); 
              }
  
            })
    }else{
      setBackGrounLoading(false);
    }

  }

  const BooksChange = (SelectedItems)=>{
    let selected = [];
    SelectedItems.forEach(element => {
      selected.push(element.id)
    });
    setbooks(selected);
    SetSelectedbooks(SelectedItems)
  }

  const UpdateYearsFilter = (event) => {   
    let filter = YearsFilter;
    if(event.target.checked){
      filter.push(event.target.value);
      setCurrentSearchbooksObject({...CurrentSearchbooksObject,  afterSearchYearsFilter: filter})
    }else{
      const index = filter.indexOf(event.target.value);
      filter.splice(index,1);
    }
    setResultat({...Resultat,  results:[]})
    setYearsFilter(filter);
    let searchForm = searchInfos;
    searchForm.agregfiltreAnnees = filter;
    LoadSearch(searchForm,0);
  }
  const ClearAnneeFilter = ()=>{
    setYearsFilter([]);
    setResultat({...Resultat,  results:[]})
    let searchForm = searchInfos;
    searchForm.agregfiltreAnnees = null;
    LoadSearch(searchForm,0);
  }

  const UpdateOuvragesFilter = (event) => {   
    let filter = OuvrageFilter;
    if(event.target.checked){
      filter.push(event.target.value);
      setCurrentSearchbooksObject({...CurrentSearchbooksObject,  afterSearchNaturesFilter: filter})
    }else{
      const index = filter.indexOf(event.target.value);
      filter.splice(index,1);
    }
    setOuvrageFilter(filter);
    setResultat({...Resultat,  results:[]})
    let searchForm = searchInfos;
    searchForm.agregfiltreNatures = filter;
    LoadSearch(searchForm,0);
  }
  const ClearOuvrageFilter = ()=>{
    setOuvrageFilter([]);
    setResultat({...Resultat,  results:[]})
    let searchForm = searchInfos;
    searchForm.agregfiltreNatures = null;
    LoadSearch(searchForm,0);
  }

  const handleScroll = (e) => {
    const scrollHeight = e.srcElement.scrollHeight;
    const currentHeight = Math.ceil(
        e.srcElement.scrollTop + window.innerHeight
    );
    if (currentHeight+1 > scrollHeight && !loading) {
      LoadSearch(searchInfos, Resultat.results.length);
    }
  };
  useEffect(() => {
    document.getElementById("AppContainer").addEventListener('scroll', handleScroll);
    return () => {
      document.getElementById("AppContainer").removeEventListener('scroll', handleScroll);
    }
  }, [loading,searchInfos]);

  const SearchEnterPress = (event)=>{
    if ((event.code === "Enter" || event.code === "NumpadEnter") && !KeyWordsopen) {
      submitSearch()
    }
  }
  useEffect(() => {
    if(localStorage.getItem('searchBooksmodel') != null){
      let searchModel = JSON.parse(localStorage.getItem('searchBooksmodel'));
      setKeyWords(searchModel.query);
      setminYear(searchModel.anneeMin);
      setmaxYear(searchModel.anneeMax);
      setbooks(searchModel.filtreNature);
      setTriMode(searchModel.searchConfig.SortBy);
      let BooksItems = []
      if(BooksOptions.length >0){
        setLoadingBooks(false)
          searchModel.filtreNature.forEach(element => {
          let selected = BooksOptions.find(v => v.id==element)  
          if(selected){   
            BooksItems.push({label:selected.label,id:selected.id}) 
          }
        });
        SetSelectedbooks(BooksItems)
      }
      if(searchModel.FromDashBoard === true){
        searchModel.FromDashBoard = false;
        localStorage.setItem('searchBooksmodel', JSON.stringify(searchModel));
        setLoading(true);
        LoadSearch(searchModel,0);
      }

    }else{
      setminYear(Math.min(...AllBooksYears.map(x=>Number(x.id))))
      setmaxYear(Math.max(...AllBooksYears.map(x=>Number(x.id))))
    }
    return()=>{
      setResultat({total:0,results : [],aggregationsByNature : [],aggregationsByYears : []})
      setCurrentSearchbooksObject({})
    }
  },[])


  const GetUrlBook= (bookId)=>{
    const bk =  BooksOptions.find(f=>f.id===bookId);
    return bk == undefined ? "" : bk.url;
  }

  const GetNameBook = (bookId)=>{
    const bk = BooksOptions.find(f=>f.id===bookId);  
    return bk.label;
  }
    return(
      <>
      <Box sx={{ flexGrow: 1,mt:1,mb:1,mr:1,ml:1 }} >
        <Grid container spacing={2}>
        <Grid item  xs={12} md={12} lg={4}>
              <Grid item lg={12}>
              <Card elevation={5} sx={{mb:1}}>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                      <Autocomplete               
                              inputValue={KeyWords}
                              onInputChange={(event, newInputQuery) => {
                                functionHandler(newInputQuery);
                              }}
                              onChange={selected}
                              options={AutoCompleteOptions}
                              freeSolo={true}
                              open={KeyWords?.length>2 && focused }
                              onOpen={() => setKeyWordsopen(true)}
                              onClose={() => setKeyWordsopen(false)}
                              renderInput={ (params) => 
                            <TextField 
                              {...params} 
                              label="Mots clés"  
                              onFocus={onFocus} onBlur={onBlur}      
                              onKeyDown={(e=>SearchEnterPress(e))}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {LoadingAutoComp ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}               
                              />
                            }
                            renderOption={(props, option, { inputValue }) => {
                              const matches = match(option.label, inputValue);
                              const parts = parse(option.label, matches);
                      
                              return (
                                <li {...props}>
                                  <div>
                                    {parts.map((part, index) => (
                                      <span
                                        key={index}
                                        style={{
                                          fontWeight: part.highlight ? 700 : 400
                                        }}
                                      >
                                        {part.text}
                                      </span>
                                    ))}
                                  </div>
                                </li>
                              );
                            }}
                            
                          />
                      </Grid>  
                      <Grid item xs={12}>
                      <FormControl >
                        <FormLabel>Critère de tri des documents</FormLabel>
                        <RadioGroup
                          value={TriMode}
                          name="radio-buttons-group"
                          row={true}
                          onChange={trichange}
                        >
                            <FormControlLabel value="AnneeScore" control={<Radio />} label="Année-Score" /> 
                            <FormControlLabel value="ScoreAnnee" control={<Radio />} label="Score-Année" /> 
                        </RadioGroup>
                      </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                                                    <Autocomplete
                                                          id="MinYearTextBox"
                                                          freeSolo
                                                          value={{label:minYear,id:minYear}}
                                                          onChange={(e,value)=>setminYear((value == null || value=="") ? Math.min(...AllBooksYears.map(x=>Number(x.id))): value.id)}
                                                          options={AllBooksYears}
                                                          type="number"
                                                          getOptionLabel={(option) => String(option.label) || ""}
                                                          renderInput={(params) => <TextField {...params} label="Année Min" />}
                                                        />
                                           </Grid>     
                                           <Grid item xs={6}>
                                                    <Autocomplete
                                                          id="MaxYearTextBox"
                                                          freeSolo
                                                          getOptionLabel={(option) => String(option.label) || ""}
                                                          value={{label:maxYear,id:maxYear}}
                                                          onChange={(e,value)=>setmaxYear((value == null || value=="") ? Math.max(...AllBooksYears.map(x=>Number(x.id))): value.id)}
                                                          options={AllBooksYears}
                                                          type="number"
                                                          renderInput={(params) => <TextField {...params} label="Année Max" />}
                                                        />
                                           </Grid>  
                        <Grid item xs={12}>
                        <Autocomplete
                              multiple
                              id="tags-standard"
                              options={BooksOptions}
                              value={Selectedbooks}
                              onChange={(event, newValue,reason) => {
                                BooksChange(newValue,reason);
                              }}
                              getOptionLabel={(option) => option.label}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  label="Liste des livres"
                                  placeholder="Liste des livres"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {LoadingBooks ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }} 
                                />
                              )}
                            />

                            </Grid>
                     </Grid>
                  </CardContent>
                  <CardActions disableSpacing>
                        <Button
                          style={{marginLeft: 'auto'}}
                          type="submit"
                          variant="contained"
                          onClick={submitSearch} endIcon={<ManageSearchIcon />}>Lancer la recherche</Button>
                  </CardActions>
              </Card>
              <Grid item lg={12}>
                            <Typography variant="subtitle2" sx={{p:0}} gutterBottom component="div">
                              Résultat de recherche : <b>{Resultat.total} documents </b> <br />
                              <small>Temps de recherche :  {searchTime} secondes.</small>
                            </Typography>
                          </Grid>
              </Grid>
              <Grid item lg={12}>
              <Card elevation={2}>
              <CardHeader   
                    sx={{pb:0}}   
                    titleTypographyProps={{variant:'caption' }}   
                    action={(OuvrageFilter.length===0 ?
                      <></>
                      :
                      <IconButton color="error" onClick={(e)=>ClearOuvrageFilter()}>
                        <FilterAltOffIcon />
                      </IconButton>
                      )
                    }
                  title="Filtrage par Ouvrage"          
                />
                      <CardContent  sx={{pt:0}}   >
                    {Resultat.aggregationsByNature.length===0 ? <></> :                   
                      <ListMui dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      {Resultat.aggregationsByNature.sort((a, b) => a.count < b.count ? 1 : -1).map((elem,key) => {
                        const labelId = `checkbox-list-secondary-label-${elem.item}`;
                        return (
                          <ListItem
                            key={"Type_"+key}
                            secondaryAction={
                              <Checkbox
                                edge="end"
                                value={elem.item} 
                                onChange={UpdateOuvragesFilter}
                                checked={OuvrageFilter.includes(elem.item)}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            }
                            disablePadding
                          >
                            <ListItemButton>
                              <ListItemText id={labelId} primary={GetNameBook(elem.item) +" ("+elem.count+")" } />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </ListMui>}

                  </CardContent>
                </Card>
              
              </Grid>
              <Grid item lg={12} sx={{mt:1}}>
                <Card elevation={2}>
                <CardHeader   
                    sx={{pb:0}}   
                    titleTypographyProps={{variant:'caption' }}   
                    action={(YearsFilter.length===0 ?
                      <></>
                      :
                      <IconButton color="error" onClick={(e)=>ClearAnneeFilter()}>
                        <FilterAltOffIcon />
                      </IconButton>
                      )
                    }
                  title="Filtrage par année"          
                />
                      <CardContent>
                    {Resultat.aggregationsByYears.length===0 ? <></> :                 
                        <ListMui dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      {Resultat.aggregationsByYears.map((elem,key) => {
                        const labelId = `checkbox-list-secondary-label-${elem.item}`;
                        return (
                          <ListItem
                            key={"years_"+key}
                            secondaryAction={
                              <Checkbox
                                edge="end"
                                value={elem.item} 
                                onChange={UpdateYearsFilter}
                                checked={YearsFilter.includes(elem.item)}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            }
                            disablePadding
                          >
                            <ListItemButton>
                              <ListItemText id={labelId} primary={elem.item +" ("+elem.count+")" } />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </ListMui>}

                  </CardContent>
                </Card>
              </Grid>
        </Grid>

          <Grid item   xs={12} md={12} lg={8} style={{paddingTop:'25px'}}>

          {( !loading && Resultat.results.length === 0) ? <></> :          
          
                        <Grid  container spacing={1}>  
                          <Grid item lg={12} md={12} sm={12}>
                            {Resultat.results.map((result,i) => {
                                return (
                                  <Card sx={{marginBottom:'10px' }}> 
                                  <CardContent  sx={{ p:'5px', '&:last-child': { pb: '5px' }}}>
                                      <Grid container sx={{paddingBottom:'0px'}}> 
                                      <Grid item lg={3} >
                                          <Box sx={{textAlign:"center" , verticalAlign:"middle", mt:2,p:2 }} >
                                            <Link href={GetUrlBook(result.nature.code)} target="_blank" underline="none">
                                                  <Typography variant="body1" display="block" gutterBottom>
                                                  
                                                  <b> {result.nature.libelle} </b> 
                                              </Typography>
                                            </Link>

                                          </Box>
                                      </Grid>
                                        <Grid item lg={9}>
                                          <LinkBookToViewer 
                                            id_search={CurrentSearchbooksObject.id}
                                            id={result.id}
                                            id_file_store={result.id_data_store} 
                                            text = {result.titre} isReference={false}
                                            
                                          />
                                           
                                        <Typography variant="caption" dangerouslySetInnerHTML={{ __html: (result.bestFragment .length == 0 ? result.nature.libelle + " : " + result.titre : result.bestFragment  ) }} display="block" gutterBottom>
                                        </Typography> 
                                        <Typography variant="caption" display="block" gutterBottom>
                                         Année : <b> {result.annee}</b>
                                        </Typography>                
                                        </Grid>
                                      </Grid>
                                    </CardContent>      
                                  </Card>
                                );
                            })}
                          </Grid>
                        </Grid>}


                <Grid sx={{display:(loading || BackGrounLoading ? 'inline': 'none')}} container spacing={1}>
                  <Box sx={{ display: 'flex', justifyContent:'center' }}>
                  <CircularProgress disableShrink />
                  </Box>
                  <Box sx={{ width: '100%' }}>
                  <hr />
                  <Skeleton animation="wave" />
                  <br />
                  <Skeleton animation="wave" />
                  <br/>
                  <Skeleton animation="wave" />
                  <br />
                  <Skeleton animation="wave" />
                  <br />
                  <Skeleton animation="wave" />
                  <br/>
                  <Skeleton animation="wave" />
                  </Box>
                </Grid>
          </Grid>

        </Grid>
      </Box>
          </>
  );
}


