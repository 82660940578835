/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.


      <Route path="/app" render={(props) => <DefaultLayout {...props} />} />
      <Redirect from="/" to="/app/dashboard" />

*/
import React from "react";
import ReactDOM from "react-dom";
import { AuthProvider } from "./providers/authProvider";
import { Callback } from "./components/auth/callback";
import { Logout } from "./components/auth/logout";
import { LogoutCallback } from "./components/auth/logoutCallback";
import { PrivateRoute } from "./routes/privateRoute";
import { Register } from "./components/auth/register";
import { SilentRenew } from "./components/auth/silentRenew";
import { IndexComponent } from "components/public/IndexComponent";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import PrivateLayout from "layouts/private/PrivateLayout";

ReactDOM.render(
  <AuthProvider>
      <BrowserRouter>
        <Switch>          
          <Route exact={true} path="/signin-callback" component={Callback} />
          <Route exact={true} path="/logout" component={Logout} />
          <Route exact={true} path="/logout-callback" component={LogoutCallback} />
          <Route exact={true} path="/register" component={Register} />
          <Route exact={true} path="/silentrenew" component={SilentRenew} />
          <Route path="/EspaceClient" render={(props) => <PrivateLayout {...props} />} />
          <Route path="/" component={IndexComponent} />
        </Switch>
      </BrowserRouter>
  </AuthProvider>
,
  document.getElementById("root")
);
