import { useState, useEffect } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function RowRadioButtonsGroup(props) {
  const [TriMode, setTriMode] = useState(props.selectedValue);
  const trichange = (event)=>{
    setTriMode(event.target.value);
    props.onChange(event.target.value);
  }
  return (
    <>
    <FormControl >
    <FormLabel>Critère de tri des documents</FormLabel>
    <RadioGroup
      defaultValue={TriMode}
      name="radio-buttons-group"
      row={true}
      onChange={trichange}
    >
        <FormControlLabel value="ScoreAnneeType" control={<Radio />} label="Par Score-Année-Type" />
        <FormControlLabel value="TypeAnneeScore" control={<Radio />} label="Par Type-Année-Score" /> 
        <FormControlLabel value="AnneeTypeScore" control={<Radio />} label="Par Année-Type-Score" /> 
    </RadioGroup>
  </FormControl>
    </>

 
   


  );
}


