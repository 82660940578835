import {SEARCH_API_URL} from "config/globals" ;
import { UserToken } from "config/Token";

// Get les dossier 
export const GetDossiers = async () => {
    try{
        const response = await fetch(SEARCH_API_URL+'/DossierDocument',{
            method: 'GET',
            headers: {"Authorization": `Bearer ${UserToken()}`},
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    }  
}

export const AddDocumentToFolder = async (id_folder,id_document,id_search) => {
    try{
        const response = await fetch(SEARCH_API_URL+'/DossierDocument/'+id_folder+'/AddDocument?id_document='+id_document+'&id_search='+id_search,{
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${UserToken()}`
           }
        })
        if(response.status != 200){
            return null;
          }
        return true;
    }catch(err)  {
      return null;
    }  
}

export const DeleteDocumentFromFolder = async (id_folder,id_document,id_search) => {
    try{
        const response = await fetch(SEARCH_API_URL+'/DossierDocument/'+id_folder+'/DeleteDocument/'+id_document+'/'+id_search,{
            method: 'DELETE',
            headers: { 
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${UserToken()}`
           }
        })
        if(response.status != 200){
            return null;
          }
        return true;
    }catch(err)  {
      return null;
    }  
}

// Post les dossier
export const PostDossierDocument = async (object) => {
    try{
        const response = await fetch(SEARCH_API_URL+'/DossierDocument',{
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${UserToken()}`
           },
            body: JSON.stringify(object)
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    }  
}


export const getById =async (id) => {
    try{
        const response = await fetch(SEARCH_API_URL+'/DossierDocument/'+id,requestOptions ={
            method: 'GET', 
            headers: { 
                'Content-Type':'application/json',
                "Authorization": `Bearer ${UserToken()}`
            }
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    }  
}
   //Get document By Id 
    export const  getDocumentsByIdDossier = async (id) => {
        try{
            const response = await fetch(SEARCH_API_URL+'/DossierDocument/'+id+'/Documents' , {
                method: 'GET', 
                headers: { 
                    'Content-Type':'application/json',
                    "Authorization": `Bearer ${UserToken()}`
                }
            })
            if(response.status != 200){
                return null;
              }
            return response.json();
        }catch(err)  {
          return null;
        }  
    }


   // Edit les dossier
   export const UpdateDossier = async(selectedDocument) => {
    try{
        const response = await fetch(SEARCH_API_URL+'/DossierDocument/'+selectedDocument.id,{
            method:'PUT',
            headers: { 
                'Content-Type':'application/json',
                "Authorization": `Bearer ${UserToken()}`
            },
            body: JSON.stringify( selectedDocument )
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    }  
   }


   //Delete les dossier 
   export const DeleteDossier = async(id) => {
    try{
        const response = await fetch( SEARCH_API_URL+'/DossierDocument/'+id,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                "Authorization": `Bearer ${UserToken()}`
             }
        })
        if(response.status != 200){
            return null;
          }
        return true;
    }catch(err)  {
      return null;
    }  
   }
