import  React from 'react';
import {useEffect,useState,useContext} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppDataContext from "Contexts/AppDataContext";
import Moment from 'moment';

export default function AbonnementsComponent(props) {
  const [ Abonnements, setAbonnements] = useState([]); 
 useEffect(() => {
    setAbonnements(props.abonnements);
 }, []);
 return (
    <>
                       <TableContainer component={Paper}> 
                   <Toolbar
                     sx={{
                       pl: { sm: 2 },
                       pr: { xs: 1, sm: 1 }
                     }}
                   >
                       <Typography
                         sx={{ flex: '1 1 100%' }}
                         variant="h6"
                         id="tableTitle"
                         component="div"
                       >
                         Mes Abonnements
                       </Typography>
                   </Toolbar>
                     <Table sx={{ minWidth: 650 }} aria-label="simple table">
                       <TableHead>
                         <TableRow>
                           <TableCell> Abonnement </TableCell>
                           <TableCell> Période </TableCell>
                           <TableCell> Etat </TableCell>
                           <TableCell> Facturation </TableCell>
                           <TableCell> Utilisations </TableCell>
                           <TableCell></TableCell>
                         </TableRow>
                       </TableHead>
                       <TableBody>
                         {Abonnements.map((abonnement,i) => (
                           <TableRow key={"feedback_"+i}>
                             <TableCell> 
                               {abonnement.type.type}
                             </TableCell>
                             <TableCell>
                                 {"Du "+Moment(abonnement.dateDebut).format('DD/MM/YYYY') +" au "+Moment(abonnement.dateFin).format('DD/MM/YYYY')}
                             </TableCell>
                             <TableCell>
                               {abonnement.abonnoementActif ? "Valide":"Suspedu"}
                             </TableCell>
                             <TableCell>
                               {!abonnement.type.abonnementFacturable ? "Non Facturable" : abonnement.facture != null ? "Facture N° "+ abonnement.facture.numeroGlobal +" Du "+Moment(abonnement.facture.dateFacture).format('DD/MM/YYYY') : "Facture non disponible" }
                             </TableCell>
                             <TableCell>
                             {abonnement.nbrRech} recherches <br/>
                             {abonnement.nbrDoc} Documents Consultés
                             </TableCell>
                             <TableCell></TableCell>
                           </TableRow>
                         ))}
                       </TableBody>
                     </Table>  
                   </TableContainer>
    </>
 );
}
