import React from "react";
import { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';
import SearchItem from "components/private/Search/SearchItem";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import LinkToViewer from "components/private/Search/LinkToViewer";

import Avatar from '@mui/material/Avatar';
import { purple } from '@mui/material/colors';
import AppDataContext from "Contexts/AppDataContext";
import AppAuthContext from "Contexts/AppAuthContext";
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import ListMui from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useHistory } from 'react-router-dom';
import { GetNotesFromDocument, DeleteNoteById } from 'Services/NotesService';
import { DeleteDocumentFromFolder, AddDocumentToFolder } from 'Services/DossierDocumentService';
import { UpdateDossier, PostDossierDocument } from 'Services/DossierDocumentService';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { SaveNewConfig } from 'Services/SearchConfigService';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TableContainer from '@mui/material/TableContainer';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinkBookToViewer from "components/private/Search/LinkBookToViewer";
import { GetSearchResultV1 } from "Services/ResultatService";
import { WrapText } from "@mui/icons-material";
import { FormControlLabel, FormGroup } from "@mui/material";
const AlertFeed = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 1,
  px: 1,
  pb: 1,
};
export default function SearchVersion1(props) {
  const { IdAbonnement, AllowDownLoading } = useContext(AppAuthContext);
  const { addDocToDocsFavoris, RemoveDocFromDocsFavoris, DocsFavoris,
    fetchDossiers, AllDossiers,
    CurrentSearchObject, SaveCurrentSearchObject, setCurrentSearchObject,
    AddUserFeed, RemoveUserFeed, UserFeeds, DocsWithNotes, FetchDocumentsWithNotes, setServerError, Notifications, AllNatures, AutoCompleteOptions
  } = useContext(AppDataContext);

  const history = useHistory();
  const [notification, setnotification] = useState(null);
  const [TotalDocs, setTotalDocs] = useState(0);
  const [searchTime, setsearchTime] = useState(0);

  const [filtringKeyWords, setfiltringKeyWords] = useState([]);
  const [YearsFilter, setYearsFilter] = useState([]);
  const [NaturesFilter, setNaturesFilter] = useState([]);
  const [TagsFilter, setTagsFilter] = useState([]);
  const [Resultat, setResultat] = useState({ total: 0, results: [], aggregationsByNature: [], aggregationsByTags: [], aggregationsByYears: [] });
  const [loading, setLoading] = useState(false);
  const [openFeedAlert, setopenFeedAlert] = useState(false);

  const SearchConfig = JSON.parse(localStorage.getItem('SearchConfig'));

  const [SearchConfigTri, setSearchConfigTri] = useState(SearchConfig.sortBy)

  const [BackGrounLoading, setBackGrounLoading] = useState(false);

  const [openFoldersModel, setopenFoldersModel] = useState(false);
  const [openNotesModel, setopenNotesModel] = useState(false);

  const handleOpenFolderModal = () => setopenFoldersModel(true);
  const handleOpenNotesModal = () => setopenNotesModel(true);

  const handleCloseFolderModal = () => setopenFoldersModel(false);
  const handleCloseNotesModal = () => setopenNotesModel(false);

  const [SelectedDocumentId, setSelectedDocumentId] = useState(0);
  const [FolderLoading, setFolderLoading] = useState(false);
  const [NotesLoading, setNotesLoading] = useState(false);

  const [selectedDossier, setselectedDossier] = useState({
    nomDossier: ''
  });
  const [action, setaction] = useState('add');
  const [NotesList, setNotesList] = useState([]);

  const [openDialog, setOpenopenDialog] = React.useState(false);

  const [searchInfos, setsearchInfos] = useState({});

  const [firstFilter, setfirstFilter] = useState("none");

  const handleDialogClickOpen = () => {
    setOpenopenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenopenDialog(false);
  };

  const LoadSearch = (searchInfos, startFrom) => {
    searchInfos.startFrom = startFrom;
    console.log(searchInfos)
    if (startFrom == 0 || TotalDocs > Resultat.results.length) {
      setLoading(true);
      GetSearchResultV1(
        searchInfos
      )
        .then((res) => {
          if (res != null) {
            if (res.total == 0) {
              setTotalDocs(0);
              setCurrentSearchObject(res.rechercheInfo);
              res.results = [];
              res.aggregationsByNature = [];
              res.aggregationsByTags = [];
              res.aggregationsByYears = [];
            }

            if (startFrom == 0) {
              if ((searchInfos.id_recherche == undefined || searchInfos.id_recherche == "")) {
                //new search
                setTotalDocs(res.total);
                setsearchTime(res.searchTime)
                setCurrentSearchObject(res.rechercheInfo);
                setResultat(res);
                searchInfos.id_recherche = res.rechercheInfo.id;
                setsearchInfos(searchInfos);
              } else {
                //filtring from aggregation
                setsearchTime(res.searchTime)
                setTotalDocs(res.total);
                let filterName = firstFilter;
                if ((NaturesFilter.length == 0 && firstFilter == "natures") || searchInfos.agregfiltreNatures == undefined) {
                  filterName = "none";
                  searchInfos.agregfiltreNatures = undefined;
                }
                if ((YearsFilter.length == 0 && firstFilter == "years") || searchInfos.agregfiltreAnnees == undefined) {
                  filterName = "none";
                  searchInfos.agregfiltreAnnees = undefined;
                }
                if ((TagsFilter.length == 0 && firstFilter == "impos") || searchInfos.agregfiltreImpos == undefined) {
                  filterName = "none";
                  searchInfos.agregfiltreImpos = undefined;
                }

                if ((searchInfos.agregfiltreNatures != undefined) && firstFilter === "none") {
                  filterName = "natures";
                }
                if ((searchInfos.agregfiltreAnnees != undefined) && firstFilter === "none") {
                  filterName = "years";
                }
                if ((searchInfos.agregfiltreImpos != undefined) && firstFilter === "none") {
                  filterName = "impos";
                }
                setfirstFilter(filterName);
                switch (filterName) {
                  case "natures":
                    setResultat({
                      ...Resultat,
                      results: res.results,
                      aggregationsByYears: res.aggregationsByYears,
                      aggregationsByTags: res.aggregationsByTags
                    });
                    break;
                  case "years":
                    setResultat({
                      ...Resultat,
                      results: res.results,
                      aggregationsByNature: res.aggregationsByNature,
                      aggregationsByTags: res.aggregationsByTags
                    });
                    break;
                  case "impos":
                    setResultat({
                      ...Resultat,
                      results: res.results,
                      aggregationsByYears: res.aggregationsByYears,
                      aggregationsByNature: res.aggregationsByNature
                    });
                    break;
                  case "none":
                    setResultat({
                      ...Resultat,
                      results: res.results,
                      aggregationsByYears: res.aggregationsByYears,
                      aggregationsByNature: res.aggregationsByNature,
                      aggregationsByTags: res.aggregationsByTags
                    });
                    break;
                }
              }
              const NotUserQuery = searchInfos.query === "[DocumentsRecommandes]" || searchInfos.query === "[TopDocumentsDbprofiscal]" || searchInfos.id_notif != null;
              if (!NotUserQuery) {
                if (res.total <= res.rechercheInfo.searchConfig.resultPerPage) {
                  setBackGrounLoading(false);
                } else {
                  setBackGrounLoading(true);
                }
              }
            } else {
              if (res.results.length > 0) {
                SaveCurrentSearchObject({ ...CurrentSearchObject, seenPages: startFrom })
                setResultat({ ...Resultat, results: [...Resultat.results, ...res.results] })
              }
            }


            setLoading(false);
          } else {
            setServerError(true);
            setLoading(false);
          }

        })
    } else {
      setBackGrounLoading(false);
    }

  }

  useEffect(() => {
    if (IdAbonnement == "") {
      history.push("dashboard")
    }
    if (localStorage.getItem("RecommandedDocs") != null) {
      LoadSearch({
        query: "[DocumentsRecommandes]",
        anneeMin: 0,
        anneeMax: 0,
        impos: "%",
        filtreNature: ["%"],
        id_abonnement: IdAbonnement,
      }, 0);
      localStorage.removeItem("RecommandedDocs");
    } else if (localStorage.getItem("topDocDbprofiscal") != null) {
      LoadSearch({
        query: "[TopDocumentsDbprofiscal]",
        anneeMin: 0,
        anneeMax: 0,
        impos: "%",
        filtreNature: ["%"],
        id_abonnement: IdAbonnement,
      }, 0);
      localStorage.removeItem("topDocDbprofiscal");
    }
    else if (localStorage.getItem("showDocsFromNotif") != null) {
      LoadSearch({
        query: "",
        anneeMin: 0,
        anneeMax: 0,
        impos: "%",
        filtreNature: ["%"],
        id_abonnement: IdAbonnement,
        id_notif: localStorage.getItem("showDocsFromNotif")
      }, 0);
      let notif = Notifications.find(x => x.id === localStorage.getItem("showDocsFromNotif"));
      setnotification(notif)
      localStorage.removeItem("showDocsFromNotif");
    } else {
      setnotification(null);
      if (localStorage.getItem('searchmodel') != null) {
        let loadedSearch = JSON.parse(localStorage.getItem('searchmodel'));
        if (loadedSearch.FromDashBoard === true) {
          loadedSearch.FromDashBoard = false;
          loadedSearch.id_abonnement = IdAbonnement,

            localStorage.setItem('searchmodel', JSON.stringify(loadedSearch));
          LoadSearch(loadedSearch, 0);
        }
      }
    }

    return () => {
      setResultat({ total: 0, results: [], aggregationsByNature: [], aggregationsByTags: [], aggregationsByYears: [] })
      setCurrentSearchObject({})
    }
  }, [])
  //Show Form Popup  
  const [DossierformPopup, setDossierformPopup] = useState(false);
  const handleDossierformPopup = (action, dossier) => {
    if (action == 'add') {
      setselectedDossier({
        nomDossier: ''
      })
    } else {
      setselectedDossier(dossier)
    }
    setDossierformPopup(true)
    setaction(action)
  }

  const UpdateNaturesFilter = (event) => {
    let filter = NaturesFilter;
    if (event.target.checked) {
      filter.push(event.target.value);
      SaveCurrentSearchObject({ ...CurrentSearchObject, afterSearchNaturesFilter: filter })
    } else {
      const index = filter.indexOf(event.target.value);
      filter.splice(index, 1);
    }
    setNaturesFilter(filter);
    setResultat({ ...Resultat, results: [] })
    let searchForm = searchInfos;
    searchForm.agregfiltreNatures = filter;
    LoadSearch(searchForm, 0);
  }
  const ClearNatureFilter = () => {
    setNaturesFilter([]);
    setResultat({ ...Resultat, results: [] })
    let searchForm = searchInfos;
    searchForm.agregfiltreNatures = null;
    LoadSearch(searchForm, 0);
  }
  const SetDocumentOpened = (id_document, id_search) => {
    let files = []
    if (CurrentSearchObject.openedFiles != undefined) {
      files = CurrentSearchObject.openedFiles;
    }
    files.push({ index_file: id_document, downloaded: false, openOrder: files.length, downloadedWithOutOpen: false, component: 'searchWeb' });
    SaveCurrentSearchObject({ ...CurrentSearchObject, openedFiles: files });
  }
  const GetTypeDocName = (code) => {
    const nature = AllNatures.find((obj) => obj.code === code);
    if (nature != null) {
      return nature.libelle;
    }
    return "";
  }
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#507CD1',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const setSearchTriConfig = (e) => {
    setSearchConfigTri(e.target.value);
    let config = SearchConfig;
    config.sortBy = e.target.value;
    setResultat({ ...Resultat, results: [] })
    SaveNewConfig(config)
      .then((res) => {
        localStorage.setItem('SearchConfig', JSON.stringify(res));
        LoadSearch(searchInfos, 0);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const BackToSearchEngine = () => {
    history.push('Dashboard');
  }
  return (
    <>
      <br />
      <Card sx={{ mb: 2, p: 0 }}>
        <CardContent sx={{ m: 0, p: 0 }}>
          <Grid sx={{ m: 0, p: 0 }} container>
            <Grid sx={{ m: 0, p: 2 }} item lg={6} md={6} sm={12}>
              <Box sx={{ textAlign: 'center', width: '100%', justifyContent: 'center', mt: 2 }}>
                <Button variant="contained" onClick={BackToSearchEngine}>
                  Nouvelle recherche
                </Button>
              </Box>
            </Grid>
            <Grid sx={{ m: 0, p: 2 }} item lg={6} md={6} sm={12}>
              <Box sx={{ textAlign: 'center', width: '100%', minWidth: 120, justifyContent: 'center' }}>
                <Card elevation={2}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: purple[200] }} aria-label="Recherches">
                        <FindInPageIcon />
                      </Avatar>
                    }
                    title={<b>Résultat de recherche : {TotalDocs} documents trouvés </b>}
                    subheader={"Temps de recherche : " + searchTime + " secondes."}
                  />
                </Card>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <br />
      <Card sx={{ mb: 2, p: 0 }}>
  <CardContent sx={{ m: 0, p: 0 }}>
    {Resultat.aggregationsByNature.length === 0 ? (
      <></> // This seems unnecessary, consider removing it
    ) : (
      <Grid container sx={{ paddingBottom: '0px' }}>
        <Grid item xs={12} sm={6} md={4} lg={3} padding={1}>
          <FormControlLabel
            control={
              <Checkbox
                edge="end"
                onChange={ClearNatureFilter}
                checked={NaturesFilter.length === 0}
                inputProps={{ 'aria-labelledby': 'checkbox-list-secondary-label-AllNatures' }}
              />
            }
            label="Tous les types"
            sx={{
              '& .MuiTypography-root': {
                paddingLeft: '8px',
              },
            }}
          />
        </Grid>
        {Resultat.aggregationsByNature.map((elem, key) => {
          const labelId = `checkbox-list-secondary-label-${elem.item}`;
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={key} padding={1}>
          <FormControlLabel
                control={
                  <Checkbox
                    edge="end"
                    value={elem.item}
                    onChange={UpdateNaturesFilter}
                    checked={NaturesFilter.includes(elem.item)}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                }
                label={GetTypeDocName(elem.item) + ' (' + elem.count + ')'}
                sx={{
                  '& .MuiTypography-root': {
                    paddingLeft: '8px',
                  },
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    )}
  </CardContent>
</Card>
 


      {!(!loading && Resultat.results.length > 0) && (
        <Grid>
          <Grid sx={{ display: (loading || BackGrounLoading ? 'inline' : 'none') }} container spacing={1}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress disableShrink />
            </Box>
            <Box sx={{ width: '100%' }}>
              <hr />
              <Skeleton animation="wave" />
              <br />
              <Skeleton animation="wave" />
              <br />
              <Skeleton animation="wave" />
              <br />
              <Skeleton animation="wave" />
              <br />
              <Skeleton animation="wave" />
              <br />
              <Skeleton animation="wave" />
            </Box>
          </Grid>
          <Grid sx={{ display: ((!BackGrounLoading && !loading) ? 'inline' : 'none') }} container spacing={1}>
            <hr />
            <small>Le chargement des documents est terminé.</small>
          </Grid>
        </Grid>)
}
<Box sx={{ flexGrow: 1, mt: 1, mb: 1, mr: 1, ml: 1 }} >
  {!loading && Resultat.results.length > 0 && (
    <TableContainer component={Paper} style={{ width: '100%' }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Référence</StyledTableCell>
            <StyledTableCell align="center">Titre</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Resultat.results.map((item, i) => {
            /*      console.log(item); // Add this line to log the item
                 console.log(Resultat.rechercheInfo.id)
                 console.log(item.id); */
            return (
              <StyledTableRow key={`SearchItem${i}`}>
                <StyledTableCell component="th" scope="row" align="center" width="25%">

                  <LinkToViewer
                    id={item.id}

                    id_search={Resultat.rechercheInfo.id}
                    id_file_store={item.id_data_store}
                    text={item.reference}
                    isReference={false}
                    DocOpened={SetDocumentOpened}


                  />
                  {item.nature.libelle}


                </StyledTableCell>

                <StyledTableCell align="center" sx={{ fontWeight: 'bold' }} >
                  {item.titre}
                </StyledTableCell>
              </StyledTableRow>);
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )}



</Box>

    </>
  );
}