import {SEARCH_API_URL} from "config/globals" ;
import { UserToken } from "config/Token";

// get all Impositions
export const LoadAutoCompleteOptions =async (KeyWord) =>{
    const LoadMore = KeyWord;
    try{
        const response = await fetch(SEARCH_API_URL+'/Options/GetThemActiv', {
            method: 'GET',
            headers: {"Authorization": `Bearer ${UserToken()}`}
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    }  
};