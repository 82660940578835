import  {createContext,useState,useEffect} from 'react';
import AuthService from "Services/authService";
const AppAuthContext = createContext();

export function AppAuthProvider({children}){
    const [userId,setuserId]= useState("");
    const [userClientId,setuserClientId]= useState("");
    const [userFullName,setuserFullName]= useState("");
    const [userEmail,setuserEmail]= useState("");
    const [userIsAccountOwner,setuserIsAccountOwner]= useState(false);
    const [userToken,setuserToken]= useState("");
    const [IdAbonnement,setIdAbonnement]= useState("");
    const [ClientName,setClientName]= useState("");
    const [AllowDownLoading,setAllowDownLoading]= useState(false);
    
    useEffect(() => {
        const authservice = new AuthService();
        authservice.getUser().then((user)=>{
            setuserToken(user.access_token);
            setuserIsAccountOwner(user.profile.is_manager=="True");
            setuserClientId(user.profile.id_client);
            setuserId(user.profile.sub);
            setuserFullName(user.profile.FullName);
            setuserEmail(user.profile.name);
        })
      },[])
    return (
        <AppAuthContext.Provider value={{userId,userClientId,userFullName,userEmail,userIsAccountOwner,userToken,IdAbonnement,setIdAbonnement,ClientName,setClientName,AllowDownLoading,setAllowDownLoading}}>
            {children}
        </AppAuthContext.Provider>
    )
}

export default AppAuthContext;