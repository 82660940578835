import {SEARCH_API_URL} from "config/globals" ;
import { UserToken } from "config/Token";
// Get Historique 
export const GetHistoriqueTableData = async (id,days) => {
    const token = UserToken();
    try{
        const response = await fetch(SEARCH_API_URL+'/Historiques/LoadTable/'+id+'/'+days,{
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`},
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    } 
}


   //UpdateHistoriqueRecherche
   export const UpdateHistoryRecherche = async(recherche) => {
    const token = UserToken();
    try{
        const response = await fetch(SEARCH_API_URL+'/Historiques/'+recherche.id,{
            method:'PUT',
            headers: { 
                'Content-Type':'application/json',
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify( recherche )
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    } 
   }

   export const AddOpenDocToSearch = async(id_search,doc) => {
    console.log(doc);
    const token = UserToken();
    try{
        const response = await fetch(SEARCH_API_URL+'/Historiques/AddOpenedDoc/'+id_search,{
            method:'PUT',
            headers: { 
                'Content-Type':'application/json',
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify( doc )
        })
        if(response.status != 200){
            return false;
          }
        return true
    }catch(err)  {
      return false;
    } 
   }   
   