import React from "react";
import {useContext,useState,useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { NavLink } from "react-router-dom";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import NotificationsIcon from '@mui/icons-material/Notifications';
import GroupIcon from '@mui/icons-material/Group';
import StoreIcon from '@mui/icons-material/Store';
import { useHistory } from "react-router-dom";
import Badge from '@mui/material/Badge';
import AppAuthContext from "Contexts/AppAuthContext";
import AppDataContext from "Contexts/AppDataContext";
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Notifications from "components/private/Notifications/Notifications";

import WarningIcon from '@mui/icons-material/Warning';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: {xs :  '60%',md:'auto' },
  overflow: 'scroll',
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 1,
  px: 1,
  pb: 1,
};
export default function MainNavBar() {
  const {userId,userFullName,ClientName,userIsAccountOwner} = useContext(AppAuthContext);
  const {NotSeenNotifications} = useContext(AppDataContext);
  
  const [TermsAccepted, setTermsAccepted] = useState(false); 
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElUserMD, setAnchorElUserMD] = useState(null);
  const history = useHistory(); 

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenUserMenuMD = (event) => {
    setAnchorElUserMD(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    setAnchorElUserMD(null);
  };
  const handleLogOut=()=>{
    history.push("/logout");
    handleCloseUserMenu();
  }
  const redirectTo = (route) => {
    history.push(route);
    handleCloseUserMenu();
  }
  /*useEffect(() => {
    setAnchorElNav(NotSeenNotifications>0);
  },[NotSeenNotifications])*/
  useEffect(() => {
    
  },[])
  return (
<>
    <AppBar color="primary" position="static">
      <Box sx={{paddingLeft: '15px',paddingRight:'15px', display: { xs: 'block', md: 'none'} }}>
        <Toolbar disableGutters>
          <ManageSearchIcon sx={{ mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              fontFamily: 'monospace',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',             
            }}
          >
                DBPROFISCAl.COM 
          </Typography>
          <Box sx={{flexGrow:1}}>
            
          </Box>
          <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Notification et actualités fiscales">
              <IconButton           
              size="large"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              color="inherit" 
              onClick={handleOpenNavMenu} sx={{ p: 1 }}>
                    <Badge badgeContent={NotSeenNotifications} color="primary">
                        <NotificationsIcon fontSize="large"  /> 
                    </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Compte dbprofiscal">
                <IconButton          
                  size="large"
                  aria-label="account of current user"
                  aria-controls="primary-search-account-menu"
                  color="inherit" onClick={handleOpenUserMenuMD} sx={{ p: 1 }}>
                <AccountCircle fontSize="large"  />
              </IconButton>
            </Tooltip>
            <Tooltip title="Avertissement juridique ">
                <IconButton           
                size="large"
                color="inherit" 
                onClick={(e)=>setTermsAccepted(false)}>
                  <WarningIcon fontSize="large"  />
                </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              onClose={handleCloseUserMenu}
              anchorEl={anchorElUserMD}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUserMD)}
            >
                <MenuItem disabled={true}>
                  <b> {userFullName} </b> <br/> <small>{"("+ClientName+")"}</small>
                </MenuItem>
                { !userIsAccountOwner ? 
                <></>
            
            :   <>
                <MenuItem key={"ManageUsersBtn"} onClick={(e)=>redirectTo("ManageUsers")}>
                  <Typography textAlign="center">Utilisateurs</Typography>
                </MenuItem>
                <MenuItem key={"ManageSubscriptionBtn"} onClick={(e)=>redirectTo("ManageSubscription")}>
                  <Typography textAlign="center">Commandes & Abonnements</Typography>
                </MenuItem>
            </>

              }
                <MenuItem key={"LoginsBTN"} onClick={(e)=>redirectTo("HistoriqueConnexions")}>
                  <Typography textAlign="center">Historique des connexions</Typography>
                </MenuItem>
                <MenuItem key={"MdpBtn"}>
                  <Typography component="a"             sx={{
              color: 'inherit',
              textDecoration: 'none',             
            }}
            target={"_blank"} href="https://compte.dbprofiscal.com/Account/ChangePassword" textAlign="center">Changer mots de passe</Typography>
                </MenuItem>
                <MenuItem key={"logoutBtn"} onClick={handleLogOut}>
                  <Typography textAlign="center">Se déconnecter</Typography>
                </MenuItem>
            </Menu>
         
          </Box>
        </Toolbar>
      </Box>
      <Box sx={{paddingLeft: '15px',paddingRight:'15px', display: { xs: 'none', md: 'block'} }}>
        <Toolbar disableGutters>
          <ManageSearchIcon sx={{ mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              fontFamily: 'monospace',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',             
            }}
          >
                DBPROFISCAl.COM 
          </Typography>
            { !userIsAccountOwner ? 
              <Box sx={{flexGrow:1}}>
              </Box>
            
            :          
            <Box sx={{flexGrow:1}}>
            <Button
                  key={"ManageUsers"}
                  startIcon={ <GroupIcon />}
                  sx={{
                    ml:2, color: 'white',
                    fontFamily: 'arial',
                    fontWeight: 300,
                    color: 'inherit',
                    textDecoration: 'none',           
                  }}
                  onClick={(e)=>redirectTo("ManageUsers")}
                  >
                  Utilisateurs
                </Button>
                <Button
                  key={"ManageSubscription"}
                  sx={{
                    ml:2, color: 'white',
                    fontFamily: 'arial',
                    fontWeight: 300,
                    color: 'inherit',
                    textDecoration: 'none',           
                  }}
                  startIcon={ <StoreIcon />}
                  onClick={(e)=>redirectTo("ManageSubscription")}
                  >
                  Commandes & Abonnements
                </Button>
            </Box>
            }
          <Box sx={{ flexGrow: 0 }}>
              <Button
                key={"userFullName"}
                sx={{
                  ml:2, color: 'white',
                  fontFamily: 'arial',
                  fontWeight: 300,
                  color: 'inherit',
                  textDecoration: 'none',           
                }}
                >
                  <Typography
                      sx={{mr:3}}
                    variant="caption"
                  >
                        <b> {userFullName} </b> <br/> <small>{"("+ClientName+")"}</small>
                  </Typography>
              </Button>

              
              <Tooltip title="Notification et actualités fiscales">
              <IconButton           
              size="large"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              color="inherit" 
              onClick={handleOpenNavMenu} sx={{ p: 1 }}>
                    <Badge badgeContent={NotSeenNotifications} size="large" color="error">
                        <NotificationsIcon fontSize="large" /> 
                    </Badge>
              </IconButton>
            </Tooltip>
 
            <Tooltip title="Compte dbprofiscal">
              <IconButton           
              size="large"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              color="inherit" 
              onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <AccountCircle fontSize="large"  />
              </IconButton>
            </Tooltip>
           
            <Tooltip title="Avertissement juridique ">
                <IconButton           
                size="large"
                color="inherit" 
                onClick={(e)=>setTermsAccepted(false)}>
                  <WarningIcon fontSize="large"  />
                </IconButton>
            </Tooltip>
            <Menu
              
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={handleCloseUserMenu}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
            >
                <MenuItem key={"LoginsBTN"} onClick={(e)=>redirectTo("HistoriqueConnexions")}>
                  <Typography textAlign="center">Historique des connexions</Typography>
                </MenuItem>
                <MenuItem key={"mdpBtn"}>
                  <Typography component="a" sx={{
              color: 'inherit',
              textDecoration: 'none',             
            }} target={"_blank"} href="https://compte.dbprofiscal.com/Account/ChangePassword" textAlign="center">Changer mots de passe</Typography>
                </MenuItem>
                <MenuItem key={"logoutBtn"} onClick={handleLogOut}>
                  <Typography textAlign="center">Se déconnecter</Typography>
                </MenuItem>
            </Menu>
            
            <Menu
              
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={handleCloseNavMenu}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
            >            
              <Notifications handleCloseNavMenu={handleCloseNavMenu} />
            </Menu>
  
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
    <Modal
        keepMounted
        open={(!TermsAccepted&&userId!="")}
      >
            <Card sx={style} >
                <CardHeader
                    title="Avertissement juridique important!"  
                />
                <CardContent>
                <Typography variant="body1" align="justify" gutterBottom>
                    <b>DBPROFISCAL.COM </b>met en garde ses utilisateurs que la doctrine administrative ne peut se confondre avec la loi et les réglementations applicables qui prévalent toujours.<br />
                    Les solutions retenues dans les prises de position, bien qu'elles puissent avoir un impact très fort sur la pratique, ne sont pas, par principe, opposables à l'administration fiscale par les personnes non destinataires de la réponse.<br/>
                    Bien qu'il soit généralement admis que le contribuable puisse se faire prévaloir des BODI (notes communes) en cours de validité, il ne bénéficie d'aucune garantie légale exprès contre les changements de doctrine administrative.<br/>
                    Dans ce contexte, en tant qu'utilisateur DB ProFiscal vous appelle à procéder aux investigations et recherches que vous jugeriez nécessaires et appliquer, sous votre seule, unique et entière responsabilité, les solutions qui vous semblent les plus appropriées.<br/>
                    En conséquence, les données qui figurent dans cette base sont soumises à une clause de non-responsabilité et sont protégées par un copyright.<br/>
                    De même, nous attirons votre attention sur la nécessité de prendre en considération les dates des textes administratifs ainsi que des éventuels changements de législation postérieurs.<br/>
                    En outre, L'utilisateur est informé que nous avons procédé à une sélection aussi bien dans les BODI que dans les prises de position et que, par conséquent, DB Profiscal n'est pas exhaustive.<br/>
                </Typography>
                </CardContent>
                <CardActions>
                     <Button onClick={(e)=>setTermsAccepted(true)}  style={{marginLeft: 'auto'}} variant="contained" color="success" >J'accepte les termes</Button>
                </CardActions>
              </Card>
      </Modal>
</>
  );
};


