import {useState,useEffect,useContext}  from "react";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import Avatar from '@mui/material/Avatar';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import DescriptionIcon from '@mui/icons-material/Description';
import StoreIcon from '@mui/icons-material/Store';
import { blue, red,purple,indigo,orange,teal,deepOrange,brown} from '@mui/material/colors';
import {GetCurrentSubscriptionState} from "Services/SubscriptionManageService";
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppAuthContext from "Contexts/AppAuthContext";
import { height } from "@mui/system";
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import AddIcon from '@mui/icons-material/Add';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import StarIcon from '@mui/icons-material/Star';
import AppsIcon from '@mui/icons-material/Apps';
import DuoIcon from '@mui/icons-material/Duo';
import {useHistory} from 'react-router-dom';
import Link from '@mui/material/Link';
export default function ButttomCards() { 
  const history = useHistory();
  
  const RecommandedDocs=()=>{
    localStorage.setItem("RecommandedDocs",true);
    history.push('ReloadSearch'); 
  }
  const TopDocs=()=>{
    localStorage.setItem("topDocDbprofiscal",true);
    history.push('ReloadSearch'); 
  }
  useEffect(() => {
  },[])
return (
    <>
            <Grid container>
              <Grid item xs={12} md={6} lg={6}>
                <Card onClick={TopDocs} elevation={3} sx={{bgcolor: purple[50],margin: 1 ,cursor:"pointer"}}>  
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: purple[300] }} aria-label="Exipiration">
                        <TrendingUpIcon />
                      </Avatar>
                    }
                    title={"Top documents de dbprofiscal selon l'utilisation"}
                    subheader={"Les documents les plus consultés dans les 10 derniers jours"}
                  />
                </Card>
              </Grid>

              <Grid item xs={12} md={6}  lg={6}>
                <Card  onClick={RecommandedDocs} elevation={3} sx={{bgcolor: teal[50],margin: 1 ,cursor:"pointer"}}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: teal[300] }} aria-label="Recherches">
                        <StarIcon />
                      </Avatar>
                    }
                    title={"Documents recommandés par nos soins"}
                    subheader={"les documents jugés utiles et importants"}
                  />
                </Card>
              </Grid>
              
              <Grid item xs={12} md={6} lg={6}>
              <Link target={"_blank"} href="https://www.expertiseonline.com" underline="none">
              <Card elevation={3} sx={{bgcolor: deepOrange[50],margin: 1 ,cursor:"pointer"}}>
                  <CardHeader
                      avatar={
                        <Avatar sx={{ bgcolor: deepOrange[300] }} aria-label="Documents">
                          <AppsIcon />
                        </Avatar>
                      }
                      title={"Autres applications de ExpertiseOnLine"}
                      subheader={"Lien vers les autres applications de expertise on line"}
                    />
                </Card>  
              </Link>
            
              </Grid>              
              <Grid item xs={12} md={6} lg={6}>
              <Link target={"_blank"} href="https://www.expertiseonline.com/Demonstration/dbprofiscal" underline="none">
                <Card elevation={3} sx={{bgcolor: orange[50],margin: 1 ,cursor:"pointer"}}>
                      <CardHeader
                        avatar={
                          <Avatar sx={{ bgcolor: orange[300] }} aria-label="Abonnement">
                            <DuoIcon />
                          </Avatar>
                        }
                        title={"Vidéos de démonstration"}
                        subheader={"Lien vers les vidéos de démonstration de la solution DBPROFISCAL"}
                      />
                  </Card>  
              </Link>
              </Grid>
            </Grid>
    </>
)
}