import {SEARCH_API_URL} from "config/globals" ;
import { UserToken } from "config/Token";

export const GetGenStats= async (id) => {
    const token = UserToken();
    try{
        const response =await fetch(SEARCH_API_URL+'/Statistiques/GeneralAccountStatsLast30Days'+(id!=null ? "?id="+id :""),{
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`},
        });
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    }
}

export const GetUAllUsersNumbers= async () => {
  const token = UserToken();
  try{
      const response =await fetch(SEARCH_API_URL+'/Statistiques/AccountUsersStats',{
          method: 'GET',
          headers: {"Authorization": `Bearer ${token}`},
      });
      if(response.status != 200){
          return null;
        }
      return response.json();
  }catch(err)  {
    return null;
  }
}

export const LoginsHistory= async (id,days) => {
  const token = UserToken();
  try{
      const response =await fetch(SEARCH_API_URL+'/Statistiques/UserLogins/'+id+'?days='+days,{
          method: 'GET',
          headers: {"Authorization": `Bearer ${token}`},
      });
      if(response.status != 200){
          return null;
        }
      return response.json();
  }
  catch(err)  {
    return null;
  }
}