import {SEARCH_API_URL} from "config/globals" ;
import { UserToken } from "config/Token";

export const GetPrettyDocumentsWithNotes= async () => {
    const token = UserToken();
    try{
        const response = await fetch(SEARCH_API_URL+'/Notes/GetPrettyDocumentsWithNotes',{
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`},
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    } 
}

export const GetDocsWithNotes= async () => {
    const token = UserToken();    
    try{
        const response = await fetch(SEARCH_API_URL+'/Notes/DocsIdsThatHasNotes',{
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`},
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    }  
}

export const GetNotesFromDocument= async (id_document) => {
    const token = UserToken();
    try{
        const response = await fetch(SEARCH_API_URL+'/Notes/documents/'+id_document,{
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`},
        })
        if(response.status != 200){
            return null;
          }
        return response.json();
    }catch(err)  {
      return null;
    }  
}
export const DeleteNoteById= async (id_note) => {
    const token = UserToken();
    try{
        const response = await fetch(SEARCH_API_URL+'/Notes/'+id_note,{
            method: 'DELETE',
            headers: {"Authorization": `Bearer ${token}`},
        })
        if(response.status != 200){
            return null;
          }
        return true;
    }catch(err)  {
      return null;
    }  
}