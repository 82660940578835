


export default class SearchModel{
    constructor(query,
      filtreNature,
      anneeMin,
      anneeMax,
      impos,
      startFrom,
      pageSize,
      enableDictionnaire,
      orderBy) {
      this.query = query;
      this.filtreNature = filtreNature;
      this.anneeMin = anneeMin;
      this.anneeMax = anneeMax;
      this.impos = impos;
      this.startFrom = startFrom;
      this.pageSize =pageSize;
      this.enableDictionnaire = enableDictionnaire;
      this.orderBy = orderBy;
    }
  }