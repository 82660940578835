import React from "react";
import {useState,useEffect,useContext} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';
import SearchItem from "components/private/Search/SearchItem";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { purple } from '@mui/material/colors';
import {GetSearchResult} from "Services/ResultatService";
import AppDataContext from "Contexts/AppDataContext";
import AppAuthContext from "Contexts/AppAuthContext";
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import ListMui from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {useHistory} from 'react-router-dom';
import {GetNotesFromDocument,DeleteNoteById} from 'Services/NotesService';
import {DeleteDocumentFromFolder,AddDocumentToFolder } from 'Services/DossierDocumentService';
import { UpdateDossier,PostDossierDocument } from 'Services/DossierDocumentService';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {SaveNewConfig} from 'Services/SearchConfigService';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const AlertFeed = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 1,
  px: 1,
  pb: 1,
};
export default  function Search(props) {
  const {IdAbonnement,AllowDownLoading} = useContext(AppAuthContext);
  const {addDocToDocsFavoris,RemoveDocFromDocsFavoris,DocsFavoris,
    fetchDossiers,AllDossiers,
    CurrentSearchObject, SaveCurrentSearchObject,setCurrentSearchObject,
    AddUserFeed,RemoveUserFeed,UserFeeds,DocsWithNotes,FetchDocumentsWithNotes,setServerError,Notifications,AllNatures,AutoCompleteOptions
  } = useContext(AppDataContext);

  const history = useHistory(); 
  const [notification, setnotification] = useState(null);
  const [TotalDocs, setTotalDocs] = useState(0);
  const [searchTime, setsearchTime] = useState(0);
  
  const [ filtringKeyWords, setfiltringKeyWords] = useState([]);
  const [ YearsFilter, setYearsFilter] = useState([]);
  const [ NaturesFilter, setNaturesFilter] = useState([]);
  const [ TagsFilter, setTagsFilter] = useState([]);
  const [Resultat, setResultat] = useState({total:0,results : [],aggregationsByNature : [],aggregationsByTags : [],aggregationsByYears : []});
  const [loading, setLoading] = useState(false);
  const [openFeedAlert, setopenFeedAlert] = useState(false);
  
  const SearchConfig = JSON.parse(localStorage.getItem('SearchConfig'));

  const [SearchConfigTri,setSearchConfigTri] = useState(SearchConfig.sortBy)

  const [BackGrounLoading, setBackGrounLoading] = useState(false);

  const [openFoldersModel, setopenFoldersModel] = useState(false);
  const [openNotesModel, setopenNotesModel] = useState(false);

  const handleOpenFolderModal = () => setopenFoldersModel(true);
  const handleOpenNotesModal = () => setopenNotesModel(true);

  const handleCloseFolderModal = () => setopenFoldersModel(false);
  const handleCloseNotesModal = () => setopenNotesModel(false);

  const [SelectedDocumentId,setSelectedDocumentId] = useState(0);
  const [FolderLoading, setFolderLoading] = useState(false);
  const [NotesLoading, setNotesLoading] = useState(false);

  const [selectedDossier,setselectedDossier] = useState({
    nomDossier:''
  });
  const [action,setaction] = useState('add');
  const [NotesList, setNotesList] = useState([]);

  const [openDialog, setOpenopenDialog] = React.useState(false);

  const [searchInfos,setsearchInfos] = useState({});

  const [firstFilter,setfirstFilter] = useState("none");

  const handleDialogClickOpen = () => {
    setOpenopenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenopenDialog(false);
  };

  const LoadSearch = (searchInfos,startFrom)=>{
    searchInfos.startFrom = startFrom;
    if(startFrom == 0 || TotalDocs>Resultat.results.length)
    {
      setLoading(true);
      GetSearchResult(     
        searchInfos
            )
            .then((res) => {    
              if(res!=null){          
                if(res.total == 0){
                  setTotalDocs(0);
                  setCurrentSearchObject(res.rechercheInfo);
                  res.results = [];
                  res.aggregationsByNature = [];
                  res.aggregationsByTags =  [];
                  res.aggregationsByYears = [];
                }
  
                if(startFrom == 0){
                  if((searchInfos.id_recherche == undefined || searchInfos.id_recherche=="")){
                    //new search
                    setTotalDocs(res.total);
                    setsearchTime(res.searchTime)
                    setCurrentSearchObject(res.rechercheInfo);
                    setResultat(res); 
                    searchInfos.id_recherche=res.rechercheInfo.id;
                    setsearchInfos(searchInfos);
                  }else{
                    //filtring from aggregation
                    setsearchTime(res.searchTime)
                    setTotalDocs(res.total);                     
                    let filterName= firstFilter;
                    if((NaturesFilter.length==0 && firstFilter=="natures") || searchInfos.agregfiltreNatures==undefined ) {
                      filterName="none";
                      searchInfos.agregfiltreNatures=undefined;
                    }
                    if((YearsFilter.length==0 && firstFilter=="years") || searchInfos.agregfiltreAnnees==undefined) {
                      filterName="none";
                      searchInfos.agregfiltreAnnees=undefined;
                    }
                    if((TagsFilter.length==0 && firstFilter=="impos") || searchInfos.agregfiltreImpos==undefined ) {
                      filterName="none";
                      searchInfos.agregfiltreImpos=undefined;
                    }

                    if((searchInfos.agregfiltreNatures != undefined) && firstFilter==="none"){
                      filterName ="natures";
                    }
                    if((searchInfos.agregfiltreAnnees != undefined) && firstFilter==="none"){
                      filterName = "years";
                    }
                    if((searchInfos.agregfiltreImpos != undefined) && firstFilter==="none"){
                      filterName="impos";
                    }
                    setfirstFilter(filterName);
                    switch(filterName){
                      case "natures":
                        setResultat({...Resultat,
                            results:res.results,
                            aggregationsByYears:res.aggregationsByYears,
                            aggregationsByTags:res.aggregationsByTags
                          });
                        break;
                        case "years":
                          setResultat({...Resultat,
                              results:res.results,
                              aggregationsByNature:res.aggregationsByNature,
                              aggregationsByTags:res.aggregationsByTags
                            });
                          break;
                        case "impos":
                          setResultat({...Resultat,
                              results:res.results,
                              aggregationsByYears:res.aggregationsByYears,
                              aggregationsByNature:res.aggregationsByNature
                          });
                          break;                        
                      case "none":
                        setResultat({...Resultat,
                          results:res.results,
                          aggregationsByYears:res.aggregationsByYears,
                          aggregationsByNature:res.aggregationsByNature,
                          aggregationsByTags:res.aggregationsByTags
                        });
                        break;
                    }
                  }
                  const NotUserQuery = searchInfos.query ==="[DocumentsRecommandes]" || searchInfos.query === "[TopDocumentsDbprofiscal]" || searchInfos.id_notif != null;
                  if(!NotUserQuery){
                    if(res.total <= res.rechercheInfo.searchConfig.resultPerPage){                  
                      setBackGrounLoading(false);
                    }else{
                      setBackGrounLoading(true);
                    }
                  }
                }else{
                  if(res.results.length>0){
                    SaveCurrentSearchObject({...CurrentSearchObject,  seenPages: startFrom })
                    setResultat({...Resultat,  results:[...Resultat.results, ...res.results]})
                  }
                }


                setLoading(false); 
              }else{
                setServerError(true);
                setLoading(false); 
              }
  
            })
    }else{
      setBackGrounLoading(false);
    }

  }
  
  const handleScroll = (e) => {
    const scrollHeight = e.srcElement.scrollHeight;
    const currentHeight = Math.ceil(
        e.srcElement.scrollTop + window.innerHeight
    );
    const NotUserQuery = searchInfos.query ==="[DocumentsRecommandes]" || searchInfos.query === "[TopDocumentsDbprofiscal]" || searchInfos.id_notif != null;
    if (currentHeight+1 > scrollHeight && !loading) {
      if(!NotUserQuery){
        LoadSearch(searchInfos, Resultat.results.length);
      }
    }
  };

  useEffect(() => {
    if(IdAbonnement==""){
      history.push("dashboard")
    }
    if(localStorage.getItem("RecommandedDocs") != null){
      LoadSearch({
        query :"[DocumentsRecommandes]",
        anneeMin : 0,
        anneeMax : 0,
        impos : "%",
        filtreNature : ["%"],
        id_abonnement: IdAbonnement,
      },0); 
      localStorage.removeItem("RecommandedDocs");
    }else if(localStorage.getItem("topDocDbprofiscal") != null){
      LoadSearch({
        query :"[TopDocumentsDbprofiscal]",
        anneeMin : 0,
        anneeMax : 0,
        impos : "%",
        filtreNature : ["%"],
        id_abonnement: IdAbonnement,
      },0); 
      localStorage.removeItem("topDocDbprofiscal");
    }
    else if(localStorage.getItem("showDocsFromNotif") != null){
      LoadSearch({
        query :"",
        anneeMin : 0,
        anneeMax : 0,
        impos : "%",
        filtreNature : ["%"],
        id_abonnement: IdAbonnement,
        id_notif:localStorage.getItem("showDocsFromNotif")
      },0);     
      let notif = Notifications.find(x=>x.id===localStorage.getItem("showDocsFromNotif"));
      setnotification(notif)
      localStorage.removeItem("showDocsFromNotif");
    }else{
      setnotification(null);
      if(localStorage.getItem('searchmodel') != null){
        let loadedSearch = JSON.parse(localStorage.getItem('searchmodel'));
        if(loadedSearch.FromDashBoard === true){
          loadedSearch.FromDashBoard = false;
          loadedSearch.id_abonnement = IdAbonnement,

          localStorage.setItem('searchmodel', JSON.stringify(loadedSearch));
          LoadSearch(loadedSearch,0);
        }
      }  
    }

    localStorage.setItem("Cntrl_F_AlertDialog","true");
    return()=>{
      setResultat({total:0,results : [],aggregationsByNature : [],aggregationsByTags : [],aggregationsByYears : []})
      setCurrentSearchObject({})
    }
  },[])
  const SearchEnterPress = (event)=>{
    if ((event.code === "Enter" || event.code === "NumpadEnter")) {
      AddFiltringKeyWord()
    }
  }

  useEffect(() => {
    document.getElementById("AppContainer").addEventListener('scroll', handleScroll);
    document.addEventListener("keydown",Cntrl_F_AlertDialog);
    return () => {
      document.getElementById("AppContainer").removeEventListener('scroll', handleScroll);
      document.removeEventListener("keydown",Cntrl_F_AlertDialog);
    }
  }, [loading,searchInfos]);

  const handleCloseDossierformPopup = () => setDossierformPopup(false);
  
  const onDossierValidationClicked =() => {
    if(action == 'add'){
      if(selectedDossier.nomDossier.trim() === ''){
        return;
      }
      PostDossierDocument(selectedDossier)
      .then(res => {
        if(res.nomDossier)
        {
            
            fetchDossiers();
            handleCloseDossierformPopup();

        }
      })
      .catch (error => console.log("error Add document",error))
    }else{
      UpdateDossier(selectedDossier)
        .then(res =>{
          if(res.nomDossier)
          {
            fetchDossiers();
            handleCloseDossierformPopup();

          }
        })
        .catch (error => console.log("error update document",error))
    }


  }

  const handleDossierFieldChanged = (field, value) => {
    let formData = {...selectedDossier};
    formData[field] = value;
    setselectedDossier(formData);
  }
  //Show Form Popup  
  const [DossierformPopup, setDossierformPopup] = useState(false);
  const handleDossierformPopup = (action,dossier) => {
    if(action=='add'){
      setselectedDossier({
        nomDossier:''
      })
    }else{
      setselectedDossier(dossier)
    }
    setDossierformPopup(true)
    setaction(action)
  }

  function Cntrl_F_AlertDialog (e) {     
    if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {         
      var check = localStorage.getItem("Cntrl_F_AlertDialog");  
      if(check=="true"){
        e.preventDefault(); 
        handleDialogClickOpen();
      }           
    } 

  }
 
  const AcceptedhandleDialogClose = () => {
    localStorage.setItem("Cntrl_F_AlertDialog","false");
    setOpenopenDialog(false);
  };

  const UpdateYearsFilter = (event) => {   
    let filter = YearsFilter;
    if(event.target.checked){
      filter.push(event.target.value);
      SaveCurrentSearchObject({...CurrentSearchObject,  afterSearchYearsFilter: filter})
    }else{
      const index = filter.indexOf(event.target.value);
      filter.splice(index,1);
    }
    setResultat({...Resultat,  results:[]})
    setYearsFilter(filter);
    let searchForm = searchInfos;
    searchForm.agregfiltreAnnees = filter;
    LoadSearch(searchForm,0);
  }

  const UpdateNaturesFilter = (event) => {   
    let filter = NaturesFilter;
    if(event.target.checked){
      filter.push(event.target.value);
      SaveCurrentSearchObject({...CurrentSearchObject,  afterSearchNaturesFilter: filter})
    }else{
      const index = filter.indexOf(event.target.value);
      filter.splice(index,1);
    }
    setNaturesFilter(filter);
    setResultat({...Resultat,  results:[]})
    let searchForm = searchInfos;
    searchForm.agregfiltreNatures = filter;
    LoadSearch(searchForm,0);
  }

  const UpdateTagsFilter = (event) => {   
    let filter = TagsFilter;
    if(event.target.checked){
      filter.push(event.target.value);
      SaveCurrentSearchObject({...CurrentSearchObject,  afterSearchImposFilter: filter})
    }else{
      const index = filter.indexOf(event.target.value);
      filter.splice(index,1);
    }
    setTagsFilter(filter);
    setResultat({...Resultat,  results:[]})
    let searchForm = searchInfos;
    searchForm.agregfiltreImpos = filter;
    LoadSearch(searchForm,0);
  }

  const ClearThemFilter = ()=>{
    setTagsFilter([]);
    setResultat({...Resultat,  results:[]})
    let searchForm = searchInfos;
    searchForm.agregfiltreImpos = null;
    LoadSearch(searchForm,0);
  }

  const ClearAnneeFilter = ()=>{
    setYearsFilter([]);
    setResultat({...Resultat,  results:[]})
    let searchForm = searchInfos;
    searchForm.agregfiltreAnnees = null;
    LoadSearch(searchForm,0);
  }

  const ClearNatureFilter = ()=>{
    setNaturesFilter([]);
    setResultat({...Resultat,  results:[]})
    let searchForm = searchInfos;
    searchForm.agregfiltreNatures = null;
    LoadSearch(searchForm,0);
  }

  const MoveDocToFolder = (document) =>{
    setSelectedDocumentId(document)
    handleOpenFolderModal();
  }

  const SelectedFolderChanged= (event)=>{
    setFolderLoading(true)
    if(event.target.checked){
      AddDocumentToFolder(event.target.value,SelectedDocumentId,CurrentSearchObject.id)
      .then(res => {
        fetchDossiers();
        setFolderLoading(false);     
      })
      .catch(err => console.log(err) )
    }else{
      DeleteDocumentFromFolder(event.target.value,SelectedDocumentId,CurrentSearchObject.id)
      .then(res => {
        fetchDossiers();
        setFolderLoading(false);
      })
      .catch(err => console.log(err) )
    }
  }
  const UpdateFavoris = (id_document,NewState)=>{
    if(NewState){
      addDocToDocsFavoris(id_document,CurrentSearchObject.id);
    }else{
      RemoveDocFromDocsFavoris(id_document);
    }
  }

  const ShowNotes = (id_document) => {
    setNotesLoading(true)
    GetNotesFromDocument(id_document).then(res => {
      if(res!=null){
        setNotesList(res);
        setNotesLoading(false)
      }else{
        setNotesLoading(false)
        setServerError(true)
      }

    })
    handleOpenNotesModal();
  } 

  const DeleteNote = (id_note)=>{
    DeleteNoteById(id_note).then(res => {
      if(res != null){
        let notes = NotesList.filter(d=>d.id != id_note);
        setNotesList(notes);
        FetchDocumentsWithNotes();
      }else{
        setServerError(true)
      }

    })
  }

  const SendUtile = (id_document,Feedback) => {
    let typeToDelete="";
    if(Feedback=="Utile" && UserFeeds.nonUtile.includes(id_document)){
      typeToDelete="NonUtile";
    }
    if(Feedback=="NonUtile" && UserFeeds.utile.includes(id_document)){
      typeToDelete="Utile";
    }
    AddUserFeed(id_document,Feedback,CurrentSearchObject.id)
    if(typeToDelete != ""){
      RemoveUserFeed(id_document,typeToDelete,CurrentSearchObject.id)
    }
    setopenFeedAlert(true);
  }

  const SetDocumentOpened = (id_document,id_search) => {
    let files = []
    if(CurrentSearchObject.openedFiles != undefined){
      files = CurrentSearchObject.openedFiles;
    }
    files.push({index_file:id_document,downloaded:false,openOrder:files.length,downloadedWithOutOpen:false,component:'searchWeb'});
    SaveCurrentSearchObject({...CurrentSearchObject,  openedFiles: files}); 
  }

  const SetDocumentDownloaded = (id_document) => {
    let files = []
    if(CurrentSearchObject.openedFiles != undefined){
      files = CurrentSearchObject.openedFiles;
    }
    files.push({index_file:id_document,downloaded:true,openOrder:files.length,downloadedWithOutOpen:true,component:'search'});
    SaveCurrentSearchObject({...CurrentSearchObject,  openedFiles: files}); 
  }

  const handleCloseFeedAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setopenFeedAlert(false);
  };


  const AddFiltringKeyWord = () =>{
    var KeyWord = document.getElementById("filtringKeyWordInput").value;
    let keywords = filtringKeyWords;
    keywords.push({id : keywords.length, keyword:KeyWord});
    setfiltringKeyWords(keywords);
    document.getElementById("filtringKeyWordInput").value = "";
    
    setNaturesFilter([]);
    setYearsFilter([]);
    setTagsFilter([]);
    setfirstFilter("none");

    setResultat({...Resultat,  results:[]})
    let searchForm = searchInfos;

    searchInfos.agregfiltreNatures=undefined;
    searchInfos.agregfiltreAnnees=undefined;
    searchInfos.agregfiltreImpos=undefined;
    searchForm.filtringKeyWords = keywords;
    LoadSearch(searchForm,0);
  }

  const DeleteAddedKeyWord = (idKeyWord)=>{
    let keywords = filtringKeyWords;
    const objWithIdIndex = keywords.findIndex((obj) => obj.id === idKeyWord);
    if (objWithIdIndex > -1) {
      keywords.splice(objWithIdIndex,1);    
    }
    setfiltringKeyWords(keywords);
    setResultat({...Resultat,  results:[]})
    let searchForm = searchInfos;
    searchInfos.agregfiltreNatures=undefined;
    searchInfos.agregfiltreAnnees=undefined;
    searchInfos.agregfiltreImpos=undefined;
    searchForm.filtringKeyWords = keywords.length == 0 ? undefined : keywords;
    LoadSearch(searchForm,0);
  }

  const GetTypeDocName = (code)=>{
    const nature = AllNatures.find((obj) => obj.code === code);
    if (nature != null ) {
      return nature.libelle;
    }
    return "";
  }
  const GetThemDocName = (code)=>{
    const opt = AutoCompleteOptions.find((obj) => obj.id === code);
    if (opt != null ) {
      return opt.label;
    }
    return "";
  }

  const setSearchTriConfig = (e) => {   
    setSearchConfigTri(e.target.value);
    let config = SearchConfig;
    config.sortBy = e.target.value;
    setResultat({...Resultat,  results:[]})
    SaveNewConfig(config)
        .then((res) => {   
          localStorage.setItem('SearchConfig',JSON.stringify(res));
          LoadSearch(searchInfos,0);
        })
        .catch((err) => {
            console.log(err);
        }) ;
  };
  const BackToSearchEngine=()=>{
    history.push('Dashboard'); 
  }
    return(
      <>
      <Box sx={{ flexGrow: 1,mt:1,mb:1,mr:1,ml:1 }} >
        <Grid container spacing={2}>
          <Grid item  xs={12} md={12} lg={8}>
            <Card sx={{mb:2,p:0}}>
              <CardContent  sx={{m:0,p:0}}>
              <Grid sx={{m:0,p:0}} container>
                        <Grid sx={{m:0,p:0}} item lg={6} md={6} sm={12}>
                          <Box  sx={{textAlign:'center',width:'100%' ,justifyContent:'center',mt:2}}>
                              <Button  variant="contained" onClick={BackToSearchEngine}>
                                    Nouvelle recherche
                              </Button>
                          </Box>
                        </Grid>
                        <Grid sx={{m:0,p:0}} item lg={6} md={6} sm={12}>
                          <Box  sx={{textAlign:'center',width:'100%', minWidth: 120 ,justifyContent:'center'}}>
                            <FormControl sx={{ minWidth: 210, mt:1}}>
                              <InputLabel id="demo-simple-select-label">Tri</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                value={SearchConfigTri}
                                id="demo-simple-select"
                                label="Age"
                                onChange={setSearchTriConfig}
                              >
                                <MenuItem value={"ScoreAnneeType"}>Par Score-Année-Type</MenuItem>
                                <MenuItem value={"TypeAnneeScore"}>Par Type-Année-Score</MenuItem>
                                <MenuItem value={"AnneeTypeScore"}>Par Année-Type-Score</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                    </Grid>
              </CardContent>
            </Card>

          {( !loading && Resultat.results.length === 0) ? <></> :          
          
                        <Grid  container spacing={1}>  
                          <Grid item lg={12} md={12} sm={12}>
                            {Resultat.results.map((item,i) => {
                                return (
                                  <SearchItem 
                                      AllowDownLoad={AllowDownLoading}
                                      HasNotes={DocsWithNotes.includes(item.id)}
                                      id_search={Resultat.rechercheInfo.id}
                                      IsNonUtile={UserFeeds.nonUtile.includes(item.id)}
                                      IsUtile={UserFeeds.utile.includes(item.id)}
                                      DirectDownload={SetDocumentDownloaded}
                                      DocOpened={SetDocumentOpened} 
                                      MoveToFolder={MoveDocToFolder} 
                                      ShowNotes={ShowNotes}
                                      SetFeedBack={SendUtile} 
                                      SetFavorisState={UpdateFavoris} 
                                      isFavoris={DocsFavoris.includes(item.id)} 
                                      key={"SearchItem"+i} 
                                      index={i}
                                      SearchIemModel={item} 
                                  />
                                );
                            })}
                          </Grid>
                        </Grid>}


                <Grid sx={{display:(loading || BackGrounLoading ? 'inline': 'none')}} container spacing={1}>
                  <Box sx={{ display: 'flex', justifyContent:'center' }}>
                  <CircularProgress disableShrink />
                  </Box>
                  <Box sx={{ width: '100%' }}>
                  <hr />
                  <Skeleton animation="wave" />
                  <br />
                  <Skeleton animation="wave" />
                  <br/>
                  <Skeleton animation="wave" />
                  <br />
                  <Skeleton animation="wave" />
                  <br />
                  <Skeleton animation="wave" />
                  <br/>
                  <Skeleton animation="wave" />
                  </Box>
                </Grid>
                <Grid sx={{display:(  (!BackGrounLoading && !loading )? 'inline': 'none')}} container spacing={1}>
                    <hr />
                    <small>Le chargement des documents est terminé.</small>
                </Grid>
          </Grid>
          <Grid item  xs={12} md={12} lg={4}>
              <Grid sx={{display:(notification !=null ? 'none':'')}} item lg={12}>
                {/*
                
                <SearchForm showAdvFilters={false} />

                */}
              </Grid>
              <Grid item lg={12}>
                {
                  notification ==null ?
                  <></>
                      :
                      <Box>           
                          <Typography variant="subtitle1" sx={{p:0}} gutterBottom component="div">
                                <b>{notification.titre}</b> <small>{notification.dateCreationStr}</small><br />
                                {notification.description} 
                          </Typography> 
                      </Box>
    
                }

        
              </Grid>
              <Grid item lg={12}>
              <Card elevation={2}>
                    <CardHeader
                      avatar={
                        <Avatar sx={{ bgcolor: purple[200] }} aria-label="Recherches">
                          <FindInPageIcon />
                        </Avatar>
                      }
                      title={ <b>Résultat de recherche : {TotalDocs} documents trouvés </b>}
                      subheader={"Temps de recherche : "+ searchTime + " secondes."}
                    />
               
                    <CardContent  sx={{pt:0}}>
                    <Typography variant="subtitle2" gutterBottom>
                        Mots clés de recherche : <b>{searchInfos.query}</b>
                    </Typography>
                    <small>Mots clés de ciblage</small>
                    <Box  sx={{textAlign:'center',width:'100%' ,justifyContent:'center'}}>
                      {
                      filtringKeyWords.map((elem,key)=>{
                        return (<Chip key={key} sx={{m:1}} label={elem.keyword} variant="outlined" color="primary" onDelete={(e)=>DeleteAddedKeyWord(elem.id)} />)                   
                      })
                      }
                    </Box>
                      <Box  sx={{textAlign:'center',width:'100%' ,justifyContent:'center'}}>
                          <TextField onKeyDown={(e=>SearchEnterPress(e))} sx={{width:'90%',m:2}} id="filtringKeyWordInput" label="Autres mots clés" size="small"  variant="outlined" />
                          <Button  variant="outlined" size="small" startIcon={<AddIcon />} onClick={(e)=>AddFiltringKeyWord()}>
                              Ajouter
                          </Button>
                      </Box>
                   <hr/>
                   <small>Par types des documents</small>
                    {Resultat.aggregationsByNature.length===0 ? <></> :                   
                      <ListMui dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                          <ListItem
                            key={"Type_AllNatures"}
                            secondaryAction={
                              <Checkbox
                                edge="end"
                                onChange={ClearNatureFilter}
                                checked={NaturesFilter.length===0}
                                inputProps={{ 'aria-labelledby': "checkbox-list-secondary-label-AllNatures" }}
                              />
                            }
                            disablePadding
                          >
                            <ListItemButton>
                              <ListItemText id={"checkbox-list-secondary-label-AllNatures"} primary={"Tous les types" } />
                            </ListItemButton>
                          </ListItem>
                      {Resultat.aggregationsByNature.map((elem,key) => {
                        const labelId = `checkbox-list-secondary-label-${elem.item}`;
                        return (
                          <ListItem
                            key={"Type_"+key}
                            secondaryAction={
                              <Checkbox
                                edge="end"
                                value={elem.item} 
                                onChange={UpdateNaturesFilter}
                                checked={NaturesFilter.includes(elem.item)}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            }
                            disablePadding
                          >
                            <ListItemButton>
                              <ListItemText id={labelId} primary={GetTypeDocName(elem.item) +" ("+elem.count+")" } />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </ListMui>
                    }
                    <hr />
                    <small>Par année</small>
                    {Resultat.aggregationsByYears.sort((a, b) => Number(a.item) < Number(b.item) ? 1 : -1).length===0 ? <></> :                    
                     <ListMui dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                                <ListItem
                            key={"years_AllYears"}
                            secondaryAction={
                              <Checkbox
                                edge="end"
                                onChange={ClearAnneeFilter}
                                checked={YearsFilter.length===0}
                                inputProps={{ 'aria-labelledby': "checkbox-list-secondary-label-AllYears" }}
                              />
                            }
                            disablePadding
                          >
                            <ListItemButton>
                              <ListItemText id={"checkbox-list-secondary-label-AllYears"} primary={ "Toutes les années" } />
                            </ListItemButton>
                          </ListItem>
                      {Resultat.aggregationsByYears.map((elem,key) => {
                        const labelId = `checkbox-list-secondary-label-${elem.item}`;
                        return (
                          <ListItem
                            key={"years_"+key}
                            secondaryAction={
                              <Checkbox
                                edge="end"
                                value={elem.item} 
                                onChange={(e)=>UpdateYearsFilter(e)}
                                checked={YearsFilter.includes(elem.item)}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            }
                            disablePadding
                          >
                            <ListItemButton>
                              <ListItemText id={labelId} primary={elem.item +" ("+elem.count+")" } />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </ListMui>
                    }                
                    <hr />    
                    <small>Par Thème</small>
                    {Resultat.aggregationsByTags.length===0 ? <></> :
                    <ListMui dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      <ListItem
                            key={"them_AllThemes"}
                            secondaryAction={
                              <Checkbox
                                edge="end"
                                onChange={ClearThemFilter}
                                checked={TagsFilter.length===0}
                                inputProps={{ 'aria-labelledby': "checkbox-list-secondary-label-AllThemes" }}
                              />
                            }
                            disablePadding
                          >
                            <ListItemButton>
                              <ListItemText id={"checkbox-list-secondary-label-AllThemes"} primary={"Tous les thèmes"} />
                            </ListItemButton>
                      </ListItem>
                      {Resultat.aggregationsByTags.map((elem,key) => {
                        const labelId = `checkbox-list-secondary-label-${elem.item}`;
                        return (
                          <ListItem
                            key={"them_"+key}
                            secondaryAction={
                              <Checkbox
                                edge="end"
                                value={elem.item} 
                                onChange={UpdateTagsFilter}
                                checked={TagsFilter.includes(elem.item)}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            }
                            disablePadding
                          >
                            <ListItemButton>
                              <ListItemText id={labelId} primary={GetThemDocName(elem.item) +" ("+elem.count+")" } />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </ListMui>
                    }                    
                  </CardContent>
                </Card>              
              </Grid>
        </Grid>
        </Grid>
      </Box>
      <Modal
        open={DossierformPopup}
        onClose={handleCloseDossierformPopup}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
            <Card sx={style} >
                <CardHeader
                    title={action == 'add' ? "Ajouter un dossier" : "Modifier un dossier "}  
                />
                <CardContent>
                  <FormControl fullWidth>
                    <TextField id="outlined-basic" label="Nom dossier"  variant="outlined" value={selectedDossier.nomDossier} onChange={(e)=>handleDossierFieldChanged('nomDossier',e.target.value)} />
                  </FormControl>
                </CardContent>
                <CardActions>
                     <Button onClick={handleCloseDossierformPopup} variant="outlined" >Annuler</Button>
                     <Button onClick={onDossierValidationClicked} variant="contained" style={{marginLeft: 'auto'}}>  {action == 'add' ? "Ajouter" : "Modifier"}  </Button>
                </CardActions>
              </Card>
      </Modal>
      <Modal
        open={openFoldersModel}
        onClose={handleCloseFolderModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
       <Card sx={style} >
         <CardHeader  title="Placer ce document dans un dossier"/>
          <CardContent> 
          <Box  sx={{textAlign:'center',width:'100%' ,justifyContent:'center' }}>
              <Button  variant="contained" onClick={(e)=>handleDossierformPopup('add')}>
                  Nouveau dossier 
              </Button>
          </Box>
          <Box sx={{ display: (FolderLoading ? 'flex': 'none'), justifyContent:'center' }}>
                  <CircularProgress disableShrink />
                  </Box>
          {AllDossiers.length===0 ? <p>Aucun dossier trouvé</p> :                     
          <ListMui dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {AllDossiers.map((elem,key) => {
                          const labelId = `checkbox-list-secondary-label-${elem.id}`;
                          return (
                            <ListItem
                              key={"Folder_"+key}
                              secondaryAction={
                                <IconButton edge="end" aria-label="comments">
                                  <EditIcon onClick={(e)=>handleDossierformPopup('update',elem)} />
                                </IconButton>
                              }
                              disablePadding
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge="end"
                                  value={elem.id} 
                                  onChange={SelectedFolderChanged}
                                  checked={elem.documents.filter(x=>x.index_doc==SelectedDocumentId).length>0}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </ListItemIcon>
                              <ListItemButton>
                                <ListItemText id={labelId} primary={elem.nomDossier} />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                        </ListMui>}
          </CardContent>
          <CardActions>
            <Button onClick={handleCloseFolderModal} variant="outlined" style={{marginLeft: 'auto'}}>  Fermer  </Button>
          </CardActions> 
        </Card>
      </Modal>

      <Modal
        open={openNotesModel}
        onClose={handleCloseNotesModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
       <Card sx={style} >
         <CardHeader  title="Liste des notes"/>
          <CardContent> 
          <Box sx={{ display: (NotesLoading ? 'flex': 'none'), justifyContent:'center' }}>
                  <CircularProgress disableShrink />
                  </Box>
                  <Box sx={{ width: '100%',display: (NotesLoading ? 'none': '') }} spacing={2}>
                  {NotesList.length===0 ? <p>Aucune note trouvée</p> :                     
                        <div>
                                  {NotesList.map((elem,key) => {
                                  return (
                                    <Alert key={key} severity="warning"
                                     sx={{m:2}}
                                    action={
                                      <IconButton color="error" onClick={(e)=>DeleteNote(elem.id)}>
                                        <DeleteForeverIcon />
                                      </IconButton>
                                    }
                                     icon={<StickyNote2Icon />}>  
                                    <AlertTitle>{elem.titre}</AlertTitle>
                                    {elem.description}
                                  </Alert>
                                  );
                                })}
                        </div>
                        }                              
                    </Box>
          </CardContent>
          <CardActions>
            <Button onClick={handleCloseNotesModal} variant="outlined" style={{marginLeft: 'auto'}}>  Fermer  </Button>
          </CardActions> 
        </Card>
      </Modal>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Risk de dégradation du performance"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Nous vous informons que cette application opte pour la technique d'affichage partiel des résultats afin de garantir l'optimisation et la performance à nos clients. <br/>
              Du coup l'utilisation de la recherche via le navigateur (CNTRL+F) n'est pas fiable dans cette cas.
          </DialogContentText>
          <Button onClick={handleDialogClose} autoFocus>Annuler</Button>
          <Button onClick={AcceptedhandleDialogClose} color="error" >
            j'ai compris les conséquences, désactiver ce message
          </Button>
        </DialogContent>
      </Dialog>

      <Snackbar open={openFeedAlert} autoHideDuration={6000} onClose={handleCloseFeedAlert}>
        <AlertFeed onClose={handleCloseFeedAlert} severity="success" sx={{ width: '100%' }}>
          Feedback envoyé avec succes, Merci pour votre collaboration.!
        </AlertFeed>
      </Snackbar>
          </>
  );
}


