import React from "react";
import { Route } from "react-router-dom";
import { AuthConsumer } from "../providers/authProvider";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
export const PrivateRoute = ({ component,AppContainer, ...rest }) => {
    const renderFn = (Component) => (props) => 
    (
        <AuthConsumer>
            {({ isAuthenticated, signinRedirect }) => {       
                if (!!Component && isAuthenticated()) {
                    props.AppContainer = AppContainer;
                    return <Component {...props}  />;
                } else {
                    signinRedirect();
                    return <Backdrop
                                open={true}
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>;
                }
            }}
        </AuthConsumer>
    );

    return <Route {...rest} render={renderFn(component)} />;
};