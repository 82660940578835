import * as React from 'react';
import {useEffect,useState,useContext} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ErrorIcon from '@mui/icons-material/Error';
import CommentIcon from '@mui/icons-material/Comment';
import CachedIcon from '@mui/icons-material/Cached';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LinkToViewer from "components/private/Search/LinkToViewer";
 import { GetFeedBackWithDocsInfo,DeleteFeedBack } from 'Services/FeedBackService';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AppDataContext from "Contexts/AppDataContext";
import {AddOpenDocToSearch} from 'Services/HistoriqueService';
 export default function Feedback() {
   const [ FeedBack, setFeedBack] = useState([]);
   const [ loading, setloading]=useState(false);
   const {setServerError,FetchUserFeed} = useContext(AppDataContext);

   const DeleteConfirm =(id)=>{
    DeleteFeedBack(id)
    .then(res => {
      if(res != null){
        let feeds = FeedBack.filter(d=>d.id != id);
        setFeedBack(feeds);
      }else{
        setServerError(true);
      }

    })
  }

  const fetchFeedBack =()=>{
    setloading(true)
    FetchUserFeed();
    GetFeedBackWithDocsInfo()
    .then(res => {
      if(res != null){
        setFeedBack(res);
        setloading(false);
      }else{
        setloading(false);
        setServerError(true);
      }

    })
    .catch(err => console.log(err))
  }
  const DocOpened = (id_document,id_search)=> {
    AddOpenDocToSearch(id_search,{index_file:id_document,
      downloaded:false,
      downloadedWithOutOpen:false,
      component:'feedbackWeb'})
    .then(res => {
      if(res){
      }else{
        setServerError(true);
      }

    });
  }
  useEffect(() => {
    fetchFeedBack()
  }, []);

  return (
    <>
    
        <Box sx={{ flexGrow: 1,mt:4,mb:4 }}>
              <Box sx={{ display:( loading ? 'flex' : 'none' ), textAlign:'center',width:'100%' ,justifyContent:'center' }}>
                  <CircularProgress disableShrink />
              </Box>
              <Box sx={{ display:( loading ? 'none': 'flex')}}>

              <TableContainer component={Paper}> 
                  <Toolbar
                    sx={{
                      pl: { sm: 2 },
                      pr: { xs: 1, sm: 1 }
                    }}
                  >
                      <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                      >
                        Feed Back
                      </Typography>
                  </Toolbar>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell> Réference </TableCell>
                          <TableCell > Titre </TableCell>
                          <TableCell > Date de création </TableCell>
                          <TableCell > Feed back </TableCell>
                          <TableCell > Actions </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {FeedBack.map((feedback,i) => (
                          <TableRow key={"feedback_"+i}>
                            <TableCell>
                              <LinkToViewer 
                                id_search={feedback.id_search}
                                id={feedback.idDoc}
                                id_file_store={feedback.id_File_Store} 
                                text = {feedback.referenceDoc}
                                isReference={true}
                                DocOpened={DocOpened}
                              /> 
                            </TableCell>
                            <TableCell> 
                              {feedback.titreDoc}
                            </TableCell>
                            <TableCell>
                              {feedback.dateFeedStr}
                              </TableCell>
                            <TableCell>
                              {feedback.feedBackType == "Utile" ? <Chip label="Document Utile" size="small" variant="filled" color="primary" icon={<ThumbUpIcon />} /> : <></>}
                              {feedback.feedBackType == "NonUtile" ? <Chip label="Document non utile" size="small" variant="filled" color="warning" icon={<ThumbDownIcon />} /> : <></>}
                              {feedback.feedBackType == "Comment" ? <Chip label="Commentaire" size="small" variant="filled" color="primary" icon={<CommentIcon />} /> : <></>}
                              {feedback.feedBackType == "Error" ? <Chip label="Erreur" size="small" variant="filled" color="error" icon={<ErrorIcon />} /> : <></>}
                              {feedback.feedBackType == "Update" ? <Chip label="Mise à jour" size="small"  variant="filled"  icon={<CachedIcon />} /> : <></>}
                            </TableCell>
                            <TableCell>
                              <IconButton onClick={(e)=>DeleteConfirm(feedback.id)} aria-label="delete" size="large">
                                <DeleteIcon variant="outlined" color="error" /> 
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>  
                  </TableContainer>
              </Box>

        </Box>

    </>
  );
}

